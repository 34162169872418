export const Block = {
  namespaced: true,
  state: () => ({
    block: [],
  }),
  mutations: {
    clearBlock(state) {
      while (state.block.length >= 1) {
        state.block.pop();
      }
    },
    pushBlock(state, value) {
      state.block.push(value);
    },
    popBlock(state) {
      if (state.block.length >= 1) {
        state.block.pop();
      }
    },
    deleteBlockByIdx(state, value) {
      state.block.splice(value, 1);
    },
  },
  actions: {},
  getters: {},
};
