<script setup>
import {
  DrawingUtils,
  PoseLandmarker,
  FaceLandmarker,
  HandLandmarker,
} from "@mediapipe/tasks-vision";
import { ref, onMounted, onUnmounted } from "vue";
import {
  createLandmarkers,
  getPoseInfo,
  recognizePose,
  recognizeFace,
  getHandAngles,
  getHandPalmDirection,
} from "@/api/motion";
import MotionCameraIcon from "@/components/icon/MotionCameraIcon";
import MotionCameraBlock from "@/components/MotionCameraBlock";

import { useStore } from "vuex";
const store = useStore();

const props = defineProps({
  type: {
    type: String,
    required: true,
    default: "",
  },
});

const viewingElement = ref(null);
const videoElement = ref(null);
const canvasElement = ref(null);
const videoCoverElement = ref(null);

let drawingUtils = undefined;
let ctx = undefined;
let poseLandmarker = undefined;
let faceLandmarker = undefined;
let handLandmarker = undefined;
let action_name = ref("");
let face_name = ref("");
let hand_name = ref("");
let camera_cover_flag = false;

function remoteAction(action) {
  if (action === "up") {
    const shutter = new Audio(require("@/assets/camera_shutter.mp3"));
    shutter.play();
    if (props.type === "pose") {
      if (action_name.value == "circle") {
        store.commit("Space/play");
      } else if (action_name.value != "")
        store.commit("Block/pushBlock", action_name.value);
    } else if (props.type === "face") {
      if (face_name.value == "circle") {
        store.commit("Space/play");
      } else if (face_name.value != "")
        store.commit("Block/pushBlock", face_name.value);
    } else if (props.type === "hand") {
      if (hand_name.value == "circle") {
        store.commit("Space/play");
      } else if (hand_name.value != "")
        store.commit("Block/pushBlock", hand_name.value);
    }
  }
  if (action === "down") {
    const pop = new Audio(require("@/assets/pop.mp3"));
    pop.play();
    store.commit("Block/popBlock");
  }
}

document.addEventListener("keydown", (event) => {
  if (!store.state.Camera.isUsable) return;
  if (!store.state.Camera.shutterEnable) return;
  if (event.key == "PageUp") {
    remoteAction("up");
  }
  if (event.key == "PageDown") {
    remoteAction("down");
  }
});

document.addEventListener("click", () => {
  if (!store.state.Camera.isUsable) return;
  if (!store.state.Camera.shutterEnable) return;
  remoteAction("up");
});

document.addEventListener("contextmenu", (event) => {
  if (!store.state.Camera.isUsable) return;
  if (!store.state.Camera.shutterEnable) return;
  event.preventDefault();
  remoteAction("down");
});

onUnmounted(() => {
  console.log("unmounted");
});

onMounted(async () => {
  let landmarkers = await createLandmarkers(1, 1, 2);
  poseLandmarker = landmarkers[0];
  faceLandmarker = landmarkers[1];
  handLandmarker = landmarkers[2];
  ctx = canvasElement.value.getContext("2d");
  drawingUtils = new DrawingUtils(ctx);

  const width = parseInt(window.getComputedStyle(viewingElement.value).width);
  const height = parseInt(window.getComputedStyle(viewingElement.value).height);
  const facingMode = "environment";
  canvasElement.value.width = width;
  canvasElement.value.height = height;
  videoElement.value.style.width = width + "px";
  videoElement.value.style.height = height + "px";
  canvasElement.value.style.width = width + "px";
  canvasElement.value.style.height = height + "px";

  const hasGetUserMedia = () => !!navigator.mediaDevices?.getUserMedia;
  if (hasGetUserMedia()) {
    const constraints = {
      video: {
        width,
        height,
        facingMode,
      },
    };
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      videoElement.value.srcObject = stream;
      videoElement.value.addEventListener("loadeddata", predictWebcam);
    });
  } else {
    console.warn("getUserMedia() is not supported by your browser");
  }

  store.commit("Camera/setCameraSize", {
    width,
    height,
  });
  store.commit("Camera/setIsUsable", true);
});

async function predictWebcam() {
  let startTimeMs = performance.now();

  ctx.save();
  ctx.clearRect(0, 0, canvasElement.value.width, canvasElement.value.height);

  // Detection & Recognization
  let poseResults = poseLandmarker.detectForVideo(
    videoElement.value,
    startTimeMs
  );
  let faceResults = faceLandmarker.detectForVideo(
    videoElement.value,
    startTimeMs
  );
  let handResults = handLandmarker.detectForVideo(
    videoElement.value,
    startTimeMs
  );

  // Cover
  if (props.type === "pose") {
    if (poseResults.landmarks.length == 0 && camera_cover_flag) {
      videoCoverElement.value.style.opacity = 0;
      camera_cover_flag = false;
    } else if (poseResults.landmarks.length != 0 && !camera_cover_flag) {
      videoCoverElement.value.style.opacity = 1;
      camera_cover_flag = true;
    }
  }
  if (props.type === "face") {
    if (faceResults.faceLandmarks.length == 0 && camera_cover_flag) {
      videoCoverElement.value.style.opacity = 0;
      camera_cover_flag = false;
    } else if (faceResults.faceLandmarks.length != 0 && !camera_cover_flag) {
      videoCoverElement.value.style.opacity = 1;
      camera_cover_flag = true;
    }
  }
  if (props.type === "hand") {
    if (handResults.landmarks.length == 0 && camera_cover_flag) {
      videoCoverElement.value.style.opacity = 0;
      camera_cover_flag = false;
    } else if (handResults.landmarks.length != 0 && !camera_cover_flag) {
      videoCoverElement.value.style.opacity = 1;
      camera_cover_flag = true;
    }
  }

  // Process(Pose)
  let connector_color = "white";
  for (let i = 0; i < poseResults.landmarks.length; i++) {
    let pose = getPoseInfo(
      poseResults.landmarks[i],
      poseResults.worldLandmarks[i]
    );
    action_name.value = recognizePose(pose);
    store.commit("Camera/setActionPresent", action_name.value);

    if (pose.body.direction == "left") {
      connector_color = "green";
    }
    if (pose.body.direction == "right") {
      connector_color = "yellow";
    }
  }
  // Process(Face)
  //if (faceResults.faceLandmarks) {
  for (const bshape of faceResults.faceBlendshapes) {
    face_name.value = recognizeFace(bshape);
  }
  //}
  // Process(Hand)
  for (let i = 0; i < handResults.landmarks.length; i++) {
    const isRightHand = handResults.handedness[i][0].categoryName === "Right";
    const handPalmDirection = getHandPalmDirection(
      handResults.landmarks[i],
      isRightHand
    );
    //console.log(handPalmDirection);
    const { x, y, z } = handPalmDirection;
    const absX = Math.abs(x);
    const absY = Math.abs(y);
    const absZ = Math.abs(z);
    let direction = "";
    if (absX > absY && absX > absZ) {
      if (x >= 0) direction = "right";
      else direction = "left";
    } else if (absY > absZ) {
      if (y >= 0) direction = "up";
      else direction = "down";
    } else {
      if (z >= 0) direction = "front";
      else direction = "back";
    }
    console.log(direction);

    const handAngles = getHandAngles(handResults.landmarks[i]);
    const handStatus = {
      thumb: handAngles.thumb[3] < 150,
      index: handAngles.index[3] < 120,
      middle: handAngles.middle[3] < 120,
      ring: handAngles.ring[3] < 120,
      pinky: handAngles.pinky[3] < 120,
    };
    //console.log(handStatus);
    hand_name.value = "";
    const { thumb, index, middle, ring, pinky } = handStatus;
    if (!thumb && !index && !middle && !ring && !pinky) {
      if (direction == "front" || direction == "back") break;
      hand_name.value = direction + " go";
    }
    if (index && middle && ring && pinky) {
      hand_name.value = "circle";
    }
    if (!index && middle && ring && !pinky) {
      hand_name.value = "shoot";
    }
  }

  // Drawing
  if (props.type === "pose") {
    for (const landmarks of poseResults.landmarks) {
      drawingUtils.drawLandmarks(landmarks, {
        radius: (data) => DrawingUtils.lerp(data.from.z, -0.15, 0.1, 5, 1),
      });
      drawingUtils.drawConnectors(landmarks, PoseLandmarker.POSE_CONNECTIONS, {
        color: connector_color,
      });
    }
  }
  if (props.type === "face") {
    for (const landmarks of faceResults.faceLandmarks) {
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_TESSELATION,
        { color: "#C0C0C070", lineWidth: 1 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
        { color: "#30FF30", lineWidth: 2 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
        { color: "#30FF30", lineWidth: 2 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
        { color: "#30FF30", lineWidth: 2 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
        { color: "#30FF30", lineWidth: 2 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
        { color: "#E0E0E0", lineWidth: 2 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LIPS,
        {
          color: "#30FF30",
          lineWidth: 2,
        }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
        { color: "#30FF30", lineWidth: 2 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
        { color: "#30FF30", lineWidth: 2 }
      );
    }
  }
  if (props.type === "hand") {
    for (const landmarks of handResults.landmarks) {
      drawingUtils.drawConnectors(landmarks, HandLandmarker.HAND_CONNECTIONS, {
        color: "#FFFFFF",
        lineWidth: 5,
      });
    }
  }
  ctx.restore();
  window.requestAnimationFrame(predictWebcam);
}
</script>

<template>
  <div class="wrap">
    <div class="bg"></div>
    <div class="viewing" ref="viewingElement">
      <video ref="videoElement" autoplay playsinline></video>
      <div ref="videoCoverElement" class="video_cover" style="opacity: 0"></div>
      <canvas ref="canvasElement"></canvas>
      <div class="camera_block">
        <motion-camera-block :name="action_name" v-if="props.type === 'pose'" />
        <motion-camera-block :name="face_name" v-if="props.type === 'face'" />
        <motion-camera-block :name="hand_name" v-if="props.type === 'hand'" />
      </div>
      <div class="title">
        <div class="title_icon">
          <motion-camera-icon />
        </div>
        <div class="title_text">카메라</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: relative;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2),
    /* 위 (상단) */ 0 -4px 8px rgba(0, 0, 0, 0.05),
    /* 아래 (하단) */ -8px 0 12px rgba(0, 0, 0, 0.05),
    /* 왼쪽 */ 8px 0 12px rgba(0, 0, 0, 0.05); /* 오른쪽 */
}
.bg {
  width: 100%;
  height: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px 0 0 10px;
}

.viewing {
  flex: 1;
  position: relative;
  border-radius: inherit;
}

.camera_block {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  font-size: 50px;
  color: white;
  bottom: 50px;
  left: 0;
}

.video_cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s;
  /* background-image: url("@/assets/bg_camera.png"); */
  background-color: black;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  border-radius: inherit;
}

video {
  object-fit: none;
  clear: both;
  display: block;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  display: block;
  border-radius: inherit;
}

canvas {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: inherit;
}

.title {
  position: absolute;
  top: 10px;
  left: 15px;
  display: flex;
  align-items: center;
}

.title_icon {
  width: 14px;
  height: 14px;
  margin-right: 7px;
}
.title_text {
  color: white;
}
</style>
