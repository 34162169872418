<template>
  <svg
    width="81"
    height="72"
    viewBox="0 0 81 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="margin-right: -10px"
  >
    <path
      opacity="0.99"
      d="M79.9703 31.4339V40.5661C79.9703 44.4284 76.839 47.5646 72.9717 47.5646H72.8878C72.1124 47.5646 71.4901 48.1918 71.4901 48.9623V59.7984C71.4901 61.9222 70.8974 63.9126 69.8651 65.6067C69.6774 65.9178 69.4799 66.2141 69.2626 66.5006C69.0453 66.792 68.8181 67.0686 68.576 67.3304C66.5313 69.5875 63.5729 71 60.2885 71H12.6867C9.59489 71 6.7945 69.7455 4.76952 67.7205C4.51763 67.4686 4.27562 67.2019 4.04349 66.9254C3.5891 66.3722 3.18411 65.7746 2.83838 65.1375C1.97406 63.552 1.48511 61.7296 1.48511 59.7984V48.9673C1.48511 48.1918 2.11235 47.5646 2.88777 47.5646H2.9668C6.83401 47.5646 9.97025 44.4284 9.97025 40.5611V31.4339C9.97025 27.5667 6.83895 24.4354 2.97173 24.4354H2.88777C2.11235 24.4354 1.48511 23.8082 1.48511 23.0377V12.2016C1.48511 10.2655 1.97406 8.44303 2.83838 6.85762C3.18411 6.22543 3.5891 5.62781 4.04349 5.07465C4.27562 4.79807 4.51763 4.53136 4.76952 4.27948C6.7945 2.2545 9.59489 1 12.6867 1H60.2885C63.5729 1 66.5313 2.41254 68.576 4.66965C68.8181 4.93636 69.0453 5.21294 69.2626 5.4994C69.6923 6.07232 70.0726 6.68969 70.3837 7.34657C70.5418 7.66761 70.685 8.00346 70.8035 8.34425H70.8085C71.248 9.54935 71.4901 10.8483 71.4901 12.2016V23.0377C71.4901 23.8082 72.1124 24.4354 72.8878 24.4354H72.9717C76.839 24.4354 79.9703 27.5717 79.9703 31.4339Z"
      fill="#006BE0"
      stroke="#FFFCFC"
      stroke-miterlimit="10"
    />
    <g opacity="0.99">
      <path
        d="M13.2942 67.5033C11.1013 67.5033 9.03686 66.6488 7.48602 65.098C7.30328 64.9202 7.12054 64.7177 6.9378 64.4954C6.61677 64.1052 6.32043 63.6607 6.06854 63.2014C5.4166 62.0111 5.07581 60.6579 5.07581 59.2848V49.9699C9.75795 49.3377 13.3782 45.3174 13.3782 40.4673V31.5377C13.3782 26.6876 9.75795 22.6673 5.07581 22.0401V12.7252C5.07581 11.3521 5.41659 9.99391 6.0636 8.80856C6.31549 8.34923 6.61677 7.90472 6.95262 7.48985C7.12054 7.29229 7.30329 7.08979 7.48109 6.91199C9.03192 5.36116 11.0964 4.50671 13.2893 4.50671H59.8588C62.1703 4.50671 64.3879 5.48957 65.9387 7.19845C66.1116 7.39107 66.2844 7.59851 66.4573 7.8257C66.7734 8.24551 67.0451 8.69496 67.2624 9.14934C67.3908 9.41111 67.4846 9.6383 67.5636 9.85068L67.7118 10.2754C67.9587 11.0657 68.0822 11.8855 68.0822 12.7202V23.2797C68.0822 25.5714 69.9393 27.4285 72.231 27.4285C74.5227 27.4285 76.3797 29.2855 76.3797 31.5772V40.4278C76.3797 42.7195 74.5227 44.5766 72.231 44.5766C69.9393 44.5766 68.0822 46.4336 68.0822 48.7253V59.2848C68.0822 60.7912 67.6723 62.263 66.8919 63.5422C66.7438 63.7892 66.6055 63.9966 66.4573 64.1843C66.2795 64.4213 66.1215 64.614 65.9585 64.7918C64.3879 66.5253 62.1752 67.5033 59.8588 67.5033H13.2893H13.2942Z"
        fill="#2A7DE7"
      />
    </g>
    <path
      d="M38.2404 22.9585L39.7268 21.472C40.3562 20.8427 41.374 20.8427 41.9967 21.472L55.0131 34.4818C55.6425 35.1111 55.6425 36.1289 55.0131 36.7516L42.0034 49.7613C41.374 50.3907 40.3562 50.3907 39.7335 49.7613L38.2471 48.2749C37.611 47.6388 37.6244 46.6009 38.2739 45.9782L46.3355 38.2983H27.0921C26.2015 38.2983 25.4851 37.5819 25.4851 36.6913V34.5487C25.4851 33.6582 26.2015 32.9417 27.0921 32.9417H46.3355L38.2672 25.2551C37.611 24.6324 37.5976 23.5946 38.2404 22.9585Z"
      fill="white"
    />
  </svg>
</template>
