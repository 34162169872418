<script setup>
import { computed } from "vue";
const props = defineProps({
  checked: Boolean,
});

const colorCheck = computed(() => {
  if (props.checked === true) return "#714bdd";
  else return "#858585";
});
</script>
<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.25 12.5C24.25 18.9893 18.9893 24.25 12.5 24.25C6.01065 24.25 0.75 18.9893 0.75 12.5C0.75 6.01065 6.01065 0.75 12.5 0.75C18.9893 0.75 24.25 6.01065 24.25 12.5Z"
      :stroke="colorCheck"
      stroke-width="1.5"
    />
    <path
      d="M10.9416 18.75L6.25 11.4613L7.76256 10.3093L11.0866 15.4741L17.347 7.5L18.75 8.80649L10.9416 18.75Z"
      :fill="colorCheck"
    />
  </svg>
</template>
