<script setup>
import BetyDialog from "@/components/bety/BetyDialog";
import BetyHangeulLetter from "@/components/bety/sprite/BetyHangeulLetter";
import { ref, onMounted, computed, watch, onUpdated } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useStore();
const props = defineProps({
  pageno: {
    type: Number,
    required: true,
    default: 0,
  },
});

const letters = [
  "G",
  "N",
  "D",
  "R",
  "M",
  "B",
  "S",
  "O",
  "J",
  "CH",
  "K",
  "T",
  "P",
  "H",
  "GG",
  "DD",
  "BB",
  "SS",
  "JJ",
  "a",
  "ae",
  "eo",
  "e",
  "o",
  "oe",
  "u",
  "wi",
  "eu",
  "i",
  "ya",
  "yae",
  "yeo",
  "ye",
  "wa",
  "wae",
  "yo",
  "wo",
  "we",
  "yu",
  "ui",
];

const itemsFull = {
  G: [
    { text: "기차", l: 180, t: 200, r: undefined, b: undefined, answer: true },
    { text: "나무", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "포도", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  N: [
    { text: "우리", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    {
      text: "너구리",
      l: 180,
      t: 400,
      r: undefined,
      b: undefined,
      answer: true,
    },
    { text: "자라", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  D: [
    { text: "과자", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    {
      text: "바나나",
      l: 180,
      t: 400,
      r: undefined,
      b: undefined,
      answer: false,
    },
    { text: "도시", l: 180, t: 600, r: undefined, b: undefined, answer: true },
  ],
  R: [
    { text: "토끼", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "가위", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    {
      text: "도라지",
      l: 180,
      t: 600,
      r: undefined,
      b: undefined,
      answer: true,
    },
  ],
  M: [
    { text: "비", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "무", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    { text: "의자", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  B: [
    { text: "파도", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "기타", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "바다", l: 180, t: 600, r: undefined, b: undefined, answer: true },
  ],
  S: [
    {
      text: "바나나",
      l: 180,
      t: 200,
      r: undefined,
      b: undefined,
      answer: false,
    },
    { text: "이사", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    {
      text: "도토리",
      l: 180,
      t: 600,
      r: undefined,
      b: undefined,
      answer: false,
    },
  ],
  O: [
    { text: "시소", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "우유", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    {
      text: "토마토",
      l: 180,
      t: 600,
      r: undefined,
      b: undefined,
      answer: false,
    },
  ],
  J: [
    { text: "두부", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "미소", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "가지", l: 180, t: 600, r: undefined, b: undefined, answer: true },
  ],
  CH: [
    { text: "호두", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "가마", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "고추", l: 180, t: 600, r: undefined, b: undefined, answer: true },
  ],
  K: [
    { text: "구두", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "파", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    {
      text: "키다리",
      l: 180,
      t: 600,
      r: undefined,
      b: undefined,
      answer: true,
    },
  ],
  T: [
    { text: "모래", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "제비", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    {
      text: "도토리",
      l: 180,
      t: 600,
      r: undefined,
      b: undefined,
      answer: true,
    },
  ],
  P: [
    { text: "다리", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "돼지", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "파도", l: 180, t: 600, r: undefined, b: undefined, answer: true },
  ],
  H: [
    { text: "그네", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "가수", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "하마", l: 180, t: 600, r: undefined, b: undefined, answer: true },
  ],
  GG: [
    { text: "두부", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "바위", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "꼬리", l: 180, t: 600, r: undefined, b: undefined, answer: true },
  ],
  DD: [
    {
      text: "메뚜기",
      l: 180,
      t: 200,
      r: undefined,
      b: undefined,
      answer: true,
    },
    { text: "아빠", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "다리", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  BB: [
    {
      text: "허리띠",
      l: 180,
      t: 200,
      r: undefined,
      b: undefined,
      answer: false,
    },
    { text: "비누", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "뿌리", l: 180, t: 600, r: undefined, b: undefined, answer: true },
  ],
  SS: [
    { text: "꼬마", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "시소", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    {
      text: "아저씨",
      l: 180,
      t: 600,
      r: undefined,
      b: undefined,
      answer: true,
    },
  ],
  JJ: [
    { text: "가짜", l: 180, t: 200, r: undefined, b: undefined, answer: true },
    { text: "뚜껑", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "뽀뽀", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],

  a: [
    { text: "고추", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "가마", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    {
      text: "지렁이",
      l: 180,
      t: 600,
      r: undefined,
      b: undefined,
      answer: false,
    },
  ],
  ae: [
    { text: "과자", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    {
      text: "자동차",
      l: 180,
      t: 400,
      r: undefined,
      b: undefined,
      answer: false,
    },
    { text: "은행", l: 180, t: 600, r: undefined, b: undefined, answer: true },
  ],
  eo: [
    { text: "비누", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "엄마", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    { text: "노래", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  e: [
    { text: "고무", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "기체", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    { text: "내기", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  o: [
    { text: "요리", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "오이", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    { text: "우비", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  oe: [
    { text: "박쥐", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "참외", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    { text: "무기", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  u: [
    { text: "물통", l: 180, t: 200, r: undefined, b: undefined, answer: true },
    { text: "손", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    {
      text: "건전지",
      l: 180,
      t: 600,
      r: undefined,
      b: undefined,
      answer: false,
    },
  ],
  wi: [
    { text: "침대", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "방귀", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    { text: "로봇", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  eu: [
    {
      text: "도토리",
      l: 180,
      t: 200,
      r: undefined,
      b: undefined,
      answer: false,
    },
    { text: "진흙", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    { text: "사진", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  i: [
    { text: "축구", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "음악", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "기린", l: 180, t: 600, r: undefined, b: undefined, answer: true },
  ],
  ya: [
    { text: "책", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "야구", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    { text: "빵", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  yae: [
    { text: "아기", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "지혜", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "얘기", l: 180, t: 600, r: undefined, b: undefined, answer: true },
  ],
  yeo: [
    { text: "가족", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "연습", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    { text: "눈", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  ye: [
    { text: "시계", l: 180, t: 200, r: undefined, b: undefined, answer: true },
    { text: "무늬", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "보리", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  wa: [
    { text: "신발", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "가방", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "완성", l: 180, t: 600, r: undefined, b: undefined, answer: true },
  ],
  wae: [
    {
      text: "외계인",
      l: 180,
      t: 200,
      r: undefined,
      b: undefined,
      answer: false,
    },
    { text: "돼지", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    { text: "운동", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  yo: [
    { text: "이마", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "꿈", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "요리", l: 180, t: 600, r: undefined, b: undefined, answer: true },
  ],
  wo: [
    { text: "거위", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "소원", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    { text: "개미", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  we: [
    { text: "횃불", l: 180, t: 200, r: undefined, b: undefined, answer: false },
    { text: "훼방", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    {
      text: "왜가리",
      l: 180,
      t: 600,
      r: undefined,
      b: undefined,
      answer: false,
    },
  ],
  yu: [
    { text: "우유", l: 180, t: 200, r: undefined, b: undefined, answer: true },
    { text: "풍선", l: 180, t: 400, r: undefined, b: undefined, answer: false },
    { text: "목욕", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
  ui: [
    {
      text: "복숭아",
      l: 180,
      t: 200,
      r: undefined,
      b: undefined,
      answer: false,
    },
    { text: "의사", l: 180, t: 400, r: undefined, b: undefined, answer: true },
    { text: "입", l: 180, t: 600, r: undefined, b: undefined, answer: false },
  ],
};

const items = ref(undefined);
const basket = ref({
  l: 1200,
  t: 300,
  r: undefined,
  b: undefined,
});
const itemElements = ref([]);
const basketElement = ref(null);
const spaceState = ref("none");

let handPresent = computed(() => store.state.Camera.handPresent);
let handLandmark = computed(() => store.state.Camera.handLandmark);
let cameraWidth = computed(() => store.state.Camera.cameraWidth);
let cameraHeight = computed(() => store.state.Camera.cameraHeight);
let cursor = undefined;
let grasp = false;
let selected = null;

onMounted(() => {
  items.value = itemsFull[letters[props.pageno]];
  for (let i = 0; i < itemElements.value.length; i++) {
    const style = window.getComputedStyle(itemElements.value[i]);
    const width = parseInt(style.width);
    const height = parseInt(style.height);
    const padding = parseInt(style.padding);
    items.value[i].r = items.value[i].l + width + 2 * padding;
    items.value[i].b = items.value[i].t + height + 2 * padding;
    itemElements.value[i].style.left = `${items.value[i].l}px`;
    itemElements.value[i].style.top = `${items.value[i].t}px`;
    itemElements.value[i].style.opacity = 1;
    itemElements.value[i].style.transform = "scale(1)";
  }
  const style = window.getComputedStyle(basketElement.value);
  const width = parseInt(style.width);
  const height = parseInt(style.height);
  const padding = parseInt(style.padding);
  basket.value.r = basket.value.l + width + 2 * padding;
  basket.value.b = basket.value.t + height + 2 * padding;
  basketElement.value.style.left = `${basket.value.l}px`;
  basketElement.value.style.top = `${basket.value.t}px`;
  console.log(basket.value);
});
onUpdated(() => {
  items.value = itemsFull[letters[props.pageno]];
  for (let i = 0; i < itemElements.value.length; i++) {
    const style = window.getComputedStyle(itemElements.value[i]);
    const width = parseInt(style.width);
    const height = parseInt(style.height);
    const padding = parseInt(style.padding);
    items.value[i].r = items.value[i].l + width + 2 * padding;
    items.value[i].b = items.value[i].t + height + 2 * padding;
    itemElements.value[i].style.left = `${items.value[i].l}px`;
    itemElements.value[i].style.top = `${items.value[i].t}px`;
    itemElements.value[i].style.opacity = 1;
    itemElements.value[i].style.transform = "scale(1)";
  }
  const style = window.getComputedStyle(basketElement.value);
  const width = parseInt(style.width);
  const height = parseInt(style.height);
  const padding = parseInt(style.padding);
  basket.value.r = basket.value.l + width + 2 * padding;
  basket.value.b = basket.value.t + height + 2 * padding;
  basketElement.value.style.left = `${basket.value.l}px`;
  basketElement.value.style.top = `${basket.value.t}px`;
  console.log(basket.value);
});

const collision = (cursor, items) => {
  for (let i = 0; i < items.value.length; i++) {
    if (
      cursor.l <= items.value[i].r &&
      cursor.r >= items.value[i].l &&
      cursor.t <= items.value[i].b &&
      cursor.b >= items.value[i].t
    ) {
      return i;
    }
  }
  return null;
};

const collisionInside = (cursor, items) => {
  for (let i = 0; i < items.value.length; i++) {
    if (
      cursor.l <= items.value[i].l &&
      cursor.r >= items.value[i].r &&
      cursor.t <= items.value[i].t &&
      cursor.b >= items.value[i].b
    ) {
      return i;
    }
  }
  return null;
};

const getDelta = (o, n) => {
  const sx = (o.l + o.r) / 2;
  const sy = (o.t + o.b) / 2;
  const ex = (n.l + n.r) / 2;
  const ey = (n.t + n.b) / 2;
  return { dx: ex - sx, dy: ey - sy };
};

const dialogClosed = (value) => {
  if (value === "next") {
    let routeMajor = router.currentRoute.value.params.major || "";
    let routeMinor = router.currentRoute.value.params.minor || "";
    let routePageno = parseInt(router.currentRoute.value.params.pageno || "");
    routePageno = isNaN(routePageno) ? 0 : routePageno;
    // router.replace(`/bety/${routeMajor}/${routeMinor}/${routePageno + 1}`);
    location.replace(`/bety/${routeMajor}/${routeMinor}/${routePageno + 1}`);
  } else if (value === "reload") {
    console.log("reload");
  }
  spaceState.value = "none";
};

watch(handPresent, (newValue) => {
  if (newValue === "circle" || newValue === "grab" || newValue === "shoot") {
    grasp = true;
    selected = collision(cursor, items);
    console.log(selected);
  } else {
    grasp = false;
    selected = null;
  }
});
watch(handLandmark, async (newValue) => {
  if (cameraWidth.value === undefined) return;
  if (cameraHeight.value === undefined) return;
  let l = parseInt(
    cameraWidth.value -
      Math.max(newValue[0].x, newValue[5].x, newValue[17].x) * cameraWidth.value
  );
  let r = parseInt(
    cameraWidth.value -
      Math.min(newValue[0].x, newValue[5].x, newValue[17].x) * cameraWidth.value
  );
  let t = parseInt(
    Math.min(newValue[0].y, newValue[5].y, newValue[17].y) * cameraHeight.value
  );
  let b = parseInt(
    Math.max(newValue[0].y, newValue[5].y, newValue[17].y) * cameraHeight.value
  );

  const basketItem = collisionInside(basket.value, items);
  if (basketItem !== null) {
    // console.log(items.value[basketItem].answer);
    itemElements.value[basketItem].style.opacity = 0;
    itemElements.value[basketItem].style.transform = "scale(0)";
    await new Promise((resolve) => setTimeout(resolve, 1000));
    items.value[basketItem].r -= items.value[basketItem].l;
    items.value[basketItem].b -= items.value[basketItem].t;
    items.value[basketItem].l = 180;
    items.value[basketItem].t = 200 * (basketItem + 1);
    items.value[basketItem].r += items.value[basketItem].l;
    items.value[basketItem].b += items.value[basketItem].t;
    if (items.value[basketItem].answer) spaceState.value = "success";
    else spaceState.value = "fail";
  }

  if (selected !== null && grasp) {
    let tmp = { l, r, t, b };
    const d = getDelta(cursor, tmp);
    items.value[selected].l += d.dx;
    items.value[selected].r += d.dx;
    items.value[selected].t += d.dy;
    items.value[selected].b += d.dy;
    itemElements.value[selected].style.left = `${items.value[selected].l}px`;
    itemElements.value[selected].style.top = `${items.value[selected].t}px`;

    console.log(d);
  }

  cursor = { l, r, t, b };
});
</script>

<template>
  <div class="wrap">
    <div class="title">
      <div class="title_icon" style="width: 60px; height: 30px">
        <bety-hangeul-letter :type="letters[props.pageno]" small="true" />
      </div>

      이 들어간 낱말을 골라보아요
    </div>
    <div class="basket" ref="basketElement">여기에 넣어보아요</div>

    <div v-for="item in items" :key="item.id" ref="itemElements" class="item">
      {{ item.text }}
    </div>
    <div style="position: absolute; width: 100%; height: 100%">
      <bety-dialog
        v-if="spaceState !== 'none'"
        :type="spaceState"
        @closed="dialogClosed"
      />
    </div>
  </div>
</template>

<style scoped>
.wrap {
  position: absolute;
  width: 100%;
  height: 100%;
}
.title {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--color-bety-white);
  font-size: 40px;
  padding-top: 80px;
}
.item {
  position: absolute;
  background-color: var(--color-bety-primary);
  border: 2px dashed var(--color-bety-secondary);
  color: var(--color-bety-white);
  border-radius: 10px;
  font-size: 50px;
  padding: 30px;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.basket {
  position: absolute;
  background-color: var(--color-bety-white);
  opacity: 0.5;
  border-radius: 10px;
  font-size: 20px;
  padding: 20px;
  width: 200px;
  height: 250px;
  display: flex;
  justify-content: center;
}
</style>
