<script setup>
import TheFooter from "@/components/TheFooter";
import TheHeader from "@/components/TheHeader";
import { ref, onUpdated } from "vue";

let isTerm = ref(false);
let isPrivacy = ref(false);

const props = defineProps({
  view: String,
});

if (props.view === "term") {
  isTerm.value = true;
  isPrivacy.value = false;
}
if (props.view === "privacy") {
  isTerm.value = false;
  isPrivacy.value = true;
}

onUpdated(() => {
  if (props.view === "term") {
    isTerm.value = true;
    isPrivacy.value = false;
  }
  if (props.view === "privacy") {
    isTerm.value = false;
    isPrivacy.value = true;
  }
});
</script>

<style scoped>
.container {
  width: 100%;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.content {
  padding-top: 70px;
}
h1 {
  font-size: 35px;
  font-weight: 700;
  padding: 30px 0;
}
h2 {
  font-size: 28px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 5px;
}
h3 {
  font-size: 18px;
  padding: 20px 0;
  line-height: 1.7;
}
ol {
  list-style-type: decimal;
  margin-block-start: 0.5em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
ul {
  list-style-type: circle;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

ol ol {
  list-style-type: lower-alpha;
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
}

.policy_tab_wrapper {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
}
.policy_tab {
  height: 100%;
  display: flex;
}

.policy_tab_term,
.policy_tab_privacy {
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
}

.selected {
  color: #714bdd;
  border-bottom: 3px solid #714bdd;
}
</style>

<template>
  <the-header />
  <main>
    <div class="container">
      <div class="content">
        <div class="policy_tab_wrapper">
          <div class="policy_tab">
            <router-link to="/policy/term">
              <div class="policy_tab_term" :class="{ selected: isTerm }">
                이용약관
              </div>
            </router-link>
            <router-link to="/policy/privacy"
              ><div class="policy_tab_privacy" :class="{ selected: isPrivacy }">
                개인정보처리방침
              </div>
            </router-link>
          </div>
        </div>
        <div class="term" v-if="isTerm">
          <h1>서비스 이용약관</h1>
          <h2>제 1조 목적</h2>
          <h3>
            본 약관은 리원드 주식회사(이하 "회사")가 제공하는 온라인 소프트웨어
            교육 플랫폼인 베티 서비스(이하 "베티") 및 베티와 관련한 제반
            서비스의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항,
            기타 필요한 사항을 규정함을 목적으로 합니다.
          </h3>

          <h2>제 2조 정의</h2>
          <h3>
            이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            <ol>
              <li>
                "회원"이라 함은 회사의 서비스에 접속하여 이 약관에 동의하고
                회사와 이용계약을 체결하여 회사가 제공하는 서비스를 이용하는
                고객을 말합니다.
              </li>
              <li>
                "서비스"라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종
                유무선 장치를 포함)와 상관없이 회원이 이용할 수 있는 베티 및
                베티와 관련 제반 서비스를 의미합니다.
              </li>
              <li>
                "아이디(ID)"이라 함은 회원의 식별과 서비스 이용을 위하여 회원이
                정하고 회사가 승인하는 문자와 숫자의 조합을 의미합니다.
              </li>
              <li>
                "비밀번호"라 함은 회원이 부여받은 아이디(ID)와 일치되는 회원임을
                확인하고 비밀보호를 위해 회원 자신이 정한 문자 또는 숫자의
                조합을 의미합니다.
              </li>
              <li>
                "게시물"이라 함은 회원이 서비스를 이용함에 있어 서비스에
                게시ㆍ게재ㆍ등록한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의
                정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미하며,
                서비스의 작품, 스터디, 스터디모음 및 댓글, 커뮤니티 게시물 및
                댓글 등을 통칭합니다.
              </li>
            </ol>
          </h3>

          <h2>제 3조 약관의 게시와 개정</h2>
          <h3>
            <ol>
              <li>
                회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 초기
                화면에 게시합니다.
              </li>
              <li>
                회사는 "약관의규제에관한법률",
                "정보통신망이용촉진및정보보호등에관한법률(이하 "정보통신망법")"
                등 관계법령을 위배하지 않는 범위에서 이 약관을 개정할 수
                있습니다.
              </li>
              <li>
                회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
                현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 7일
                전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한
                약관의 개정의 경우에는 최소 30일 전에 공지하고 로그인시 동의창
                등의 전자적 수단을 통해 개별적으로 명확히 통지하도록 합니다.
              </li>
              <li>
                회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 위
                기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는
                뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의
                의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로
                봅니다.
              </li>
              <li>
                회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의
                내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수
                있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는
                경우에는 회사는 이용계약을 해지할 수 있습니다.
              </li>
            </ol>
          </h3>

          <h2>제 4조 약관의 해석</h2>
          <h3>
            <ol>
              <li>
                회사는 서비스의 원활한 제공을 위하여 별도의 운영 정책을 둘 수
                있으며, 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 운영
                정책, 관계법령 또는 상관례에 따릅니다.
              </li>
              <li>
                회사는 서비스 중 특정 서비스에 관한 약관(이하 “개별약관”)을
                별도로 제정할 수 있으며, 회원이 개별약관에 동의한 경우
                개별약관은 이용계약의 일부를 구성하고 개별약관에 이 약관과
                상충하는 내용이 있을 경우 개별약관이 우선적으로 적용됩니다.
              </li>
            </ol>
          </h3>

          <h2>제 5조 이용계약 체결</h2>
          <h3>
            <ol>
              <li>
                이용계약은 회원이 되고자 하는 자(이하 "가입신청자")가 약관의
                내용에 대하여 동의를 한 다음 회원가입신청을 하고 회사가 이러한
                신청에 대하여 승낙함으로써 체결됩니다.
              </li>
              <li>
                회사는 "가입신청자"의 신청에 대하여 서비스 이용을 승낙함을
                원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에
                대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수
                있습니다.
              </li>
              <ol>
                <li>
                  가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이
                  있는 경우, 단 회사의 회원 재가입 승낙을 얻은 경우에는 예외로
                  함.
                </li>
                <li>
                  허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은
                  경우
                </li>
                <li>
                  "가입신청자"의 귀책사유로 인하여 승인이 불가능하거나 기타
                  규정한 제반 사항을 위반하며 신청하는 경우
                </li>
              </ol>
              <li>
                제1항에 따른 신청에 있어 회사는 회원의 종류에 따라 전문기관을
                통한 실명확인 및 본인인증을 요청할 수 있습니다.
              </li>
              <li>
                회사는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가
                있는 경우에는 승낙을 유보할 수 있습니다.
              </li>
              <li>
                제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나
                유보한 경우, 회사는 원칙적으로 이를 가입신청자에게 알리도록
                합니다.
              </li>
              <li>
                이용계약의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시한
                시점으로 합니다.
              </li>
              <li>
                회사는 운영 정책에 따라 회원을 등급별로 구분하여 이용시간,
                이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수
                있습니다.
              </li>
              <li>
                회사는 회원에 대하여 "영화및비디오물의진흥에관한법률" 및
                "청소년보호법"등에 따른 등급 및 연령 준수를 위해 이용제한이나
                등급별 제한을 할 수 있습니다.
              </li>
            </ol>
          </h3>

          <h2>제 6조 회원정보의 변경</h2>
          <h3>
            <ol>
              <li>
                회원은 회원정보관리화면을 통하여 언제든지 본인의 회원정보를
                열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한
                아이디(ID) 등은 수정이 불가능합니다.
              </li>
              <li>
                회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 서비스 내
                회원정보관리화면에서 수정을 하거나 전자우편 기타 방법으로 회사에
                대하여 그 변경사항을 알려야 합니다.
              </li>
              <li>
                제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여
                회사는 책임지지 않습니다.
              </li>
            </ol>
          </h3>

          <h2>제 7조 개인정보보호 의무</h2>
          <h3>
            회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원의
            개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에
            대해서는 관계법령 및 회사의 개인정보 처리방침이 적용됩니다. 다만,
            회사가 운영하는 서비스의 공식 사이트 이외의 사이트에서 링크된
            제3자의 사이트에서는 회사의 개인정보 처리방침이 적용되지 않습니다.

            <ol></ol>
          </h3>

          <h2>제 8조 회원의 아이디 및 비밀번호의 관리에 대한 의무</h2>
          <h3>
            <ol>
              <li>
                회원의 아이디(ID)와 비밀번호에 관한 관리책임은 회원에게 있으며,
                이를 제 3자가 이용하도록 하여서는 안 됩니다.
              </li>
              <li>
                회사는 회원의 아이디(ID)가 개인정보 유출 우려가 있거나, 반사회적
                또는 미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인할 우려가
                있는 경우, 해당 아이디(ID)의 이용을 제한할 수 있습니다.
              </li>
              <li>
                회원은 아이디(ID) 및 비밀번호가 도용되거나 제3자가 사용하고
                있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에
                따라야 합니다.
              </li>
              <li>
                제3항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나,
                통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여
                회사는 책임지지 않습니다.
              </li>
            </ol>
          </h3>

          <h2>제 9조 회원에 대한 통지</h2>
          <h3>
            <ol>
              <li>
                회사가 회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는
                한 로그인 시 공지 창, 서비스 화면 또는 전자우편 등을 통해 통지할
                수 있습니다.
              </li>
              <li>
                회사는 회원 전체에 대해 통지의 경우 7일 이상 회사의 공지사항에
                게시함으로써 제1항의 통지에 갈음할 수 있습니다.
              </li>
            </ol>
          </h3>

          <h2>제 10조 회사의 의무</h2>
          <h3>
            <ol>
              <li>
                회사는 관계법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를
                하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여
                최선을 다하여 노력합니다.
              </li>
              <li>
                회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보보호를
                위해 보안시스템을 갖추어야 하며 개인정보 처리방침을 공시하고
                준수합니다.
              </li>
              <li>
                회사는 서비스이용과 관련하여 발생하는 회원의 불만 또는
                피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을
                구비합니다.
              </li>
              <li>
                회사는 서비스이용과 관련하여 회원으로부터 제기된 의견이나 불만이
                정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한
                의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을
                통하여 회원에게 처리과정 및 결과를 전달합니다.
              </li>
            </ol>
          </h3>
          <h2>제 11조 회원의 의무</h2>
          <h3>
            <ol>
              <li>
                회원은 다음 행위를 하여서는 안 됩니다
                <ol>
                  <li>신청 또는 변경 시 허위내용의 등록</li>
                  <li>타인의 정보도용</li>
                  <li>회사가 게시한 정보의 변경</li>
                  <li>
                    회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신
                    또는 게시
                  </li>
                  <li>회사 또는 제3자의 저작권 등 지적재산권에 대한 침해</li>
                  <li>
                    회사 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위
                  </li>
                  <li>
                    외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에
                    반하는 정보를 서비스에 공개 또는 게시하는 행위
                  </li>
                  <li>
                    회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위
                  </li>
                  <li>기타 불법적이거나 부당한 행위</li>
                </ol>
              </li>
              <li>
                회원은 관계법령, 이 약관의 규정, 이용정책, 이용안내 및 서비스와
                관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야
                하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
              </li>
            </ol>
          </h3>
          <h2>제 12조 서비스의 제공 등</h2>
          <h3>
            <ol>
              <li>
                회사는 회원에게 아래와 같은 서비스를 제공합니다.
                <ol>
                  <li>프로그래밍 교육 서비스</li>
                  <li>프로그래밍 제작 플랫폼</li>
                  <li>프로그래밍 관련 저작물 공유 플랫폼(출시 예정)</li>
                  <li>프로그래밍 교육 관련 커뮤니티(출시 예정)</li>
                  <li>
                    기타 회사가 추가 개발하거나 다른 회사와의 제휴 계약 등을
                    통해 회원에게 제공하는 일체의 서비스
                  </li>
                </ol>
              </li>
              <li>
                회사는 서비스를 일정 범위로 분할하여 각 범위 별로 이용가능시간을
                별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을
                사전에 공지합니다.
              </li>
              <li>서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</li>
              <li>
                회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절
                또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로
                중단할 수 있습니다. 이 경우 회사는 제9조에서 정한 방법으로
                회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한
                사유가 있는 경우 사후에 통지할 수 있습니다.
              </li>
              <li>
                회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며,
                정기점검시간은 서비스 초기 화면에 공지한 바에 따릅니다.
              </li>
            </ol>
          </h3>

          <h2>제 13조 서비스의 변경</h2>
          <h3>
            <ol>
              <li>
                회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라
                제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.
              </li>
              <li>
                서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는
                변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에
                해당 서비스 초기화면에 게시하여야 합니다.
              </li>
              <li>
                회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및
                운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여
                관계법령에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지
                않습니다.
              </li>
            </ol>
          </h3>

          <h2>제 14조 정보의 제공 및 광고의 게재</h2>
          <h3>
            <ol>
              <li>
                회사는 정보통신망법 제50조 소정의 영리목적의 광고성 정보를
                제외하고는 회원이 서비스 이용 중 필요하다고 인정되는 다양한
                정보를 공지사항이나 전자우편 등의 방법으로 회원에게 제공할 수
                있습니다. 다만, 회원은 관계법령에 따른 거래관련 정보 및 고객문의
                등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신
                거절을 할 수 있습니다.
              </li>
              <li>
                제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는
                경우에는 회원의 사전 동의를 받아서 전송합니다. 다만, 회원의
                서비스 이용 관련 주요 정보 및 고객문의 등에 대한 회신에 있어서는
                제외됩니다.
              </li>
              <li>
                회사는 서비스의 운영과 관련하여 서비스 화면, 홈페이지 등에
                광고를 게재할 수 있습니다.
              </li>
              <li>
                "이용자(회원, 비회원 포함)"는 회사가 제공하는 서비스와 관련하여
                게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지
                않습니다.
              </li>
            </ol>
          </h3>

          <h2>제 15조 게시물의 저작권</h2>
          <h3>
            <ol>
              <li>
                회원이 서비스 내에 게시한 게시물의 저작권은 해당 게시물의
                저작자에게 귀속됩니다.
              </li>
              <li>
                회원은 본인이 작성한 게시물(작품, 스터디, 스터디모음)을 서비스
                내에 공개할 경우 다음 각 호의 사항을 허락하며, 구체적인 내용은
                베티 저작권 정책에 따릅니다.
                <ol>
                  <li>
                    내가 만든 게시물(작품, 스터디, 스터디모음)과 그 소스 코드를
                    공개하는 것에 동의합니다.
                  </li>
                  <li>
                    다른 사람이 나의 게시물(작품, 스터디, 스터디모음)을 이용하는
                    것을 허락합니다. (비영리 목적의 복제, 공중송신, 배포 등을
                    포함)
                  </li>
                  <li>
                    다른 사람이 나의 게시물(작품, 스터디, 스터디모음)을 수정하는
                    것을 허락합니다. (리메이크, 변형 등 2차적 저작물 작성을
                    포함)
                  </li>
                </ol>
              </li>

              <li>
                회사는 회원이 서비스 내에 게시하는 게시물을 서비스 내 노출,
                서비스 홍보를 위한 활용, 서비스 운영, 개선 및 새로운 서비스
                개발을 위한 연구, 웹 접근성 등 법률상 의무 준수, 외부
                사이트에서의 검색, 수집 및 링크 허용 등을 위해서 사용할 수
                있으며, 회원은 회사의 해당 사용을 위해 필요한 범위 내에서 회원의
                게시물을 저장, 수정, 복제, 편집, 공중 송신, 전시, 배포, 2차적
                저작물 작성 등의 이용권한을 회사에게 부여합니다(기한과 지역
                제한에 정함이 없으며, 별도 대가 지급이 없는 라이선스). 회사는
                회원의 저작물을 이용함에 있어 저작권법 규정을 준수하며, 회원은
                언제든지 고객센터 또는 서비스 내 관리기능을 통해 해당 게시물에
                대해 삭제, 비공개 등의 조치를 취할 수 있습니다.
              </li>
              <li>
                회사는 제3항에서 명시된 것 이외의 목적과 방법으로 회원의
                게시물을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해
                사전에 회원의 동의를 얻어야 합니다.
              </li>
            </ol>
          </h3>

          <h2>제 16조 게시물의 관리</h2>
          <h3>
            <ol>
              <li>
                회원의 게시물이 정보통신망법 및 저작권법등 관계법령에 위반되는
                내용을 포함하는 경우, 권리자는 관계법령이 정한 절차에 따라 해당
                게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는
                관계법령에 따라 조치를 취하여야 합니다.
              </li>
              <li>
                회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가
                인정될 만한 사유가 있거나 기타 운영 정책 및 관계법령에 위반되는
                경우에는 관계법령에 따라 해당 게시물에 대해 임시조치 등을 취할
                수 있습니다.
              </li>
            </ol>
          </h3>

          <h2>제 17조 권리의 귀속</h2>
          <h3>
            <ol>
              <li>
                서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다. 단,
                회원의 게시물 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.
              </li>
              <li>
                회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라
                계정, 콘텐츠 등을 이용할 수 있는 이용권한만을 부여하며, 회원은
                이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.
              </li>
            </ol>
          </h3>

          <h2>제 18조 계약해제, 해지 등</h2>
          <h3>
            <ol>
              <li>
                회원은 언제든지 서비스 초기화면의 고객센터 또는 내 정보 관리
                메뉴 등을 통하여 이용계약 해지 신청을 할 수 있으며, 회사는
                관계법령 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
              </li>
              <li>
                회원이 계약을 해지할 경우, 관계법령 및 개인정보 처리방침에 따라
                회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의
                모든 데이터는 소멸됩니다.
              </li>
              <li>
                회원이 계약을 해지하는 경우, 회원이 작성한 게시물 중 작품,
                스터디, 스터디모음 등과 같이 본인 계정에 등록된 게시물 일체는
                삭제됩니다. 다만, 공용게시판에 등록된 게시물, 공유 등이 되어
                재게시된 게시물은 삭제되지 않으니, 직접 삭제 또는 회사에 삭제
                요청을 통하여 사전에 삭제 후 탈퇴해야 합니다.
              </li>
              <li>
                전항과 관련하여 회원이 저작자가 아니거나 타인이 공동으로 권리를
                보유한 게시물의 경우 삭제되지 않을 수 있습니다.
              </li>
            </ol>
          </h3>

          <h2>제 19조 이용제한 등</h2>
          <h3>
            <ol>
              <li>
                회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인
                운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 서비스
                이용을 단계적으로 제한할 수 있습니다.
              </li>
              <li>
                회사는 전항에도 불구하고, 결제도용, 저작권법 등 관계법령을
                위반한 불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한
                불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과
                같이 관계법령을 위반한 경우에는 즉시 영구이용정지를 할 수
                있습니다. 본 항에 따른 영구이용정지 시 회원에게 제공되는 서비스
                이용권한 등은 모두 소멸되며, 회사는 이에 대해 별도로 보상하지
                않습니다.
              </li>
              <li>
                회사는 회원이 계속해서 3개월 이상 로그인하지 않는 경우,
                회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수
                있습니다.
              </li>
              <li>
                회사는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은
                이용정책에서 정하는 바에 의합니다.
              </li>
              <li>
                본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는
                회사는 제9조에서 정한 방법에 따라 통지합니다.
              </li>
              <li>
                회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라
                이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가
                인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
              </li>
            </ol>
          </h3>

          <h2>제 20조 책임제한</h2>
          <h3>
            <ol>
              <li>
                회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를
                제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
              </li>
              <li>
                회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는
                책임을 지지 않습니다.
              </li>
              <li>
                회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의
                신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
              </li>
              <li>
                회사는 회원 간 또는 회원과 제3자 상호 간에 서비스를 매개로 하여
                거래 등을 한 경우에 해당 거래 등과 관련하여 책임을 지지
                않습니다.
              </li>
              <li>
                회사는 무료로 제공되는 서비스 이용과 관련하여 관계법령에 특별한
                규정이 없는 한 책임을 지지 않습니다.
              </li>
            </ol>
          </h3>

          <h2>제 21조 준거법 및 재판관할</h2>
          <h3>
            <ol>
              <li>
                회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.
              </li>
              <li>
                회사와 회원 간 발생한 분쟁에 관한 소송은 제소 당시의 회원의
                주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의
                전속관할로 합니다. 단, 제소 당시 회원의 주소 또는 거소가
                명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.
              </li>
              <li>
                해외에 주소나 거소가 있는 회원의 경우 회사와 회원간 발생한
                분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을
                관할법원으로 합니다.
              </li>
            </ol>
          </h3>
          <h2>부칙</h2>
          <h3>
            <ol>
              <li>이 약관은 2024년 1월 1일부터 적용됩니다.</li>
            </ol>
            <ul>
              <li>공고일자: 2023년 12월 25일</li>
              <li>시행일자: 2024년 1월 1일</li>
            </ul>
          </h3>
        </div>

        <div class="privacy" v-if="isPrivacy">
          <h1>개인정보 수집 및 취급 방침</h1>
          <h2>제 1조 수집하는 개인정보의 항목</h2>
          <h3>
            <ol>
              <li>
                일반 회원가입 시
                <ol>
                  <li>
                    필수항목: 아이디, 비밀번호, 성별, (선생님의 경우)
                    휴대전화번호
                  </li>
                  <li>선택항목: 이메일 주소</li>
                </ol>
              </li>
              <li>
                서비스 내 행사의 경품으로 재화 또는 서비스를 제공하는 경우
                추가적으로 다음과 같은 개인정보를 수집할 수 있습니다.
                <ol>
                  <li>
                    선택항목: 아이디, 닉네임, 이메일 주소, 전화번호, 주소, IP
                    주소, 생년월일, (만 14세 미만인 경우) 보호자의 이름,
                    보호자의 전화번호, 보호자와의 관계
                  </li>
                </ol>
              </li>
              <li>
                서비스 이용과정이나 사업 처리 과정에서 다음과 같은 정보들이
                자동으로 생성되어 수집될 수 있습니다.
                <ol>
                  <li>
                    IP 주소, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록
                  </li>
                  <li>
                    서비스 이용 기록에는 특정 서비스 이용량, 특정 콘텐츠 열람 및
                    ‘좋아요’ 기록, 서비스 이용 과정에서 이용자가 직접 입력한
                    텍스트 메시지, 음성 및 이미지 데이터 로그가 포함됩니다.
                  </li>
                </ol>
              </li>
            </ol>
          </h3>
          <h2>제 2조 개인정보 수집 및 이용 목적</h2>
          <h3>
            <ol>
              <li>
                회원 관리 회원제 서비스 이용에 따른 개인 식별, 원활한 의사소통
                경로의 확보, 고객 문의에 대한 응답, 새로운 정보의 소개 및
                고지사항 전달, 불량회원의 부정 이용 방지, 가입 의사 확인,
                불만처리 등 민원처리
              </li>
              <li>
                정보 전달 및 서비스 개선 베티 서비스의 최신 개발 정보와 업데이트
                전달, 이용 통계 분석을 통한 사이트 개선 등
              </li>
              <li>통계작성, 과학적 연구, 공익적 기록보존 목적</li>
              <li>
                법령 등의 이행 및 준수 법령 등에 규정된 의무의 이행, 법령이나
                이용약관에 반하여 정보주체에게 피해를 줄 수 있는 잘못된
                이용행위의 방지 등
              </li>
              <li>사고발생 시 원인 규명 및 처리</li>
              <li>행사 경품으로 재화 또는 서비스를 제공</li>
            </ol>
          </h3>
          <h2>제 3조 개인정보의 보유 및 이용기간</h2>
          <h3>
            <ol>
              <li>
                개인정보 수집 및 이용 목적이 달성된 후에는 해당 정보를 지체없이
                파기합니다.
              </li>
              <li>
                위 개인정보 수집 및 이용 동의는 거부하실 수 있으며, 동의 거부 시
                회원가입이 제한됩니다.
              </li>
            </ol>
          </h3>
        </div>
      </div>
    </div>
  </main>
  <the-footer />
</template>
<style scoped></style>
