<script setup>
const props = defineProps({
  message: {
    type: String,
    required: true,
    default: "",
  },
});
</script>
<template>
  <div class="mb" ref="mb">{{ props.message }}</div>
</template>

<style scoped>
.mb {
  position: absolute;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2),
    /* 위 (상단) */ 0 -4px 8px rgba(0, 0, 0, 0.05),
    /* 아래 (하단) */ -8px 0 12px rgba(0, 0, 0, 0.05),
    /* 왼쪽 */ 8px 0 12px rgba(0, 0, 0, 0.05); /* 오른쪽 */
}

.mb:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 14px solid transparent;
  border-top-color: white;
  border-bottom: 0;
  margin-left: -14px;
  margin-bottom: -14px;
}
</style>
