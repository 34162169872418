export const Mission = {
  namespaced: true,
  state: () => ({
    isUsable: false,
    mission: [false, false, false],
  }),
  mutations: {
    setIsUsable(state, value) {
      state.isUsable = value;
    },
    getMissionComplete(state, value) {
      return state.mission[value];
    },
    setMissionComplete(state, value) {
      state.mission[value] = true;
    },
  },
  actions: {},
  getters: {},
};
