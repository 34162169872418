<script setup>
import { ref, onMounted, computed, watch } from "vue";
import GemBasicSprite from "@/components/sprite/GemBasicSprite";
import GemGraspSprite from "@/components/sprite/GemGraspSprite";
import GemHandscircleSprite from "@/components/sprite/GemHandscircleSprite";
import GemHandsleftSprite from "@/components/sprite/GemHandsleftSprite";
import GemHandsrightSprite from "@/components/sprite/GemHandsrightSprite";
import GemHandsupExcitedSprite from "@/components/sprite/GemHandsupExcitedSprite";
import GemHandsupSprite from "@/components/sprite/GemHandsupSprite";
import GemSitdownSprite from "@/components/sprite/GemSitdownSprite";
import MessageBox from "@/components/MessageBox";
import { useStore } from "vuex";
const store = useStore();

const messages = [
  "안녕하세요 여러분, 잼 코딩에 오신걸 환영합니다",
  "제 이름은 잼재미에요, 저와 같이 조작법을 배워보아요",
  "옆에서 항해사님의 모습이 나오고 있어요, 자유롭게 움직여보세요",
  "몸으로 모양을 만들면 '블록'을 만들 수 있어요",
  "팔을 왼쪽으로, 높게 들면 '왼쪽' 블록을 만들 수 있어요",
  "'왼쪽' 블럭이 생기는 순간 '위 버튼'을 눌러보아요", //5
  "그러면 아래에 만든 블록이 연결되어요",
  "이번엔 팔을 오른쪽으로 들고 '위 버튼'을 눌러보아요", //7
  "이렇게 하면 왼쪽, 오른쪽으로 이동할 준비가 되었어요.",
  "손을 들고 동그라미 모양을 만들어보아요", //9
  "그러면 붙인 블럭의 순서대로 행동해요",
  "위로 쭉 뻗어보아요", //11
  "앉아보아요", //12
  "오른팔을 굽혀보아요, 이건 레이저를 쏘는 동작이에요", //13
  "다시 동그라미 모양을 만들어보아요", //14
  "'아래' 버튼을 누르면 블럭을 하나 지울 수 있어요'",
  "항해사님이 수행할 임무가 적혀있는 곳이에요",
  "다른 활동을 하고 싶다면 이곳을 확인하세요",
  "저와 같이 우주를 항해할 준비가 되었나요?",
  "렛츠 고~~~",
];
let step = computed(() => {
  return store.getters["Space/getTutorialStep"];
});
let gemSpriteState = ref("basic");
let gemElem = ref(null);

function shutterSound() {
  const shutter = new Audio(require("@/assets/camera_shutter.mp3"));
  shutter.play();
}
function lazerSound() {
  const lazerSound = new Audio(require("@/assets/lazer.mp3"));
  lazerSound.play();
}

onMounted(() => {
  const remoteAction = (action) => {
    if (action === "up") {
      switch (step.value) {
        case 5:
          if (store.state.Camera.actionPresent !== "left go") return;
          shutterSound();
          store.commit("Block/pushBlock", "left go");
          break;
        case 7:
          if (store.state.Camera.actionPresent !== "right go") return;
          shutterSound();
          store.commit("Block/pushBlock", "right go");
          break;
        case 9:
          if (store.state.Camera.actionPresent !== "circle") return;
          shutterSound();
          break;
        case 11:
          if (store.state.Camera.actionPresent !== "up go") return;
          shutterSound();
          store.commit("Block/pushBlock", "up go");
          break;
        case 12:
          if (store.state.Camera.actionPresent !== "down go") return;
          shutterSound();
          store.commit("Block/pushBlock", "down go");
          break;
        case 13:
          if (store.state.Camera.actionPresent !== "shoot") return;
          shutterSound();
          store.commit("Block/pushBlock", "shoot");
          break;
        case 14:
          if (store.state.Camera.actionPresent !== "circle") return;
          shutterSound();
          break;
        case 15:
          return;
        case 19:
          return;
      }
      store.commit("Space/incTutorialStep");
    }
    if (action === "down") {
      if (step.value == 15) {
        store.commit("Block/popBlock");
        store.commit("Space/incTutorialStep");
        return;
      }
    }
  };

  document.addEventListener("click", () => {
    remoteAction("up");
  });

  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
    remoteAction("down");
  });
  document.addEventListener("keydown", (event) => {
    if (event.key == "PageDown") {
      remoteAction("down");
    }
    if (event.key == "PageUp") {
      remoteAction("up");
    }
  });
});

watch(step, async (newValue) => {
  gemSpriteState.value = "basic";
  switch (newValue) {
    case 1:
      break;
    case 2:
      break;
    case 3:
      break;
    case 4:
      gemSpriteState.value = "hands-left";
      break;
    case 5:
      gemSpriteState.value = "hands-left";
      break;
    case 6:
      break;
    case 7:
      gemSpriteState.value = "hands-right";
      break;
    case 8:
      break;
    case 9:
      gemSpriteState.value = "hands-circle";
      break;
    case 10:
      gemElem.value.$el.style.transform = "translate(-70%, -50%)";
      await new Promise((resolve) => setTimeout(resolve, 500));
      gemElem.value.$el.style.transform = "translate(-50%, -50%)";
      //left right
      break;
    case 11:
      gemSpriteState.value = "hands-up";
      break;
    case 12:
      gemSpriteState.value = "sit-down";
      break;
    case 13:
      gemSpriteState.value = "grasp";
      break;
    case 14:
      gemSpriteState.value = "hands-circle";
      break;
    case 15:
      gemElem.value.$el.style.transform = "translate(-70%, -50%)";
      await new Promise((resolve) => setTimeout(resolve, 500));
      gemElem.value.$el.style.transform = "translate(-50%, -50%)";
      await new Promise((resolve) => setTimeout(resolve, 500));
      gemElem.value.$el.style.transform = "translate(-50%, -70%)";
      await new Promise((resolve) => setTimeout(resolve, 500));
      gemElem.value.$el.style.transform = "translate(-50%, -50%)";
      await new Promise((resolve) => setTimeout(resolve, 500));
      lazerSound();
      break;
    case 16:
      break;
    case 17:
      break;
    case 18:
      break;
    case 19:
      gemSpriteState.value = "hands-up-excited";
      break;
  }
});
</script>
<template>
  <gem-basic-sprite
    class="gem basic"
    v-show="gemSpriteState == 'basic'"
    ref="gemElem"
  />
  <gem-grasp-sprite class="gem grasp" v-if="gemSpriteState == 'grasp'" />
  <gem-handscircle-sprite
    class="gem hands-circle"
    v-if="gemSpriteState == 'hands-circle'"
  />
  <gem-handsleft-sprite
    class="gem hands-left"
    v-if="gemSpriteState == 'hands-left'"
  />
  <gem-handsright-sprite
    class="gem hands-right"
    v-if="gemSpriteState == 'hands-right'"
  />
  <gem-handsup-excited-sprite
    class="gem hands-up-excited"
    v-if="gemSpriteState == 'hands-up-excited'"
  />
  <gem-handsup-sprite
    class="gem hands-up"
    v-if="gemSpriteState == 'hands-up'"
  />
  <gem-sitdown-sprite
    class="gem sit-down"
    v-if="gemSpriteState == 'sit-down'"
  />
  <message-box
    :message="messages[store.state.Space.tutorialStep]"
    style="
      position: absolute;
      left: 50%;
      top: 20%;
      transform: translate(-50%, -50%);
      font-size: 24px;
    "
  />
</template>
<style scoped>
.gem {
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
}

.basic {
}
.grasp {
  margin-left: 17px;
}
.hands-circle {
  margin-left: 40px;
  margin-top: 12px;
}
.hands-left {
}
.hands-right {
}
.hands-up-excited {
  margin-left: 33px;
  margin-top: 12px;
}

.hands-up {
  margin-left: 33px;
  margin-top: 12px;
}

.sit-down {
  margin-left: 10px;
}
</style>
