<script setup>
import MotionSpaceIcon from "@/components/icon/MotionSpaceIcon";
import MotionSpaceFullscreenIcon from "@/components/icon/MotionSpaceFullscreenIcon";
import MotionSpaceAddIcon from "@/components/icon/MotionSpaceAddIcon";
import MotionSpaceDeleteIcon from "@/components/icon/MotionSpaceDeleteIcon";

import SpaceMaze from "@/components/space/SpaceMaze.vue";
import SpaceTutorial from "@/components/space/SpaceTutorial.vue";

const props = defineProps({
  type: {
    type: String,
    required: true,
    default: "",
  },
});

/*
async function execute() {
  for (let block of block_list) {
    if (block == "left go") {
      const xPos = parseInt(dogRef.value.style.left);
      dogRef.value.style.left = `${xPos - 20}px`;
    }
    if (block == "right go") {
      const xPos = parseInt(dogRef.value.style.left);
      dogRef.value.style.left = `${xPos + 20}px`;
    }
    if (block == "front go") {
      const yPos = parseInt(dogRef.value.style.top);
      dogRef.value.style.top = `${yPos - 20}px`;
    }
    if (block == "hands up") {
      let yPos = parseInt(dogRef.value.style.top);
      for (let i = 0; i < 4; i++) {
        yPos -= 20;

        dogRef.value.style.top = `${yPos}px`;
        await new Promise((resolve) => setTimeout(resolve, 300));
      }
      for (let i = 0; i < 4; i++) {
        yPos += 20;

        dogRef.value.style.top = `${yPos}px`;
        await new Promise((resolve) => setTimeout(resolve, 300));
      }
    }

    await new Promise((resolve) => setTimeout(resolve, 300));
  }
}
*/
</script>

<template>
  <div class="wrap">
    <space-tutorial v-if="props.type == 'tutorial'" />
    <space-maze v-if="props.type == 'maze'" />

    <motion-space-fullscreen-icon
      style="position: absolute; top: 10px; right: 10px"
    />

    <motion-space-add-icon
      style="position: absolute; bottom: 10px; right: 10px"
    />
    <motion-space-delete-icon
      style="position: absolute; bottom: 10px; right: 90px"
    />

    <div class="title">
      <div class="title_icon">
        <motion-space-icon />
      </div>
      <div class="title_text">스페이스</div>
    </div>
  </div>
</template>
<style scoped>
.wrap {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2),
    /* 위 (상단) */ 0 -4px 8px rgba(0, 0, 0, 0.05),
    /* 아래 (하단) */ -8px 0 12px rgba(0, 0, 0, 0.05),
    /* 왼쪽 */ 8px 0 12px rgba(0, 0, 0, 0.05); /* 오른쪽 */
}

.title {
  position: absolute;
  top: 10px;
  left: 15px;
  display: flex;
  align-items: center;
}

.title_icon {
  width: 14px;
  height: 14px;
  margin-right: 7px;
}
</style>
