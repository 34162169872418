<script setup>
import MotionBlockIcon from "@/components/icon/MotionBlockIcon";
import MotionBlockCircle from "@/components/block/MotionBlockCircle";
import MotionBlockUp from "@/components/block/MotionBlockUp";
import MotionBlockDown from "@/components/block/MotionBlockDown";
import MotionBlockLeft from "@/components/block/MotionBlockLeft";
import MotionBlockRight from "@/components/block/MotionBlockRight";
import MotionBlockShoot from "@/components/block/MotionBlockShoot";
import { useStore } from "vuex";
const store = useStore();

let block_list = store.state.Block.block;
</script>

<template>
  <div class="wrap">
    <div class="bg"></div>
    <div class="title">
      <div class="title_icon">
        <motion-block-icon />
      </div>
      <div class="title_text">블럭</div>
    </div>
    <div class="block_list">
      <motion-block-circle />

      <div v-for="item in block_list" :key="item.id">
        <motion-block-circle v-if="item == 'circle'" />
        <motion-block-left v-if="item == 'left go'" />
        <motion-block-right v-if="item == 'right go'" />
        <motion-block-up v-if="item == 'up go'" />
        <motion-block-down v-if="item == 'down go'" />
        <motion-block-shoot v-if="item == 'shoot'" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.block_list {
  display: flex;
  position: absolute;
  top: 50px;
  left: 50px;
  filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.2));
}
.wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 7px;
  box-sizing: border-box;
  border-radius: 10px 0 10px 0;
  box-shadow: -2px 2px 4px 2px rgba(0, 0, 0, 0.05),
    2px -2px 4px 2px rgba(0, 0, 0, 0.05);
}

.bg {
  width: 100%;
  height: 100%;
  background: repeating-radial-gradient(
    circle at 10px 10px,
    rgb(230, 230, 230) 0,
    rgb(230, 230, 230) 2.5px,
    transparent 2.5px,
    transparent 25px
  );
  background-size: 25px 25px; /* 동그라미 간격 설정 */
}

.title {
  position: absolute;
  top: 10px;
  left: 15px;
  width: 70px;
  display: flex;
  align-items: center;
  background-color: white;
}

.title_icon {
  width: 14px;
  height: 14px;
  margin-right: 7px;
}
</style>
