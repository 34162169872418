<script setup>
import { ref, watch, computed, onMounted } from "vue";
import { useStore } from "vuex";
import BetyChainReactionSlider from "@/components/bety/BetyChainReactionSlider";

// import Matter from "matter-js";
import {
  Engine,
  Render,
  Runner,
  //   MouseConstraint,
  //   Mouse,
  World,
  Body,
  Bodies,
  Events,
  Constraint,
  Composite,
} from "matter-js";

const store = useStore();

let port = undefined;
// let handPresent = computed(() => store.state.Camera.handPresent);
const handLandmark = computed(() => store.state.Camera.handLandmark);
const handPresent = computed(() => store.state.Camera.handPresent);
const faceLandmark = computed(() => store.state.Camera.faceLandmark);
const faceScore = computed(() => store.state.Camera.faceScore);
// let cameraWidth = computed(() => store.state.Camera.cameraWidth);
// let cameraHeight = computed(() => store.state.Camera.cameraHeight);
const serialConnected = ref(false);
const robotValid = ref(false);
const sensorIrLeft = ref(0);
const sensorIrRight = ref(0);
const sensorColor = ref(0);
const sensorOutput = ref(0);
const sensorRange = ref(50);
const motorRange = ref(100);

const canvasElement = ref(null);
const wrapElement = ref(null);

let matterEngine = Engine.create();
let matterWorld = undefined;
let matterRender = undefined;
let matterRunner = undefined;
let matterWorldSize = { width: 800, height: 600 };
let bodyCircle = undefined;
let bodyRock = undefined;
let bodyElastic = undefined;
let bodyStartPoint;
let bodyEndPoint;
let anchor = { x: 600, y: 450 };
const startFlag = ref(false);

Events.on(matterEngine, "collisionStart", async (event) => {
  const pairs = event.pairs;
  pairs.forEach(async (pair) => {
    const bodyA = pair.bodyA;
    const bodyB = pair.bodyB;
    if (
      (bodyA === bodyCircle && bodyB === bodyEndPoint) ||
      (bodyA === bodyEndPoint && bodyB === bodyCircle)
    )
      await successMission();
  });
});

const startMission = () => {
  //   if (!bodyCircle) return;
  if (startFlag.value) return;
  const cw = matterWorldSize.width;
  bodyCircle = Bodies.circle(cw - 400, 150, 50, {
    render: { fillStyle: "blue" },
    restitution: 1.5,
  });
  bodyCircle.friction = 0.1;

  startFlag.value = true;
  //   Body.setVelocity(bodyCircle, { x: 0, y: 0 });
  //   Body.setPosition(bodyCircle, { x: 100, y: 120 });
  //   bodyCircle.torque = 10;
  //   const forceMagnitude = 0.2;
  //   Body.applyForce(
  //     bodyCircle,
  //     { x: bodyCircle.position.x, y: bodyCircle.position.y },
  //     { x: forceMagnitude, y: 0 }
  //   );
  World.add(matterWorld, [bodyCircle]);
};

const initMatter = () => {
  const cw = matterWorldSize.width;
  const ch = matterWorldSize.height;

  matterWorld = matterEngine.world;
  matterRender = Render.create({
    canvas: canvasElement.value,
    engine: matterEngine,
    options: {
      width: cw,
      height: ch,
      background: "transparent",
      pixelRatio: "auto",
      wireframes: false,
    },
  });
  matterRunner = Runner.create();
  Render.run(matterRender);
  Runner.run(matterRunner, matterEngine);

  Render.lookAt(matterRender, {
    min: { x: 0, y: 0 },
    max: { x: cw, y: ch },
  });

  bodyStartPoint = Bodies.rectangle(cw - 250, ch - 150, 500, 50, {
    isStatic: true,
    render: { fillStyle: "#903EF7" },
  });
  bodyEndPoint = Bodies.rectangle(cw, ch / 2, 50, ch, {
    isStatic: true,
    isSensor: true,
    render: {
      fillStyle: "#903EF7",
    },
  });
  //   Body.setVelocity(bodyCircle, { x: 1, y: 0 });
  bodyStartPoint.friction = 100;

  let rockOptions = { density: 0.004 };
  bodyRock = Bodies.polygon(anchor.x, anchor.y, 8, 20, rockOptions);
  bodyElastic = Constraint.create({
    pointA: anchor,
    bodyB: bodyRock,
    length: 0.01,
    damping: 0.01,
    stiffness: 0.05,
  });

  World.add(matterWorld, [bodyStartPoint, bodyEndPoint, bodyRock, bodyElastic]);
};

onMounted(() => {
  if (!("serial" in navigator)) {
    alert("Serial Not Supported");
    return;
  }
  const width = wrapElement.value.offsetWidth;
  const height = wrapElement.value.offsetHeight;
  canvasElement.value.width = width;
  canvasElement.value.height = height;
  canvasElement.value.style.width = width + "px";
  canvasElement.value.style.height = height + "px";

  matterWorldSize.width = width;
  matterWorldSize.height = height;
  initMatter();
});

const connectSerial = async () => {
  const filters = [
    { usbVendorId: 0x10c4, usbProductId: 0xea60 },
    // { usbVendorId: 0x0d28, usbProductId: 0x0204 },
  ];

  try {
    port = await navigator.serial.requestPort({ filters });
    await port.open({
      baudRate: 500000,
      dataBits: 8,
      stopBits: 1,
      parity: "none",
      bufferSize: 255, //byte
      flowControl: "none",
    });
    serialConnected.value = true;
    robotValid.value = true;

    // const reader = port.readable.getReader();
    // while (true) {
    //   const { value, done } = await reader.read();
    //   if (done) break;
    //   receivedData.value = new TextDecoder().decode(value);
    //   console.log("Received Data:", receivedData.value);
    // }
  } catch (error) {
    console.error("Error connecting to serial:", error);
  }

  const data = new Uint8Array([0xaa, 0xaa, 0xcc, 0x02, 0x02, 0x03, 0x05]);
  sendCommand(data);
  while (port.readable) {
    const reader = port.readable.getReader();
    let done = false;
    let value = null;
    try {
      while (!done) {
        const r = await reader.read();
        value = r.value;
        done = r.done;
        if (value.length >= 43) {
          console.log(value[31], value[33], value[35]);
          sensorIrLeft.value = value[18];
          sensorIrRight.value = value[20];
          sensorColor.value = value[29];
          console.log(value.length);
          if (value.length == 43) sensorOutput.value = value[40];
          if (value.length == 49) sensorOutput.value = value[40];
          switch (value[29]) {
            case 40:
              sensorColor.value = "White";
              break;
            case 80:
              sensorColor.value = "Red";
              break;
            case 120:
              sensorColor.value = "Yellow";
              break;
            case 160:
              sensorColor.value = "Green";
              break;
            case 200:
              sensorColor.value = "Blue";
              break;
            default:
              sensorColor.value = "Undefined";
              break;
          }
        }
      }
    } finally {
      console.log("Stream complete");
      reader.releaseLock();
    }
  }
};

const toggleRobot = async () => {
  if (bodyCircle != undefined) {
    World.remove(matterWorld, bodyCircle);
    bodyCircle = undefined;
  }
  if (!serialConnected.value) return;
  startFlag.value = false;
  robotValid.value = !robotValid.value;
  const code = makeCommand("both", 0);
  await sendCommand(code);
};

const sendCommand = async (serialData) => {
  const writer = port.writable.getWriter();
  await writer.write(serialData);
  writer.releaseLock();
};

// const calibrateIr = async (color) => {
//   const cmd = color == "white" ? 0x06 : 0x07;
//   const parity = color == "white" ? 0x29 : 0x2a;
//   const data = new Uint8Array([
//     0xaa,
//     0xaa,
//     0xcc,
//     0x05,
//     0x01,
//     0x02,
//     0x20,
//     cmd,
//     0x00,
//     parity,
//   ]);
//   await sendCommand(data);
// };

const makeCommand = (direction = "both", speed = 0) => {
  let directionCode = 0x00;
  if (speed < 0) directionCode = 0x01;

  let speedCode = Math.abs(speed);
  speedCode = speedCode <= 100 ? speedCode : 100;

  let motorCode = 0x00;
  //   if (direction === "left") motorCode = 0x01;
  //   if (direction === "right") motorCode = 0x02;
  console.log(direction);

  const ackCode = 0x11;
  const dataStart = new Uint8Array([0xaa, 0xaa, 0xcc]);
  const dataPayload = new Uint8Array([
    0x01,
    0x10,
    ackCode,
    0x00,
    0x00,
    0x03,
    speedCode,
    0x00,
    motorCode,
    0x00,
    directionCode,
    0x00,
  ]);
  const dataLength = new Uint8Array([dataPayload.length]);
  const dataParity = new Uint8Array([
    dataPayload.reduce((acc, cur) => acc + cur, 0),
  ]);
  const data = new Uint8Array([
    ...dataStart,
    ...dataLength,
    ...dataPayload,
    ...dataParity,
  ]);

  return data;
};

console.log(sendCommand);
console.log(makeCommand);

const dragRock = (handPosition) => {
  const { x, y } = handPosition;
  const worldX = (1 - x) * matterWorldSize.width;
  const worldY = y * matterWorldSize.height;
  Body.setPosition(bodyRock, { x: worldX, y: worldY });
  Body.setVelocity(bodyRock, { x: 0, y: 0 });
};

Events.on(matterEngine, "afterUpdate", function () {
  if (
    !fingerStick &&
    (bodyRock.position.x < anchor.x - 20 || bodyRock.position.y > anchor.y + 20)
  ) {
    // Limit maximum speed of current rock.
    if (Body.getSpeed(bodyRock) > 45) {
      Body.setSpeed(bodyRock, 45);
    }
    bodyRock = Bodies.polygon(anchor.x, anchor.y, 7, 20, {
      density: 0.004,
      isSensor: true,
    });
    Composite.add(matterWorld, bodyRock);
    bodyElastic.bodyB = bodyRock;
    setTimeout(() => {
      bodyRock.isSensor = false;
    }, 100);
  }
});

// const shootRock = () => {
//   console.log(bodyRock.position.x, bodyRock.position.y);
//   if (
//     bodyRock.position.x < anchor.x - 20 ||
//     bodyRock.position.y > anchor.y + 20
//   ) {
//     if (Body.getSpeed(bodyRock) > 45) {
//       Body.setSpeed(bodyRock, 45);
//     }
//     bodyRock = Bodies.polygon(anchor.x, anchor.y, 7, 20, { density: 0.004 });
//     Composite.add(matterWorld, bodyRock);
//     bodyElastic.bodyB = bodyRock;
//   }
// };

let fingerStick = false;

const calculateDistance = (p1, p2) => {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  const distance = Math.sqrt(dx * dx + dy * dy);
  return distance;
};

const successMission = async () => {
  console.log(motorRange.value);
  const leftCode = makeCommand("left", motorRange.value);
  const rightCode = makeCommand("right", motorRange.value);
  await sendCommand(leftCode);
  await sendCommand(rightCode);
};

watch(sensorOutput, async (newValue) => {
  if (newValue > 0 && newValue > sensorRange.value) {
    startMission();
  }
});
watch(handPresent, async () => {
  //   if (newValue == "") shootRock();
  //   if (newValue == "circle") makeRectangle(handLandmark.value[13]);
});
watch(handLandmark, async (newValue) => {
  if (calculateDistance(newValue[4], newValue[8]) < 0.1) {
    fingerStick = true;
    dragRock(newValue[8]);
  } else fingerStick = false;

  if (!serialConnected.value) return;
  if (!robotValid.value) return;
  //   let handX = 1 - newValue[8].x;
  //   let handY = 1 - newValue[8].y;
  //   handX = handX >= 0 ? handX : 0;
  //   handX = handX <= 1 ? handX : 1;
  //   handX -= 0.5;
  //   handX *= 2;
  //   if (handX >= -0.1 && handX <= 0.1) handX = 0;
  //   handY = handY >= 0 ? handY : 0;
  //   handY = handY <= 1 ? handY : 1;
  //   handY -= 0.5;
  //   handY *= 2;
  //   if (handY >= -0.1 && handY <= 0.1) handY = 0;

  //   let leftSpeed = parseInt(handY * 100);
  //   let rightSpeed = parseInt(handY * 100);
  //   if (handX > 0) {
  //     leftSpeed = parseInt(handY * 100);
  //     rightSpeed = parseInt(handY * (1 - handX) * 100);
  //   } else if (handX < 0) {
  //     leftSpeed = parseInt(handY * (1 + handX) * 100);
  //     rightSpeed = parseInt(handY * 100);
  //   }
  //   console.log(leftSpeed, rightSpeed);
  //   const leftCode = makeCommand("left", leftSpeed);
  //   const rightCode = makeCommand("right", rightSpeed);
  //   await sendCommand(leftCode);
  //   await sendCommand(rightCode);
});

watch(faceLandmark, async (newValue) => {
  if (!serialConnected.value) return;
  if (!robotValid.value) return;

  let faceX = 1 - newValue[1].x;
  faceX -= 0.5;
  faceX *= 4;
  if (faceX >= -0.1 && faceX <= 0.1) faceX = 0;
  faceX = faceX >= -1 ? faceX : -1;
  faceX = faceX <= 1 ? faceX : 1;

  console.log(faceScore.value.mouthOpen, faceScore.value.mouthSmile);
  const mouthOpen = faceScore.value.mouthOpen;
  const mouthSmile = faceScore.value.mouthSmile;
  let score = mouthOpen > mouthSmile ? mouthOpen : mouthSmile;
  score = score < 0.1 ? 0 : score;
  score *= 10 / 8;
  score = score >= 1.0 ? 1 : score;

  let leftSpeed = parseInt(score * 100);
  let rightSpeed = parseInt(score * 100);
  if (faceX > 0) {
    leftSpeed = parseInt(score * 100);
    rightSpeed = parseInt(score * (1 - faceX) * 100);
  } else if (faceX < 0) {
    leftSpeed = parseInt(score * (1 + faceX) * 100);
    rightSpeed = parseInt(score * 100);
  }
  if (mouthSmile > mouthOpen) {
    leftSpeed = -leftSpeed;
    rightSpeed = -rightSpeed;
  }
  const leftCode = makeCommand("left", leftSpeed);
  const rightCode = makeCommand("right", rightSpeed);
  await sendCommand(leftCode);
  await sendCommand(rightCode);
});

const sensorRangeChanged = (value) => {
  console.log("sensor: " + value);
  sensorRange.value = value;
};
const motorRangeChanged = (value) => {
  console.log("motor: " + value);
  motorRange.value = value;
};
</script>

<template>
  <div class="wrap" ref="wrapElement">
    <canvas
      style="width: 800px; height: 600px"
      width="800"
      height="600"
      ref="canvasElement"
    ></canvas>
    <div class="connect_robot" @click="connectSerial">로봇 연결</div>
    <div class="toggle_robot" @click="toggleRobot" v-if="serialConnected">
      재시작
    </div>
    <!-- <div
      class="calib calib_white"
      @click="calibrateIr('white')"
      v-if="serialConnected"
    >
      흰색 조정
    </div>
    <div
      class="calib calib_black"
      @click="calibrateIr('black')"
      v-if="serialConnected"
    >
      검은색 조정
    </div>
    <div class="robot_sensor" v-if="serialConnected">
      [센서 값]
      <div class="sensor_ir_left">적외선(L) : {{ sensorIrLeft }}</div>
      <div class="sensor_ir_right">적외선(R) : {{ sensorIrRight }}</div>
      <div class="sensor_color">색깔 : {{ sensorColor }}</div>
    </div> -->
    <bety-chain-reaction-slider
      v-if="serialConnected"
      :sensorOutput="sensorOutput"
      @sensorValue="sensorRangeChanged"
      @motorValue="motorRangeChanged"
    />
  </div>
</template>

<style scoped>
.wrap {
  position: absolute;
  width: 100%;
  height: 100%;
}
.toggle_robot {
  position: absolute;
  right: 170px;
  top: 20px;
  width: 100px;
  height: 50px;
  border: 0;
  color: var(--color-bety-white);
  background-color: var(--color-bety-error);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calib {
  position: absolute;
  width: 100px;
  height: 50px;
  border: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calib_white {
  right: 50px;
  top: 90px;
  color: var(--color-bety-black);
  background-color: var(--color-bety-white);
}

.calib_black {
  right: 50px;
  top: 160px;
  color: var(--color-bety-white);
  background-color: var(--color-bety-black);
}

.connect_robot {
  position: absolute;
  right: 50px;
  top: 20px;
  width: 100px;
  height: 50px;
  border: 0;
  background-color: var(--color-bety-complementary);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.robot_sensor {
  position: absolute;
  top: 70px;
  left: 50px;
  width: 150px;
  height: 150px;
  border: 0;
  padding: 10px;
  font-size: 18px;
  color: var(--color-bety-white);
  background-color: var(--color-bety-tertiary);
  border-radius: 10px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
}
</style>
