<template>
  <div class="push">
    <button class="btn-push">+</button>
  </div>
</template>

<style scoped>
/*
.push {
  position: absolute;
  bottom: 20px;
  right: 10px;
}
*/

.btn-push {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: solid 1px var(--color-primary);
  background-color: var(--color-primary);
  color: white;
  font-size: 20px;
  font-weight: 700;
}
</style>
