<template>
  <li>
    <div class="item-left" @click="clickItem">
      <div class="chk" :checked="props.item.checked"></div>
    </div>
    <div class="item-middle" @click="clickItem">
      <label class="title" :checked="props.item.checked">{{
        props.item.name
      }}</label>
      <label class="description" :checked="props.item.checked">{{
        props.item.description
      }}</label>
    </div>
    <div class="item-right">
      <button class="info" @click="openModal">i</button>
    </div>
  </li>
</template>

<script setup>
import { useStore } from "vuex";
const store = useStore();

const props = defineProps({
  item: Object,
});
const emit = defineEmits(["toggleChk"]);

const clickItem = () => {
  emit("toggleChk", props.item.idx);
  return false;
};

const openModal = () => store.commit("Checklist/openModal");
</script>

<style scoped>
.chk {
  width: 18px;
  height: 18px;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
}
.chk[checked="true"] {
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary);
}
li {
  width: 100%;
  display: flex;
  align-items: center;
}

.item-middle {
  display: flex;
  flex-direction: column;
  padding: 6px 0px 7px 10px;
}

.item-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.info {
  width: 15px;
  height: 15px;
  background-color: var(--color-complementary);
  color: white;
  border: 0px;
  border-radius: 5px;
  font-size: 12px;
}
.title {
  font-size: 14px;
  margin-bottom: 3px;
}
.title[checked="true"] {
  color: #797979;
  text-decoration: line-through;
}
.description {
  font-size: 11px;
  color: #797979;
}
.description[checked="true"] {
  color: #797979;
}
</style>
