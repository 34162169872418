<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="#eac04a" />
    <path
      d="M19.2713 15.795C20.9138 16.5714 22.3144 17.7798 23.3229 19.2909C24.3315 20.802 24.9102 22.5589 24.997 24.3736C25.0085 24.5399 24.9866 24.7067 24.9327 24.8644C24.8788 25.0221 24.7939 25.1674 24.6831 25.2918C24.5723 25.4163 24.4377 25.5173 24.2873 25.589C24.1368 25.6608 23.9736 25.7017 23.8072 25.7095C23.6407 25.7173 23.4744 25.6917 23.3179 25.6343C23.1615 25.577 23.018 25.4889 22.8961 25.3753C22.7741 25.2618 22.6761 25.125 22.6077 24.9731C22.5393 24.8211 22.502 24.657 22.4979 24.4904C22.4069 22.5627 21.5771 20.744 20.1807 19.412C18.7843 18.0799 16.9286 17.3367 14.9987 17.3367C13.0688 17.3367 11.2131 18.0799 9.8167 19.412C8.4203 20.744 7.59049 22.5627 7.49955 24.4904C7.47707 24.8167 7.32773 25.1212 7.0835 25.3388C6.83926 25.5563 6.51956 25.6696 6.19285 25.6543C5.86613 25.639 5.55838 25.4965 5.3355 25.2571C5.11262 25.0177 4.99233 24.7006 5.00038 24.3736C5.0869 22.5591 5.66526 20.8022 6.67356 19.2911C7.68185 17.78 9.08211 16.5715 10.7244 15.795C9.67174 14.917 8.91507 13.736 8.55736 12.4126C8.19964 11.0893 8.25825 9.68788 8.72521 8.39903C9.19217 7.11019 10.0448 5.99647 11.1672 5.20937C12.2895 4.42227 13.627 4 14.9979 4C16.3687 4 17.7063 4.42227 18.8286 5.20937C19.9509 5.99647 20.8036 7.11019 21.2705 8.39903C21.7375 9.68788 21.7961 11.0893 21.4384 12.4126C21.0807 13.736 20.324 14.917 19.2713 15.795ZM19.1695 10.6682C19.1695 9.56207 18.7301 8.5012 17.9479 7.71902C17.1658 6.93684 16.1049 6.49741 14.9987 6.49741C13.8925 6.49741 12.8317 6.93684 12.0495 7.71902C11.2673 8.5012 10.8279 9.56207 10.8279 10.6682C10.8279 11.7744 11.2673 12.8353 12.0495 13.6175C12.8317 14.3997 13.8925 14.8391 14.9987 14.8391C16.1049 14.8391 17.1658 14.3997 17.9479 13.6175C18.7301 12.8353 19.1695 11.7744 19.1695 10.6682Z"
      fill="white"
      fill-opacity="0.7"
    />
  </svg>
</template>
