<template>
  <svg
    width="80"
    height="72"
    viewBox="0 0 80 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="margin-right: -10px"
  >
    <g clip-path="url(#clip0_855_1278)">
      <path
        d="M72.0657 24.425H71.9822C71.2111 24.425 70.5923 23.7973 70.5923 23.0263V12.1826C70.5923 5.99466 65.6071 0.973145 59.453 0.973145H12.1159C5.96174 0.973145 0.976562 5.98971 0.976562 12.1826V59.8178C0.976562 66.0057 5.96174 71.0272 12.1159 71.0272H59.453C65.6022 71.0272 70.5923 66.0106 70.5923 59.8178V48.9741C70.5923 48.1981 71.2161 47.5753 71.9822 47.5753H72.0657C75.9115 47.5753 79.0253 44.4369 79.0253 40.5719V31.4383C79.0253 27.5684 75.9065 24.4348 72.0657 24.4348V24.425Z"
        fill="#EFB012"
        stroke="#FFFCFC"
        stroke-miterlimit="10"
      />
      <path
        d="M12.7648 67.5227C8.22654 67.5227 4.55273 63.8209 4.55273 59.259V12.7408C4.55273 8.17893 8.23145 4.47705 12.7648 4.47705H58.9919C63.5252 4.47705 67.2039 8.17893 67.2039 12.7408V23.2682C67.2039 25.5615 69.0507 27.4198 71.3296 27.4198C73.6085 27.4198 75.4553 29.2782 75.4553 31.5715V40.4234C75.4553 42.7167 73.6085 44.5751 71.3296 44.5751C69.0507 44.5751 67.2039 46.4333 67.2039 48.7266V59.254C67.2039 63.8159 63.5252 67.5178 58.9919 67.5178H12.7648V67.5227Z"
        fill="#F6CD4C"
      />
      <mask
        id="mask0_855_1278"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="20"
        y="9"
        width="32"
        height="55"
      >
        <path
          d="M51.7078 9.72949H20.4883V63.243H51.7078V9.72949Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_855_1278)">
        <path
          d="M47.3985 11.0831C45.6083 9.2783 42.7071 9.28546 40.9239 11.0903C40.1738 11.8494 39.721 12.8234 39.6148 13.8762C38.5463 13.3677 37.3575 13.0741 36.098 13.0741C34.8385 13.0741 33.6356 13.3749 32.56 13.8905C32.461 12.8664 32.0364 11.8709 31.2722 11.0974C30.4018 10.2165 29.2555 9.72949 28.0313 9.72949C26.8072 9.72949 25.6609 10.2094 24.7975 11.0831C22.0167 13.8905 20.4883 17.5646 20.4883 21.4463C20.4883 26.0013 22.5757 30.2124 26.2269 33.0843V57.5852C26.2269 60.7005 28.7319 63.243 31.8171 63.243C33.4303 63.243 34.888 62.5483 35.914 61.431C36.8127 62.534 38.1784 63.243 39.6997 63.243H41.0513C43.7614 63.243 45.962 61.0157 45.962 58.2727V33.0843C49.6204 30.2124 51.7007 26.0013 51.7007 21.4463C51.7007 17.5646 50.1723 13.8834 47.3985 11.0831Z"
          fill="#EFB012"
        />
        <path
          d="M36.0967 27.8559C39.5944 27.8559 42.4298 24.986 42.4298 21.446C42.4298 17.9059 39.5944 15.0361 36.0967 15.0361C32.5991 15.0361 29.7637 17.9059 29.7637 21.446C29.7637 24.986 32.5991 27.8559 36.0967 27.8559Z"
          fill="white"
        />
        <path
          d="M49.7613 21.4462C49.7613 18.0944 48.4381 14.9075 46.0252 12.4796C44.9992 11.441 43.3292 11.441 42.3032 12.4796C41.2771 13.5181 41.2771 15.2082 42.3032 16.2468C43.7114 17.672 44.4897 19.5125 44.4897 21.4462C44.4897 25.7076 40.7252 29.1739 36.0904 29.1739C31.4555 29.1739 27.6912 25.7076 27.6912 21.4462C27.6912 19.5411 28.4482 17.7149 29.8352 16.2969C30.8541 15.2512 30.9036 13.5252 29.8847 12.4867C28.8658 11.4482 27.1887 11.441 26.1627 12.4867C23.7568 14.9146 22.4336 18.1016 22.4336 21.4534C22.4336 25.6645 24.5353 29.4174 27.7832 31.8095C28.0308 31.9885 28.1793 32.2821 28.1793 32.5901V57.5922C28.1793 59.6262 29.8069 61.2806 31.8235 61.2806C33.8331 61.2806 35.4677 59.6334 35.4677 57.5922V47.2218C35.4677 46.8709 35.7366 46.5629 36.0833 46.5486C36.4301 46.5342 36.7414 46.8279 36.7414 47.1931V58.2798C36.7414 59.9341 38.0717 61.2806 39.7063 61.2806H41.0578C42.6923 61.2806 44.0227 59.9341 44.0227 58.2798V32.583C44.0227 32.275 44.1713 31.9814 44.4189 31.8023C47.6668 29.4102 49.7684 25.6574 49.7684 21.4462H49.7613Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_855_1278">
        <rect width="80" height="72" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
