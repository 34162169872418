<script setup>
import { onMounted, ref, computed, watch } from "vue";
import BetyDialog from "@/components/bety/BetyDialog";
// const props = defineProps({
//   pageno: {
//     type: Number,
//     required: true,
//     default: 0,
//   },
// });
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useStore();
let handStatus = computed(() => store.state.Camera.handStatus);
let handNumber = ref(0);
const spaceState = ref("none");

onMounted(() => {});

const pageNo = computed(() => store.state.Space.pageNumber);
let num = ref(pageNo.value); //Math.floor(Math.random() * 4) + 1;
watch(pageNo, (newValue) => {
  num.value = newValue;
  console.log("pageNo Changed: " + newValue);
});

watch(handStatus, (newValue) => {
  const { thumb, index, middle, ring, pinky } = newValue;
  let n = 0;
  if (!thumb) n++;
  if (!index) n++;
  if (!middle) n++;
  if (!ring) n++;
  if (!pinky) n++;
  handNumber.value = n;
});

const dialogClosed = (value) => {
  if (value === "next") {
    let routeMajor = router.currentRoute.value.params.major || "";
    let routeMinor = router.currentRoute.value.params.minor || "";
    let routePageno = parseInt(router.currentRoute.value.params.pageno || "");
    routePageno = isNaN(routePageno) ? 0 : routePageno;
    if (routePageno < 4) {
      router.replace(`/bety/${routeMajor}/${routeMinor}/${routePageno + 1}`);
      store.commit("Space/setPageNumber", routePageno + 1);
    }
    // location.replace(`/bety/${routeMajor}/${routeMinor}/${routePageno + 1}`);
  } else if (value === "reload") {
    console.log("reload");
  }
  spaceState.value = "none";
};

const clickValidate = () => {
  let flag = handClass.value.correct == true;
  if (flag) {
    console.log("success");
    spaceState.value = "success";
  } else {
    console.log("fail");
    spaceState.value = "fail";
  }
};

let handClass = computed(() => {
  let correct = false;
  let wrong = false;
  if (handNumber.value === num.value + 1) correct = true;
  else wrong = true;
  return { correct, wrong };
});
</script>

<template>
  <div class="wrap">
    <div class="title">
      <div class="num">{{ num }}</div>
      보다 1 큰 수를 나타내보아요
    </div>
    <div class="represent">
      <div class="handnumber" :class="handClass">{{ handNumber }}</div>
    </div>
    <div class="validateBtn" @click="clickValidate">확인</div>
    <div
      style="position: absolute; width: 100%; height: 100%"
      v-if="spaceState !== 'none'"
    >
      <bety-dialog :type="spaceState" @closed="dialogClosed" />
    </div>
  </div>
</template>

<style scoped>
.wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 50px;
  padding-top: 80px;
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: center;
  color: var(--color-bety-white);
}
.num {
  width: 40px;
  height: 40px;
  background-color: var(--color-bety-white);
  color: var(--color-bety-black);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid var(--color-bety-gray);
  border-radius: 10px;
  padding: 5px;
  margin-top: -5px;
}
.represent {
  padding-top: 80px;
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: center;
  color: var(--color-bety-white);
}
.handnumber {
  width: 150px;
  height: 150px;
  background-color: var(--color-bety-primary);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 70px;
  transition: all 0.5s ease-in-out;
}

.correct {
  background-color: var(--color-bety-primary);
}
.wrong {
  background-color: var(--color-bety-error);
}
.validateBtn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 50px;
  color: var(--color-bety-black);
  background-color: var(--color-bety-complementary);
  font-size: 30px;
  border: 0;
  border-radius: 15px;
}
.validateBtn:hover {
  cursor: pointer;
}
</style>
