<template>
<h1>Vuex Test</h1>
{{ $store.state.Counter.counter }}
{{ counter }}
{{  $store.getters["Counter/time2"] }}
<button @click="increase">increase</button>
</template>

<script setup>
import {computed} from "vue";
import {useStore} from "vuex";

const store = useStore();
const counter = computed(() => store.state.Counter.counter);
const increase = () => store.commit("Counter/setCounter", counter.value+1);
</script>