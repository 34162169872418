<script setup>
import BetyCharacter from "@/components/bety/sprite/BetyCharacter";
import BetyObstacle from "@/components/bety/sprite/BetyObstacle";

import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useStore();

const props = defineProps({
  pageno: {
    type: Number,
    required: true,
    default: 0,
  },
});

const mapData = [
  {
    id: 0,
    size: { w: 2, h: 1 },
    bs: { x: 0, y: 0 },
    be: { x: 1, y: 0 },
    platform: [
      [0, 0],
      [0, 1],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 1,
    size: { w: 4, h: 1 },
    bs: { x: 0, y: 0 },
    be: { x: 3, y: 0 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 2],
      [0, 3],
    ],

    obstacle: [],
    loop: false,
  },

  {
    id: 2,
    size: { w: 5, h: 1 },
    bs: { x: 0, y: 0 },
    be: { x: 4, y: 0 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 2],
      [0, 3],
      [0, 4],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 3,
    size: { w: 2, h: 2 },
    bs: { x: 0, y: 0 },
    be: { x: 1, y: 1 },
    platform: [
      [0, 0],
      [0, 1],
      [1, 1],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 4,
    size: { w: 3, h: 3 },
    bs: { x: 0, y: 0 },
    be: { x: 2, y: 2 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 2],
      [1, 2],
      [2, 2],
    ],
    obstacle: [],
    loop: false,
  },

  {
    id: 5,
    size: { w: 2, h: 2 },
    bs: { x: 0, y: 0 },
    be: { x: 1, y: 1 },
    platform: [
      [0, 0],
      [1, 0],
      [1, 1],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 6,
    size: { w: 4, h: 3 },
    bs: { x: 0, y: 0 },
    be: { x: 3, y: 2 },
    platform: [
      [0, 0],
      [1, 0],
      [2, 0],
      [2, 1],
      [2, 2],
      [2, 3],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 7,
    size: { w: 1, h: 4 },
    bs: { x: 0, y: 0 },
    be: { x: 0, y: 3 },
    platform: [
      [0, 0],
      [1, 0],
      [2, 0],
      [3, 0],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 8,
    size: { w: 1, h: 4 },
    bs: { x: 0, y: 3 },
    be: { x: 0, y: 0 },
    platform: [
      [0, 0],
      [1, 0],
      [2, 0],
      [3, 0],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 9,
    size: { w: 3, h: 3 },
    bs: { x: 2, y: 2 },
    be: { x: 0, y: 0 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 2],
      [1, 2],
      [2, 2],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 10,
    size: { w: 3, h: 3 },
    bs: { x: 0, y: 0 },
    be: { x: 0, y: 2 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 2],
      [1, 2],
      [2, 0],
      [2, 1],
      [2, 2],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 11,
    size: { w: 4, h: 3 },
    bs: { x: 0, y: 0 },
    be: { x: 3, y: 2 },
    platform: [
      [0, 0],
      [0, 1],
      [1, 1],
      [2, 1],
      [2, 2],
      [2, 3],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 12,
    size: { w: 2, h: 2 },
    bs: { x: 0, y: 0 },
    be: { x: 1, y: 1 },
    platform: [
      [0, 0],
      [0, 1],
      [1, 0],
      [1, 1],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 13,
    size: { w: 4, h: 4 },
    bs: { x: 0, y: 0 },
    be: { x: 3, y: 3 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 2],
      [0, 3],
      [1, 0],
      [1, 1],
      [1, 2],
      [1, 3],
      [2, 0],
      [2, 1],
      [2, 2],
      [2, 3],
      [3, 0],
      [3, 1],
      [3, 2],
      [3, 3],
    ],
    obstacle: [],
    loop: false,
  },

  {
    id: 14,
    size: { w: 4, h: 4 },
    bs: { x: 0, y: 0 },
    be: { x: 3, y: 3 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 2],
      [0, 3],
      [1, 0],
      [1, 2],
      [2, 0],
      [2, 1],
      [2, 2],
      [2, 3],
      [3, 1],
      [3, 3],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 15,
    size: { w: 3, h: 1 },
    bs: { x: 0, y: 0 },
    be: { x: 2, y: 0 },
    platform: [
      [0, 0],
      [0, 2],
    ],
    obstacle: [],
    loop: false,
  },

  {
    id: 16,
    size: { w: 5, h: 1 },
    bs: { x: 0, y: 0 },
    be: { x: 4, y: 0 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 3],
      [0, 4],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 17,
    size: { w: 3, h: 4 },
    bs: { x: 0, y: 0 },
    be: { x: 2, y: 3 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 2],
      [2, 2],
      [3, 2],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 18,
    size: { w: 6, h: 1 },
    bs: { x: 0, y: 0 },
    be: { x: 5, y: 0 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 3],
      [0, 5],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 19,
    size: { w: 3, h: 4 },
    bs: { x: 0, y: 0 },
    be: { x: 2, y: 3 },
    platform: [
      [0, 0],
      [0, 1],
      [2, 1],
      [2, 2],
      [3, 2],
    ],
    obstacle: [],
    loop: false,
  },

  {
    id: 20,
    size: { w: 3, h: 3 },
    bs: { x: 1, y: 0 },
    be: { x: 0, y: 2 },
    platform: [
      [0, 1],
      [0, 2],
      [2, 0],
      [2, 2],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 21,
    size: { w: 4, h: 3 },
    bs: { x: 0, y: 0 },
    be: { x: 3, y: 2 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 3],
      [2, 1],
      [2, 3],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 22,
    size: { w: 3, h: 5 },
    bs: { x: 0, y: 0 },
    be: { x: 0, y: 4 },
    platform: [
      [0, 0],
      [0, 2],
      [2, 2],
      [4, 0],
      [4, 2],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 23,
    size: { w: 4, h: 4 },
    bs: { x: 0, y: 0 },
    be: { x: 3, y: 3 },
    platform: [
      [0, 0],
      [0, 2],
      [1, 1],
      [1, 2],
      [2, 0],
      [2, 2],
      [3, 1],
      [3, 3],
    ],
    obstacle: [],
    loop: false,
  },
  {
    id: 24,
    size: { w: 4, h: 1 },
    bs: { x: 0, y: 0 },
    be: { x: 3, y: 0 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 2],
      [0, 3],
    ],
    obstacle: [[0, 1]],
    loop: false,
  },
  {
    id: 25,
    size: { w: 6, h: 1 },
    bs: { x: 0, y: 0 },
    be: { x: 5, y: 0 },
    platform: [
      [0, 0],
      [0, 2],
      [0, 3],
      [0, 5],
    ],
    obstacle: [[0, 3]],
    loop: false,
  },
  {
    id: 26,
    size: { w: 3, h: 3 },
    bs: { x: 0, y: 0 },
    be: { x: 2, y: 2 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 2],
      [2, 2],
    ],
    obstacle: [[0, 2]],
    loop: false,
  },
  {
    id: 27,
    size: { w: 3, h: 4 },
    bs: { x: 0, y: 0 },
    be: { x: 0, y: 3 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 2],
      [1, 2],
      [3, 0],
      [3, 1],
      [3, 2],
    ],
    obstacle: [[1, 2]],
    loop: false,
  },
  {
    id: 28,
    size: { w: 5, h: 2 },
    bs: { x: 0, y: 1 },
    be: { x: 4, y: 1 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 3],
      [0, 4],
      [1, 0],
      [1, 1],
      [1, 3],
      [1, 4],
    ],
    obstacle: [
      [0, 4],
      [1, 3],
    ],
    loop: false,
  },
  {
    id: 29,
    size: { w: 6, h: 4 },
    bs: { x: 0, y: 0 },
    be: { x: 5, y: 3 },
    platform: [
      [0, 0],
      [0, 2],
      [0, 3],
      [0, 5],
      [1, 0],
      [1, 2],
      [1, 4],
      [1, 5],
      [2, 0],
      [2, 3],
      [2, 4],
      [2, 5],
      [3, 0],
      [3, 1],
      [3, 2],
      [3, 3],
      [3, 4],
      [3, 5],
    ],
    obstacle: [
      [0, 3],
      [1, 2],
      [3, 0],
    ],
    loop: false,
  },
  {
    id: 30,
    size: { w: 6, h: 1 },
    bs: { x: 0, y: 0 },
    be: { x: 5, y: 0 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 2],
      [0, 3],
      [0, 4],
      [0, 5],
    ],
    obstacle: [],
    loop: true,
  },
  {
    id: 31,
    size: { w: 4, h: 4 },
    bs: { x: 0, y: 0 },
    be: { x: 0, y: 3 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 2],
      [0, 3],
      [1, 3],
      [2, 3],
      [3, 0],
      [3, 1],
      [3, 2],
      [3, 3],
    ],
    obstacle: [],
    loop: true,
  },

  {
    id: 32,
    size: { w: 6, h: 1 },
    bs: { x: 0, y: 0 },
    be: { x: 5, y: 0 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 2],
      [0, 3],
      [0, 4],
      [0, 5],
    ],
    obstacle: [
      [0, 1],
      [0, 2],
      [0, 3],
      [0, 4],
    ],
    loop: true,
  },
  {
    id: 33,
    size: { w: 3, h: 3 },
    bs: { x: 0, y: 0 },
    be: { x: 0, y: 2 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 2],
      [1, 2],
      [2, 0],
      [2, 1],
      [2, 2],
    ],
    obstacle: [
      [0, 2],
      [2, 0],
      [2, 2],
    ],
    loop: true,
  },
  {
    id: 34,
    size: { w: 4, h: 4 },
    bs: { x: 0, y: 0 },
    be: { x: 0, y: 3 },
    platform: [
      [0, 0],
      [0, 1],
      [0, 3],
      [1, 3],
      [3, 0],
      [3, 2],
      [3, 3],
    ],
    obstacle: [],
    loop: true,
  },
  {
    id: 35,
    size: { w: 5, h: 5 },
    bs: { x: 3, y: 0 },
    be: { x: 0, y: 1 },
    platform: [
      [0, 3],
      [0, 4],
      [1, 0],
      [1, 4],
      [3, 0],
      [3, 4],
      [4, 0],
      [4, 1],
      [4, 3],
      [4, 4],
    ],
    obstacle: [
      [1, 4],
      [3, 0],
      [4, 3],
    ],
    loop: true,
  },
];

const gridCellSize = 80; // 80px
const gridCellGap = 20; // 20px
const gridRow = ref(mapData[props.pageno].size.h);
const gridCol = ref(mapData[props.pageno].size.w);
const betyX = ref(mapData[props.pageno].bs.x);
const betyY = ref(mapData[props.pageno].bs.y);
const destX = ref(mapData[props.pageno].be.x);
const destY = ref(mapData[props.pageno].be.y);
const isLoop = ref(mapData[props.pageno].loop);
const betyDirection = ref("right");
const betyExpression = ref("normal");
const stageSuccess = ref(false);
const stageFailed = ref(false);
const gridData = ref(mapData[props.pageno].platform);
const obstacleData = ref(mapData[props.pageno].obstacle);

const pageNo = computed(() => store.state.Space.pageNumber - 1);
watch(pageNo, (newValue) => {
  resetSpace(newValue);
});

const resetSpace = (pageNo) => {
  store.commit("Block/clearBlock");
  gridRow.value = mapData[pageNo].size.h;
  gridCol.value = mapData[pageNo].size.w;
  betyX.value = mapData[pageNo].bs.x;
  betyY.value = mapData[pageNo].bs.y;
  destX.value = mapData[pageNo].be.x;
  destY.value = mapData[pageNo].be.y;
  isLoop.value = mapData[pageNo].loop;
  betyDirection.value = "right";
  betyExpression.value = "normal";
  stageSuccess.value = false;
  stageFailed.value = false;
  gridData.value = mapData[pageNo].platform;
  obstacleData.value = mapData[pageNo].obstacle;
  store.commit("Camera/setShutterEnable", true);
};

const validateDelta = (dx, dy, command) => {
  const x = betyX.value;
  const y = betyY.value;
  const ndx = command == "jump" ? dx * 2 : dx;
  const ndy = command == "jump" ? dy * 2 : dy;

  if (command == "jump") {
    const collision = obstacleData.value.some(
      (o) => o[0] === y + dy && o[1] === x + dx
    );
    if (collision) return "hurt";
  }
  if (
    x + ndx < 0 ||
    x + ndx > gridCol.value - 1 ||
    y + ndy < 0 ||
    y + ndy > gridRow.value - 1
  )
    return "fall";

  const notFall = gridData.value.some(
    (g) => g[0] === y + ndy && g[1] === x + ndx
  );
  if (!notFall) return "fall";

  const collision = obstacleData.value.some(
    (o) => o[0] === y + ndy && o[1] === x + ndx
  );
  if (collision) return "hurt";

  return true;
};

const getDeltaXY = (direction) => {
  switch (direction) {
    case "right":
      return { dx: 1, dy: 0 };
    case "down":
      return { dx: 0, dy: 1 };
    case "left":
      return { dx: -1, dy: 0 };
    case "up":
      return { dx: 0, dy: -1 };
    default:
      console.log("유효하지 않은 방향입니다.");
      return null;
  }
};

const rotateDirection = (currentDirection, rotation) => {
  const directions = ["right", "down", "left", "up"];
  const currentIndex = directions.indexOf(currentDirection);
  if (currentIndex === -1) return "right";
  let newIndex = 0;
  if (rotation === "clock") {
    newIndex = (currentIndex + 1) % directions.length;
  } else {
    newIndex = (currentIndex - 1 + directions.length) % directions.length;
  }
  return directions[newIndex];
};

const isPlaying = computed(() => store.state.Space.isPlaying);
watch(isPlaying, async (newValue) => {
  if (!newValue) return;
  let v = true;
  if (store.state.Block.block.length <= 0) {
    store.commit("Space/stop");
    return;
  }
  store.commit("Camera/setShutterEnable", false);

  let loopCnt = 1;
  if (isLoop.value) loopCnt = 10;
  while (loopCnt--) {
    for (let block of store.state.Block.block) {
      const dir = betyDirection.value;
      const { dx, dy } = getDeltaXY(betyDirection.value);
      if (block == "left turn") {
        betyDirection.value = rotateDirection(dir, "counterclock");
      }
      if (block == "right turn") {
        betyDirection.value = rotateDirection(dir, "clock");
      }
      if (block == "go") {
        v = validateDelta(dx, dy, "go");
        if (v == "hurt") {
          betyExpression.value = "hurt";
          break;
        }
        betyX.value += dx;
        betyY.value += dy;
        if (v == "fall") {
          await new Promise((resolve) => setTimeout(resolve, 500));
          betyExpression.value = "fall";
          break;
        }
      }
      if (block == "jump") {
        v = validateDelta(dx, dy, "jump");
        if (v == "hurt") {
          betyExpression.value = "hurt";
          break;
        }
        betyX.value += dx * 2;
        betyY.value += dy * 2;
        if (v == "fall") {
          await new Promise((resolve) => setTimeout(resolve, 500));
          betyExpression.value = "fall";
          break;
        }
      }
      if (block == "shoot") {
        obstacleData.value = obstacleData.value.filter(
          (o) => !(o[0] === betyY.value + dy && o[1] === betyX.value + dx)
        );
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
    if (
      betyX.value == destX.value &&
      betyY.value == destY.value &&
      betyExpression.value == "normal"
    ) {
      stageSuccess.value = true;
      betyExpression.value = "success";
      const { minor, page } = getRouteInfo();
      store.commit("User/setCompleteStage", { space: minor, page: page });
      break;
    } else if (betyExpression.value !== "normal") {
      stageFailed.value = true;
      break;
    }
  }

  if (betyExpression.value == "normal") {
    betyExpression.value = "fail";
    stageFailed.value = true;
  }
  store.commit("Space/stop");
});

const gridWrapStyle = computed(() => {
  const c = gridCol.value;
  const r = gridRow.value;
  const w = c * gridCellSize + (c - 1) * gridCellGap;
  const h = r * gridCellSize + (r - 1) * gridCellGap;
  return `width:${w}px;height:${h}px`;
});

const betyStyle = computed(() => {
  return `position:absolute; left: ${
    betyX.value * (gridCellSize + gridCellGap) + gridCellSize / 2
  }px; top: ${
    betyY.value * (gridCellSize + gridCellGap) + gridCellSize / 2 - 12
  }px; transform:translate(-50%,-50%);
        transition: all 0.3s ease;`;
});

const gridStyle = computed(() => {
  const be = mapData[pageNo.value].be;
  return gridData.value.map((p) => {
    let st = `top:${p[0] * (gridCellSize + gridCellGap)}px; left:${
      p[1] * (gridCellSize + gridCellGap)
    }px;`;
    if (p[1] == be.x && p[0] == be.y) {
      st += `;background-color:#FA9C85`;
    }
    return st;
  });
});
const obstacleStyle = computed(() => {
  return obstacleData.value.map((o) => {
    let s =
      `position: absolute;width: 70px;height: 70px;transform: translate(-50%, -50%);` +
      `top:${o[0] * (gridCellSize + gridCellGap) + gridCellSize / 2}px; left:${
        o[1] * (gridCellSize + gridCellGap) + gridCellSize / 2
      }px;`;
    return s;
  });
});

// const clickMsg = (value) => {
//   console.log(value);
//   resetSpace(pageNo.value);
// };

const getRouteInfo = () => {
  let routeMinor = router.currentRoute.value.params.minor || "";
  let routePageno = parseInt(router.currentRoute.value.params.pageno || "");
  routePageno = isNaN(routePageno) ? 0 : routePageno;
  return { minor: routeMinor, page: routePageno };
};

const clickMsg = (value) => {
  if (value === "next") {
    let routeMajor = router.currentRoute.value.params.major || "";
    let routeMinor = router.currentRoute.value.params.minor || "";
    let routePageno = parseInt(router.currentRoute.value.params.pageno || "");
    routePageno = isNaN(routePageno) ? 0 : routePageno;
    router.replace(`/bety/${routeMajor}/${routeMinor}/${routePageno + 1}`);
    // location.replace(`/bety/${routeMajor}/${routeMinor}/${routePageno + 1}`);
    store.commit("Space/setPageNumber", routePageno + 1);
  } else if (value === "reload") {
    resetSpace(pageNo.value);
  }
};
</script>

<template>
  <div class="wrap">
    <div class="content">
      <div class="grid" :style="gridWrapStyle">
        <div
          class="cell"
          v-for="cell in gridStyle"
          :key="cell.id"
          :style="cell"
        ></div>
        <bety-obstacle v-for="o in obstacleStyle" :key="o.key" :style="o" />
        <bety-character
          :style="betyStyle"
          :direction="betyDirection"
          :expression="betyExpression"
        />
      </div>
    </div>

    <div
      v-show="stageSuccess"
      class="message-box"
      @click.stop="clickMsg('next')"
    >
      성공!!
    </div>
    <div
      v-show="stageFailed"
      class="message-box"
      @click.stop="clickMsg('reload')"
    >
      다시 해볼까요?
    </div>
  </div>
</template>

<style scoped>
.wrap {
  position: absolute;
  width: 100%;
  height: 100%;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}
.title {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--color-bety-white);
  font-size: 25px;
  text-align: center;
}

.grid {
  position: relative;
}

.cell {
  position: absolute;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--color-bety-white);
  mix-blend-mode: overlay;
}
.cell_bg {
  width: 100%;
  height: 100%;
}
.cell_text {
  position: absolute;
  color: var(--color-bety-white);
  font-size: 23px;
}

.message-box {
  display: flex;
  position: absolute;
  width: 300px;
  height: 50px;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0);
  background-color: var(--color-bety-gray);
  border: 0;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}
.message-box:hover {
  background-color: var(--color-bety-white);
  cursor: pointer;
}
</style>
