<script setup>
import { onMounted, onUpdated, ref } from "vue";
const props = defineProps({
  major: {
    type: String,
    required: true,
    default: "",
  },
  minor: {
    type: String,
    required: true,
    default: "",
  },
});

onMounted(() => {
  console.log("#########");
  console.log(props.major, props.minor);
  console.log("#########");
});
onUpdated(() => {
  console.log("#########");
  console.log(props.major, props.minor);
  console.log("#########");
});
const onClick = () => {
  console.log("#########");
  console.log(props.major, props.minor);
  console.log("#########");
};

const paths = ref({
  hangeul: [
    { type: "represent", text: "몸 표현" },
    { type: "represent_hand", text: "손 표현" },
    { type: "pixel", text: "픽셀 아트" },
    { type: "wordselect", text: "낱말 퀴즈" },
  ],
  math: [
    { type: "order", text: "숫자 세기" },
    { type: "math_represent", text: "숫자 표현" },
    { type: "maze_inc", text: "숫자 미로\n(큰 수)" },
    { type: "maze_dec", text: "숫자 미로\n(작은 수)" },
  ],
  coding: [
    { type: "maze_compute_pose", text: "몸 미로" },
    { type: "maze_compute_face", text: "얼굴 미로" },
    { type: "maze_compute_hand", text: "손 미로" },
  ],
  aion: [
    { type: "control_hand", text: "손 제어" },
    { type: "control_face", text: "얼굴 제어" },
    { type: "chain1", text: "체인\n리액션1" },
    { type: "chain2", text: "체인\n리액션2" },
    { type: "chain3", text: "체인\n리액션3" },
    { type: "chain4", text: "체인\n리액션4" },
    { type: "chain5", text: "체인\n리액션5" },
  ],
});
</script>

<template>
  <div class="wrap" @click="onClick">
    <div class="mission" v-for="path in paths[props.major]" :key="path.id">
      <div
        class="mission-text"
        :class="{ mission_selected: props.minor === path.type }"
      >
        <a
          :href="`/bety/${props.major}/${path.type}/1`"
          style="white-space: pre-line"
        >
          {{ path.text }}
        </a>
      </div>
    </div>
  </div>
</template>
<style scoped>
.wrap {
  width: 100%;
  height: 100%;
  background-color: var(--color-bety-primary);
  color: var(--color-bety-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.2);
  padding: 25px 10px;
  box-sizing: border-box;
}

.mission {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}
.mission-text {
  font-size: 18px;
  margin-top: 5px;
  color: var(--color-bety-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.mission_selected {
  color: var(--color-bety-complementary);
}
</style>
