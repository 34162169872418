<script setup>
const props = defineProps({
  type: String, //next, previous
  hover: Boolean,
});
</script>

<template>
  <svg
    v-if="props.type === 'next'"
    width="20"
    height="27"
    viewBox="0 0 20 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9499 15.5132L17.9498 15.5132L17.9412 15.5203L6.61381 24.8709C6.07878 25.298 5.43484 25.5668 4.75487 25.6468C4.07263 25.727 3.38157 25.614 2.76043 25.3206L2.74862 25.3151L2.73668 25.3098C2.22529 25.0844 1.78959 24.7165 1.48163 24.2502C1.17465 23.7853 1.00751 23.2422 1 22.6853L0.999999 3.98664C1.00751 3.4297 1.17465 2.88656 1.48162 2.42171C1.78959 1.95537 2.22528 1.58752 2.73667 1.36211L2.74862 1.35684L2.76043 1.35126C3.38157 1.05787 4.07263 0.944868 4.75487 1.02513C5.43486 1.10513 6.07882 1.37392 6.61386 1.80099L17.9412 11.1516L17.9411 11.1516L17.9499 11.1587C18.2759 11.4217 18.5393 11.754 18.7209 12.1315C18.9018 12.5074 18.9971 12.9188 19 13.3359C18.9971 13.7531 18.9018 14.1645 18.7209 14.5404C18.5393 14.9179 18.2759 15.2502 17.9499 15.5132Z"
      stroke-width="2"
      :class="{ stay: !props.hover, hover: props.hover }"
    />
  </svg>

  <svg
    v-if="props.type === 'previous'"
    width="20"
    height="27"
    viewBox="0 0 20 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.05011 15.5132L2.05015 15.5132L2.05883 15.5203L13.3862 24.8709C13.9212 25.298 14.5652 25.5668 15.2451 25.6468C15.9274 25.727 16.6184 25.614 17.2396 25.3206L17.2514 25.3151L17.2633 25.3098C17.7747 25.0844 18.2104 24.7165 18.5184 24.2502C18.8254 23.7853 18.9925 23.2422 19 22.6853L19 3.98664C18.9925 3.4297 18.8254 2.88656 18.5184 2.42171C18.2104 1.95537 17.7747 1.58752 17.2633 1.36211L17.2514 1.35684L17.2396 1.35126C16.6184 1.05787 15.9274 0.944868 15.2451 1.02513C14.5651 1.10513 13.9212 1.37392 13.3861 1.80099L2.05883 11.1516L2.05887 11.1516L2.05011 11.1587C1.72409 11.4217 1.46071 11.754 1.27907 12.1315C1.09816 12.5074 1.00287 12.9188 1.00002 13.3359C1.00287 13.7531 1.09816 14.1645 1.27907 14.5404C1.46071 14.9179 1.72409 15.2502 2.05011 15.5132Z"
      stroke-width="2"
      :class="{ stay: !props.hover, hover: props.hover }"
    />
  </svg>
</template>

<style scoped>
.stay {
  fill: var(--color-bety-primary);
  stroke: var(--color-bety-secondary);
  /* stroke: var(--color-bety-white); */
}
.hover {
  fill: var(--color-bety-complementary);
  stroke: var(--color-bety-white);
}
</style>
