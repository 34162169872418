<script setup>
import BetySprite from "@/components/bety/sprite/BetySprite";
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
const store = useStore();

const gridRow = ref(4);
const gridCol = ref(4);
const gridCellSize = 90; //90px
const betyElement = ref(null);
const betyX = ref(0);
const betyY = ref(3);
const stageSuccess = ref(false);
const stageFailed = ref(false);

const props = defineProps({
  type: {
    type: String,
    required: true,
    default: "dec", //inc, dec
  },
});

const betyStyle = computed(() => {
  console.log("x: " + betyX.value);
  return `position:absolute; height:${gridCellSize - 20}px; left: ${
    betyX.value * gridCellSize + (gridCellSize - 10) / 2
  }px; top: ${
    betyY.value * gridCellSize + (gridCellSize - 10) / 2
  }px; transform:translate(-50%,-50%);
        transition: all 0.3s ease;`;
});

const titleText = computed(() => {
  let t = undefined;
  if (props.type === "inc") t = "큰 수";
  else if (props.type === "dec") t = "작은 수";
  return `1 ${t}를 따라가보아요`;
});

let mapData = ref({
  inc: [
    [3, 6, 7, "도착"],
    [2, 5, 4, 6],
    [1, 2, 3, 4],
    ["시작", 1, 2, 3],
  ],
  dec: [
    [7, 6, 3, "도착"],
    [8, 7, 4, 2],
    [9, 6, 5, 6],
    ["시작", 9, 8, 7],
  ],
});

const gridData = ref([]);

onMounted(() => {
  for (let i = 0; i < mapData.value[props.type].length; i++) {
    for (let j = 0; j < mapData.value[props.type][i].length; j++) {
      gridData.value.push({
        style: `position:absolute; top:${i * gridCellSize}px; left:${
          j * gridCellSize
        }px`,
        text: mapData.value[props.type][i][j],
      });
    }
  }
});

const validateDelta = (dx, dy) => {
  const x = betyX.value;
  const y = betyY.value;
  if (x + dx < 0) return "cannot";
  if (x + dx > gridCol.value - 1) return "cannot";
  if (y + dy < 0) return "cannot";
  if (y + dy > gridRow.value - 1) return "cannot";
  if (mapData.value[props.type][y][x] == "시작") return true;
  if (mapData.value[props.type][y + dy][x + dx] == "시작") return true;
  if (mapData.value[props.type][y + dy][x + dx] == "도착") return "end";
  const oldNumber = mapData.value[props.type][y][x];
  const newNumber = mapData.value[props.type][y + dy][x + dx];
  let dNumber = 0;
  if (props.type === "inc") dNumber = newNumber - oldNumber;
  if (props.type === "dec") dNumber = oldNumber - newNumber;
  if (dNumber !== 1) return false;
  return true;
};

const isPlaying = computed(() => store.state.Space.isPlaying);

watch(isPlaying, async (newValue) => {
  if (!newValue) return;
  for (let block of store.state.Block.block) {
    let v = undefined;
    if (block == "left go") {
      v = validateDelta(-1, 0);
      if (v === "cannot") break;
      betyX.value -= 1;
    }
    if (block == "right go") {
      v = validateDelta(1, 0);
      if (v === "cannot") break;
      betyX.value += 1;
    }
    if (block == "up go") {
      v = validateDelta(0, -1);
      if (v === "cannot") break;
      betyY.value -= 1;
    }
    if (block == "down go") {
      v = validateDelta(0, 1);
      if (v === "cannot") break;
      betyY.value += 1;
    }
    console.log(v);
    if (v === "end") {
      stageSuccess.value = true;
      break;
    }
    if (v === false) {
      stageFailed.value = true;
      break;
    }
    //     if (block == "shoot") {
    //       let pos = betyY.value * gridCol.value + betyX.value;
    //       if (gridData.value[pos].type === "none") {
    //         gridData.value[pos].type = "filled";
    //         fillRest.value = -1;
    //         break;
    //       } else if (gridData.value[pos].type === "placehold") {
    //         gridData.value[pos].type = "filled";
    //         fillRest.value--;
    //       }
    //     }
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
  //   if (fillRest.value === 0) {
  //     stageSuccess.value = true;
  //   } else if (fillRest.value === -1) {
  //     stageFailed.value = true;
  //   }
  store.commit("Block/clearBlock");
  store.commit("Space/stop");
});

const clickMsg = () => {
  location.reload();
};
console.log(gridData.value);
</script>

<template>
  <div class="wrap">
    <div class="content">
      <div class="title">{{ titleText }}</div>
      <div class="grid">
        <div
          class="cell"
          v-for="cell in gridData"
          :key="cell.id"
          :style="cell.style"
        >
          <div class="cell_bg"></div>
          <div class="cell_text">{{ cell.text }}</div>
        </div>
        <bety-sprite :style="betyStyle" ref="betyElement" />
      </div>
    </div>

    <div v-show="stageSuccess" class="message-box" @click="clickMsg">
      성공!!
    </div>
    <div v-show="stageFailed" class="message-box" @click="clickMsg">
      다시 해볼까요?
    </div>
  </div>
</template>

<style scoped>
.wrap {
  position: absolute;
  width: 100%;
  height: 100%;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  gap: 30px;
}
.title {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--color-bety-white);
  font-size: 25px;
  text-align: center;
}

.grid {
  position: relative;
  margin-left: -360px;
}

.cell {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cell_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: var(--color-bety-white);
  mix-blend-mode: overlay;
}
.cell_text {
  position: absolute;
  color: var(--color-bety-white);
  font-size: 23px;
}

.message-box {
  display: flex;
  position: absolute;
  width: 300px;
  height: 50px;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0);
  background-color: var(--color-bety-gray);
  border: 0;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}
.message-box:hover {
  background-color: var(--color-bety-white);
  cursor: pointer;
}
</style>
