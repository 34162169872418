<script setup>
import { ref, watch } from "vue";
import MotionMissionIcon from "@/components/icon/MotionMissionIcon";
import MotionMissionCompleteIcon from "@/components/icon/MotionMissionCompleteIcon";

let missionComplete1Elem = ref(null);
let missionComplete2Elem = ref(null);
let missionComplete3Elem = ref(null);

import { useStore } from "vuex";
const store = useStore();

watch(store.state.Mission.mission, (newValue) => {
  if (newValue[0]) {
    console.log(0);
    missionComplete1Elem.value.$el.style.display = "block";
  }
  if (newValue[1]) {
    console.log(0);
    missionComplete2Elem.value.$el.style.display = "block";
  }
  if (newValue[2]) {
    console.log(0);
    missionComplete3Elem.value.$el.style.display = "block";
  }
});
</script>

<template>
  <div class="wrap">
    <div class="title">
      <div class="title_icon"><motion-mission-icon /></div>
      <div class="title_text">임무</div>
    </div>
    <div class="content">
      <div class="start">
        <div class="top">
          <div class="circle"></div>
          <div class="subject">시작</div>
        </div>
        <div class="bottom">
          <div class="line_out"><div class="line_in"></div></div>
          <div class="text_out"><div class="text_in"></div></div>
        </div>
      </div>
      <div class="m1">
        <div class="top">
          <div class="circle"></div>
          <div class="subject">임무 01</div>
        </div>
        <div class="bottom">
          <div class="line_out"><div class="line_in"></div></div>
          <div class="text_out">
            <div class="text_in">소행성 조각을 부숴보아요</div>
          </div>
        </div>
        <motion-mission-complete-icon
          ref="missionComplete1Elem"
          style="position: absolute; bottom: 20px; right: 20px; display: none"
        />
      </div>
      <div class="m2">
        <div class="top">
          <div class="circle"></div>
          <div class="subject">임무 02</div>
        </div>
        <div class="bottom">
          <div class="line_out"><div class="line_in"></div></div>
          <div class="text_out">
            <div class="text_in">동전을 얻어보아요</div>
          </div>
        </div>
        <motion-mission-complete-icon
          ref="missionComplete2Elem"
          style="position: absolute; bottom: 20px; right: 20px; display: none"
        />
      </div>

      <div class="m3">
        <div class="top">
          <div class="circle"></div>
          <div class="subject">임무 03</div>
        </div>
        <div class="bottom">
          <div class="line_out"><div class="line_in"></div></div>
          <div class="text_out">
            <div class="text_in">미로를 빠져나와보아요</div>
          </div>
        </div>
        <motion-mission-complete-icon
          ref="missionComplete3Elem"
          style="position: absolute; bottom: 20px; right: 20px; display: none"
        />
      </div>

      <div class="end">
        <div class="top">
          <div class="circle"></div>
          <div class="subject">임무 성공</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.wrap {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 50px 20px 35px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2),
    /* 위 (상단) */ 0 -4px 8px rgba(0, 0, 0, 0.05),
    /* 아래 (하단) */ -8px 0 12px rgba(0, 0, 0, 0.05),
    /* 왼쪽 */ 8px 0 12px rgba(0, 0, 0, 0.05); /* 오른쪽 */
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.start {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60px;
  padding-bottom: 5px;
}

.m1,
.m2,
.m3 {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
}

.top {
  width: 100%;
  height: 14px;
  display: flex;
  align-items: center;
}

.circle {
  width: 14px;
  height: 100%;
  background-color: white;
  border: 1px solid #b0b0b0;
  border-radius: 7px;
  box-sizing: border-box;
}

.subject {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: white;
  border: 1px solid #b0b0b0;
  border-radius: 12px;
  padding: 10px 10px;
  color: gray;
  margin-left: 5px;
  font-size: 12px;
  box-sizing: border-box;
}

.bottom {
  flex: 1;
  display: flex;
  padding-top: 5px;
}

.line_out {
  width: 14px;
  height: 100%;
  padding: 3px 5px 3px;
  box-sizing: border-box;
}
.line_in {
  width: 100%;
  height: 100%;
  background-color: #b0b0b0;
  border-radius: 2px;
}

.text_out {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 5px 10px;
}

.text_in {
  width: 100%;
  height: 100%;
  background-color: #e9e9e9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.start .text_out {
  display: none;
}

.m1 .subject {
  background-color: #fbbf17;
  color: white;
  border: 1px solid #fbbf17;
}
.m1 .line_in {
  background-color: #fbbf17;
}
.m2 .subject {
  background-color: #1cbe52;
  color: white;
  border: 1px solid #1cbe52;
}
.m2 .line_in {
  background-color: #1cbe52;
}
.m3 .subject {
  background-color: #fc75cc;
  color: white;
  border: 1px solid #fc75cc;
}
.m3 .line_in {
  background-color: #fc75cc;
}

.title {
  position: absolute;
  top: 10px;
  left: 15px;
  display: flex;
  align-items: center;
}

.title_icon {
  width: 14px;
  height: 14px;
  margin-right: 7px;
}
</style>
