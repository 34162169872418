<script setup>
import BetyHangeulLetter from "@/components/bety/sprite/BetyHangeulLetter";
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();
// const props = defineProps({
//   pageno: {
//     type: Number,
//     required: true,
//     default: 0,
//   },
// });

// console.log(props);

const pageNo = computed(() => store.state.Space.pageNumber - 1);
// watch(pageNo, (newValue) => {
//   props.pageno = newValue;
// });

const letters = [
  "G",
  "N",
  "D",
  "R",
  "M",
  "B",
  "S",
  "O",
  "J",
  "CH",
  "K",
  "T",
  "P",
  "H",
  "GG",
  "DD",
  "BB",
  "SS",
  "JJ",
  "a",
  "ae",
  "eo",
  "e",
  "o",
  "oe",
  "u",
  "wi",
  "eu",
  "i",
  "ya",
  "yae",
  "yeo",
  "ye",
  "wa",
  "wae",
  "yo",
  "wo",
  "we",
  "yu",
  "ui",
];
</script>

<template>
  <div class="wrap">
    <div class="title">
      <div class="hangul_icon">
        <bety-hangeul-letter :type="letters[pageNo]" />
      </div>
      <div class="hangul_express">몸으로 만들어보아요</div>
    </div>
  </div>
</template>

<style scoped>
.wrap {
  position: absolute;
  width: 100%;
  height: 100%;
}
.title {
  padding-top: 30px;
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-bety-white);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hangul_icon {
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-bety-white);
  border-radius: 10px;
  color: var(--color-bety-primary);
  font-size: 130px;
  -webkit-text-stroke: 2px var(--color-bety-white);
}
.hangul_express {
  font-size: 30px;
}
</style>
