<template>
  <svg
    width="81"
    height="72"
    viewBox="0 0 81 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="margin-right: -10px"
  >
    <path
      opacity="0.99"
      d="M79.9405 31.4339V40.5661C79.9405 44.4284 76.8092 47.5646 72.942 47.5646H72.858C72.0826 47.5646 71.4603 48.1918 71.4603 48.9623V59.7984C71.4603 61.9222 70.8676 63.9126 69.8353 65.6067C69.6477 65.9178 69.4501 66.2141 69.2328 66.5006C69.0155 66.792 68.7883 67.0686 68.5463 67.3304C66.5015 69.5875 63.5431 71 60.2587 71H12.6569C9.56511 71 6.76471 69.7455 4.73973 67.7205C4.48785 67.4686 4.24584 67.2019 4.0137 66.9254C3.55932 66.3722 3.15432 65.7746 2.80859 65.1375C1.94428 63.552 1.45532 61.7296 1.45532 59.7984V48.9673C1.45532 48.1918 2.08257 47.5646 2.85799 47.5646H2.93701C6.80423 47.5646 9.94047 44.4284 9.94047 40.5611V31.4339C9.94047 27.5667 6.80916 24.4354 2.94195 24.4354H2.85799C2.08257 24.4354 1.45532 23.8082 1.45532 23.0377V12.2016C1.45532 10.2655 1.94428 8.44303 2.80859 6.85762C3.15432 6.22543 3.55932 5.62781 4.0137 5.07465C4.24584 4.79807 4.48785 4.53136 4.73973 4.27948C6.76471 2.2545 9.56511 1 12.6569 1H60.2587C63.5431 1 66.5015 2.41254 68.5463 4.66965C68.7883 4.93636 69.0155 5.21294 69.2328 5.4994C69.6625 6.07232 70.0428 6.68969 70.3539 7.34657C70.512 7.66761 70.6552 8.00346 70.7737 8.34425H70.7787C71.2183 9.54935 71.4603 10.8483 71.4603 12.2016V23.0377C71.4603 23.8082 72.0826 24.4354 72.858 24.4354H72.942C76.8092 24.4354 79.9405 27.5717 79.9405 31.4339Z"
      fill="#006BE0"
      stroke="#FFFCFC"
      stroke-miterlimit="10"
    />
    <g opacity="0.99">
      <path
        d="M13.2645 67.5033C11.0716 67.5033 9.00708 66.6488 7.45624 65.098C7.2735 64.9202 7.09076 64.7177 6.90801 64.4954C6.58698 64.1052 6.29065 63.6607 6.03876 63.2014C5.38681 62.0111 5.04602 60.6579 5.04602 59.2848V49.9699C9.72816 49.3377 13.3484 45.3174 13.3484 40.4673V31.5377C13.3484 26.6876 9.72816 22.6673 5.04602 22.0401V12.7252C5.04602 11.3521 5.38681 9.99391 6.03381 8.80856C6.2857 8.34923 6.58698 7.90472 6.92283 7.48985C7.09075 7.29229 7.2735 7.08979 7.4513 6.91199C9.00214 5.36116 11.0666 4.50671 13.2595 4.50671H59.8291C62.1405 4.50671 64.3581 5.48957 65.9089 7.19845C66.0818 7.39107 66.2547 7.59851 66.4275 7.8257C66.7436 8.24551 67.0153 8.69496 67.2326 9.14934C67.361 9.41111 67.4548 9.6383 67.5339 9.85068L67.682 10.2754C67.929 11.0657 68.0524 11.8855 68.0524 12.7202V23.2797C68.0524 25.5714 69.9095 27.4285 72.2012 27.4285C74.4929 27.4285 76.3499 29.2855 76.3499 31.5772V40.4278C76.3499 42.7195 74.4929 44.5766 72.2012 44.5766C69.9095 44.5766 68.0524 46.4336 68.0524 48.7253V59.2848C68.0524 60.7912 67.6425 62.263 66.8622 63.5422C66.714 63.7892 66.5757 63.9966 66.4275 64.1843C66.2497 64.4213 66.0917 64.614 65.9287 64.7918C64.3581 66.5253 62.1454 67.5033 59.8291 67.5033H13.2595H13.2645Z"
        fill="#2A7DE7"
      />
    </g>
    <path
      d="M42.7001 48.0415L41.2136 49.528C40.5842 50.1573 39.5665 50.1573 38.9438 49.528L25.9274 36.5182C25.298 35.8889 25.298 34.8711 25.9274 34.2484L38.9371 21.2387C39.5665 20.6093 40.5842 20.6093 41.2069 21.2387L42.6934 22.7251C43.3294 23.3612 43.3161 24.3991 42.6666 25.0218L34.605 32.7017H53.8484C54.7389 32.7017 55.4553 33.4181 55.4553 34.3087V36.4513C55.4553 37.3418 54.7389 38.0583 53.8484 38.0583H34.605L42.6733 45.7449C43.3294 46.3676 43.3428 47.4054 42.7001 48.0415Z"
      fill="white"
    />
  </svg>
</template>
