<script setup>
import BetyHeader from "@/components/bety/BetyHeader";
import BetyAppbar from "@/components/bety/BetyAppbar";
import BetyCamera from "@/components/bety/BetyCamera";
import BetyCameraSpace from "@/components/bety/BetyCameraSpace";
import BetySpace from "@/components/bety/BetySpace";
import BetyBlock from "@/components/bety/BetyBlock";
import BetyPagenavigator from "@/components/bety/BetyPagenavigator";
import BetyHelp from "@/components/bety/BetyHelp";

import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();

const router = useRouter();

const routeMajor = ref("");
const routeMinor = ref("");
const routePageno = ref(0);
const mode = ref({});
const isHelpOpen = computed(() => store.state.Bety.isHelpOpen);

const deriveMode = (major, minor) => {
  const mode = {
    hangeul: {
      represent: {
        spaceType: "represent",
        type: "pose", // pose, hand, face
        pose: true,
        hand: false,
        face: false,
        block: false,
        blockType: "normal",
        shutter: false,
        onlyCamera: true,
        numPerson: 3,
        noPageNaviator: false,
      },
      represent_hand: {
        spaceType: "represent_hand",
        type: "hand", // pose, hand, face
        pose: false,
        hand: true,
        face: false,
        block: false,
        blockType: "normal",
        shutter: false,
        onlyCamera: true,
        numPerson: 1,
        noPageNaviator: false,
      },
      pixel: {
        spaceType: "pixel",
        type: "pose", // pose, hand, face
        pose: true,
        hand: false,
        face: false,
        block: true,
        blockType: "normal",
        shutter: true,
        onlyCamera: false,
        numPerson: 1,
        noPageNaviator: false,
      },

      wordselect: {
        spaceType: "wordselect",
        type: "hand", // pose, hand, face
        pose: false,
        hand: true,
        face: false,
        block: false,
        blockType: "normal",
        shutter: false,
        onlyCamera: true,
        numPerson: 1,
        noPageNaviator: false,
      },
    },
    math: {
      order: {
        spaceType: "order",
        type: "hand", // pose, hand, face
        pose: false,
        hand: true,
        face: false,
        block: false,
        blockType: "normal",
        shutter: false,
        onlyCamera: true,
        numPerson: 1,
        noPageNaviator: true,
      },

      math_represent: {
        spaceType: "math_represent",
        type: "hand", // pose, hand, face
        pose: false,
        hand: true,
        face: false,
        block: false,
        blockType: "normal",
        shutter: false,
        onlyCamera: true,
        numPerson: 1,
        noPageNaviator: false,
      },
      maze_inc: {
        spaceType: "maze_inc",
        type: "pose", // pose, hand, face
        pose: true,
        hand: false,
        face: false,
        block: true,
        blockType: "normal",
        shutter: true,
        onlyCamera: false,
        numPerson: 1,
        noPageNaviator: true,
      },
      maze_dec: {
        spaceType: "maze_dec",
        type: "pose", // pose, hand, face
        pose: true,
        hand: false,
        face: false,
        block: true,
        blockType: "normal",
        shutter: true,
        onlyCamera: false,
        numPerson: 1,
        noPageNaviator: true,
      },
    },
    coding: {
      maze_compute_pose: {
        spaceType: "maze_compute",
        type: "pose", // pose, hand, face
        pose: true,
        hand: false,
        face: false,
        block: true,
        blockType: "rotate",
        shutter: true,
        onlyCamera: false,
        numPerson: 1,
        noPageNaviator: false,
      },
      maze_compute_face: {
        spaceType: "maze_compute",
        type: "face", // pose, hand, face
        pose: false,
        hand: false,
        face: true,
        block: true,
        blockType: "rotate",
        shutter: true,
        onlyCamera: false,
        numPerson: 1,
        noPageNaviator: false,
      },
      maze_compute_hand: {
        spaceType: "maze_compute",
        type: "hand", // pose, hand, face
        pose: false,
        hand: true,
        face: false,
        block: true,
        blockType: "rotate",
        shutter: true,
        onlyCamera: false,
        numPerson: 1,
        noPageNaviator: false,
      },
    },
    aion: {
      control_hand: {
        spaceType: "control_hand",
        type: "hand", // pose, hand, face
        pose: false,
        hand: true,
        face: false,
        block: false,
        blockType: "rotate",
        shutter: false,
        onlyCamera: true,
        numPerson: 1,
        noPageNaviator: true,
      },
      control_face: {
        spaceType: "control_face",
        type: "face", // pose, hand, face
        pose: false,
        hand: false,
        face: true,
        block: false,
        blockType: "rotate",
        shutter: false,
        onlyCamera: true,
        numPerson: 1,
        noPageNaviator: true,
      },
      chain1: {
        spaceType: "chain1",
        type: "hand", // pose, hand, face
        pose: false,
        hand: true,
        face: false,
        block: false,
        blockType: "rotate",
        shutter: false,
        onlyCamera: true,
        numPerson: 1,
        noPageNaviator: true,
      },
      chain2: {
        spaceType: "chain2",
        type: "hand", // pose, hand, face
        pose: false,
        hand: true,
        face: false,
        block: false,
        blockType: "rotate",
        shutter: false,
        onlyCamera: true,
        numPerson: 1,
        noPageNaviator: true,
      },
      chain3: {
        spaceType: "chain3",
        type: "hand", // pose, hand, face
        pose: false,
        hand: true,
        face: false,
        block: false,
        blockType: "rotate",
        shutter: false,
        onlyCamera: true,
        numPerson: 1,
        noPageNaviator: true,
      },
      chain4: {
        spaceType: "chain4",
        type: "hand", // pose, hand, face
        pose: false,
        hand: true,
        face: false,
        block: false,
        blockType: "rotate",
        shutter: false,
        onlyCamera: true,
        numPerson: 1,
        noPageNaviator: true,
      },
      chain5: {
        spaceType: "chain5",
        type: "pose", // pose, hand, face
        pose: true,
        hand: false,
        face: false,
        block: false,
        blockType: "rotate",
        shutter: false,
        onlyCamera: true,
        numPerson: 1,
        noPageNaviator: true,
      },
    },
  };
  return mode[major][minor];
};

const setMode = () => {
  routeMajor.value = router.currentRoute.value.params.major || "";
  routeMinor.value = router.currentRoute.value.params.minor || "";
  routePageno.value = parseInt(router.currentRoute.value.params.pageno || "");
  routePageno.value = isNaN(routePageno.value) ? 0 : routePageno.value - 1;
  console.log(routeMajor.value, routeMinor.value, mode.value);
  mode.value = deriveMode(routeMajor.value, routeMinor.value);
};

let scaleRatio = ref(1.0);

const scaleStyle = computed(() => {
  return `transform:scale(${scaleRatio.value});`;
});

// 리사이즈 이벤트 핸들러
const handleResize = () => {
  const cw = 1600.0;
  const ch = 1000.0;
  const cr = cw / ch;
  const pw = window.innerWidth;
  const ph = window.innerHeight;
  const pr = pw / ph;
  console.log(cw / ch);
  console.log(pw / ph);
  if (pr > cr) {
    scaleRatio.value = ph / ch;
  } else {
    scaleRatio.value = pw / cw;
  }
};
onMounted(() => {
  store.dispatch("User/initializeAuthState"); // 상태 초기화
  // if (!store.state.User.isAuthenticatedByCode) {
  //   location.href = "/betycode";
  // }
  setMode();
  handleResize();
  window.addEventListener("resize", handleResize);
});
// onUpdated(() => {
//   setMode();
// });
</script>

<template>
  <div class="fullscreen">
    <div class="wraps" :style="scaleStyle">
      <header>
        <nav>
          <div class="nav_menu">
            <bety-header />
          </div>
        </nav>
      </header>
      <main>
        <div class="content">
          <div class="nav_appbar">
            <bety-appbar :major="routeMajor" :minor="routeMinor" />
          </div>
          <div class="playground">
            <!-- <div
              class="test2"
              style="
                position: absolute;
                left: 950px;
                top: 350px;
                width: 100px;
                height: 100px;
                background-color: rgba(255, 255, 255, 1);
                mix-blend-mode: overlay;
                border-radius: 10px;
              "
            ></div> -->
            <div class="left">
              <div class="camera">
                <bety-camera
                  :type="mode.type"
                  :pose="mode.pose"
                  :hand="mode.hand"
                  :face="mode.face"
                  :block="mode.block"
                  :blockType="mode.blockType"
                  :shutter="mode.shutter"
                  :numPerson="mode.numPerson"
                />
                <bety-camera-space
                  :type="mode.spaceType"
                  :pageno="routePageno"
                />
              </div>
            </div>
            <div class="right" v-if="!mode.onlyCamera">
              <div class="space">
                <bety-space :type="mode.spaceType" :pageno="routePageno" />
              </div>
              <div class="blocks">
                <bety-block :blockType="mode.blockType" />
              </div>
            </div>

            <bety-pagenavigator
              v-if="!mode.noPageNaviator"
              style="position: absolute; right: 20px; bottom: 20px"
            />
          </div>
        </div>
        <bety-help v-if="isHelpOpen" />
      </main>
    </div>
  </div>
</template>

<style scoped>
.fullscreen {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(0, 0, 0);
}
.wraps {
  width: 1600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Jua", sans-serif;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* @media screen and (max-width: 1200px) {
  .fullscreen {
    width: auto;
    height: 120vh;
  }
  .wraps {
    transform: scale(0.68);
  }
}
@media screen and (max-width: 1024px) {
  .fullscreen {
    width: auto;
    height: 120vh;
  }
  .wraps {
    transform: scale(0.6);
  }
} */

.nav_menu {
  height: 50px;
}
main {
  flex: 1;
}
.content {
  display: flex;
  width: 100%;
  height: 100%;
}

.nav_appbar {
  min-width: 90px;
  width: 90px;
  height: 100%;
}
.playground {
  position: relative;
  display: flex;
  flex: 1;
  background-image: url("@/assets/bety_bg.png");
  background-size: cover;
  padding-top: 10px;
  padding-left: 20px;
  gap: 20px;
}

.left {
  flex: 1;
}
.right {
  width: 768px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.camera {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.camera_only {
  width: 85%;
}

.space {
  position: relative;
  height: 568px;
}
.blocks {
  flex: 1;
}
</style>
