<script setup>
import TheHeader from "@/components/TheHeader";
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import axios from "@/api";

const router = useRouter();
axios.defaults.withCredentials = true;

let username = ref("");
let password = ref("");
const usernameFocused = ref(false);
const passwordFocused = ref(false);
const isMismatch = ref(false);

watch(username, () => {
  isMismatch.value = false;
});
watch(password, () => {
  isMismatch.value = false;
});

function handleFocus(field, value) {
  if (field === "username") {
    usernameFocused.value = value;
  } else if (field === "password") {
    passwordFocused.value = value;
  }
}
const usernameStatus = computed(() => {
  if (username.value === "" && !usernameFocused.value) {
    return "none";
  } else {
    return "correct";
  }
});

const passwordStatus = computed(() => {
  if (password.value === "" && !passwordFocused.value) {
    return "none";
  } else {
    return "correct";
  }
});

const btnLoginText = computed(() => {
  if (!isEmail(username.value)) {
    return "이메일을 올바르게 기입해주세요";
  }
  if (!isPasswordValid(password.value)) {
    return "비밀번호를 올바르게 기입해주세요";
  }
  return "로그인";
});

const mismatchText = computed(() => {
  if (isMismatch.value) return "올바른 아이디 또는 비밀번호를 입력해주세요.";
  else return " ";
});

const btnLoginStatus = computed(() => {
  if (btnLoginText.value === "로그인") return true;
  else return false;
});

async function clickLogin() {
  if (!btnLoginStatus.value) return;
  await login();
}

async function login() {
  try {
    const response = await axios.post(`/api/auth/login`, {
      username: username.value,
      password: password.value,
    });
    console.log("Login Success", response.data);
    router.replace({ name: "Home" });
    console.log(router);
  } catch (error) {
    console.error("Login Fail");
    console.warn(error.response.data.message);
    isMismatch.value = true;
  }
}

function isEmail(value) {
  const emailRegex =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  return emailRegex.test(value);
}

function isPasswordValid(value) {
  const passwordRegex =
    /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{8,32}$/;
  return passwordRegex.test(value);
}
</script>

<template>
  <the-header />
  <main>
    <div class="container">
      <div class="content">
        <div class="login_header">
          <div class="header_title">로그인</div>
          <div class="header_title_sub">
            베티와 함께 다양한 코딩을 경험해보세요.
          </div>
        </div>
        <div class="login_main">
          <div class="main_menu">
            <button class="btn_student">학생</button>
            <button class="btn_admin">관리자</button>
          </div>
          <div class="main_input">
            <div class="username_input" :class="usernameStatus">
              <input
                class="ipt_username"
                type="text"
                placeholder="이메일"
                v-model="username"
                @focus="handleFocus('username', true)"
                @blur="handleFocus('username', false)"
              />
            </div>
            <div class="password_input" :class="passwordStatus">
              <input
                class="ipt_password"
                type="password"
                placeholder="비밀번호"
                v-model="password"
                @focus="handleFocus('password', true)"
                @blur="handleFocus('password', false)"
              />
            </div>
          </div>
          <div class="main_sub">
            <div class="main_wrong">
              {{ mismatchText }}
            </div>
            <div class="main_find">비밀번호 찾기</div>
          </div>

          <button
            class="btn_login"
            :class="{ btn_disable: !btnLoginStatus }"
            @click="clickLogin"
          >
            {{ btnLoginText }}
          </button>
          <div class="main_register">
            아직 회원이 아니신가요?&nbsp;
            <router-link to="/register"><b>회원가입</b></router-link
            >&nbsp;하기
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
b {
  font-weight: 600;
}
.container {
  width: 100%;
  max-width: var(--container-width);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.content {
  width: 350px;
  display: flex;
  flex-direction: column;
  padding-top: 120px;
}

.login_header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 60px;
}

.header_title {
  font-size: 25px;
  font-weight: 600;
}
.header_title_sub {
  font-size: 15px;
}
.login_header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.main_menu {
  width: 100%;
  display: flex;
  gap: 10px;
}
.main_input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.main_sub {
  width: 100%;
  display: flex;
}
.main_wrong {
  flex: 1;
  color: #ef414f;
  font-size: 13px;
}
.main_find {
  display: flex;
  justify-content: flex-end;
  color: #4c4c4c;
  font-size: 13px;
}

.main_register {
  display: flex;
  justify-content: center;
  font-size: 12px;
  padding-top: 50px;
}

.btn_student {
  flex: 1;
  height: 40px;
  color: white;
  background-color: white;
  border: 2px solid #d9d9d9;
  color: #5f5e5c;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
}
.btn_admin {
  flex: 1;
  height: 40px;
  background-color: #714bdd;
  color: white;
  border: 0;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
}
.btn_login {
  height: 50px;
  border: 0;
  border-radius: 10px;
  background-color: #714bdd;
  color: #f8f8f8;
  font-size: 18px;
  font-weight: 700;
}

.username_input,
.password_input {
  width: 100%;
  height: 48px;
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.none {
  border: 2px solid #d9d9d9;
}
.wrong {
  border: 2px solid #ef414f;
}
.correct {
  border: 2px solid #714bdd;
}

.ipt_username,
.ipt_password {
  border: 0;
  width: 90%;
  height: 80%;
  padding: 4px;
  box-sizing: border-box;
}
input:focus {
  outline: none;
}
.ipt_username::placeholder,
.ipt_password::placeholder {
  color: #898989;
  font-size: 14px;
}
button:hover {
  cursor: pointer;
}

.btn_disable {
  background-color: #666;
}
.btn_disable:hover {
  cursor: default;
}
</style>

<!-- <template>
  <main>
    <div class="container">
      <div class="panel">
        <div class="logo">
          <router-link to="/"> RE:WOND </router-link>
        </div>
        <div class="panel-header"></div>
        <div class="username">
          <input
            class="input-username"
            v-model="username"
            placeholder="아이디"
          />
        </div>
        <div class="line" focused></div>
        <div class="password" focused>
          <input
            class="input-password"
            v-model="password"
            type="password"
            placeholder="비밀번호"
          />
        </div>
        <div class="action">
          <button class="btn-login" @click="login">로그인</button>
        </div>

        <div class="extra">
          <div class="find_username">아이디 찾기</div>
          <div class="hr"></div>
          <div class="find_password">비밀번호 찾기</div>
          <div class="hr"></div>
          <div class="register">
            <router-link to="/register"> 회원가입 </router-link>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import axios from "@/api";

let username = ref("");
let password = ref("");

const router = useRouter();

axios.defaults.withCredentials = true;

async function login() {
  try {
    const response = await axios.post(`/api/auth/login`, {
      username: username.value,
      password: password.value,
    });
    console.log("Login successful", response.data);
    router.replace({ name: "Checklist" });
  } catch (error) {
    alert("아이디 및 패스워드가 올바르지 않습니다");
    console.error("Login Failed", error);
  }
}
</script>

<style scoped>
svg {
  width: 60%;
  height: 100%;
  fill: var(--color-primary);
}
main {
  background-color: var(--color-primary);
  height: 100%;
}

.logo {
  display: flex;
  justify-content: center;
  font-size: 30px;
  padding-bottom: 50px;
  color: var(--color-primary);
}

.container {
  display: flex;
  justify-content: center;
}

.panel {
  margin-top: 20px;
  width: 90%;
  background-color: white;
  border: 1px solid var(--color-primary);
  border-radius: 6px;
  padding: 50px 20px 200px 20px;
  box-sizing: border-box;
}

.username,
.password {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  border: 1px solid gray;
  box-sizing: border-box;
  padding: 8px 5px;
  margin-left: auto;
  margin-right: auto;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.username {
  border-bottom: 0;
  border-radius: 10px 10px 0 0;
}
.line {
  border-top: 1px solid gray;
}

.password {
  border-top: 0;
  border-radius: 0 0 10px 10px;
}

.username[focused] {
  border: 1px solid var(--color-primary);
  border-bottom: 0;
}
.password[focused] {
  border: 1px solid var(--color-primary);
  border-top: 0;
}
.username[focused] {
  border-top: 1px solid var(--color-primary);
}

.input-username,
.input-password {
  width: 90%;
  height: 30px;
  background-color: white;
  border: 0;
  font-size: 16px;
  outline: none;
}

.action {
  padding-top: 20px;
  padding-bottom: 10px;
}
.btn-login {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary);
}

.extra {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.extra .hr {
  border-left: 1px solid gray;
}

.extra * {
  font-size: 13px;
}
/*
.username,
.password {
  display: flex;
  flex-direction: column;
}

.input-username {
  height: 40px;
  border-radius: 5px 5px 0 0;
  border: 1px solid gray;
  font-size: 17px;
}

.input-password {
  height: 40px;
  border-radius: 0 0 5px 5px;
  border: 1px solid gray;
  font-size: 17px;
}
*/
</style> -->
