<template>
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.62989 13.5L0 4.75358L1.81508 3.37114L5.80393 9.56894L13.3164 0L15 1.56779L5.62989 13.5Z"
      fill="#5F5E5C"
    />
  </svg>
</template>
