<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { watch } from "vue";

const store = useStore();
const password = ref("");

onMounted(() => {
  store.dispatch("User/initializeAuthState"); // 상태 초기화
  if (store.state.User.isAuthenticatedByCode) {
    location.href = "/bety";
  }
});

const checkPassword = () => {
  console.log("chk");
  store.dispatch("User/checkPassword", password.value);
};

watch(
  () => store.state.User.isAuthenticatedByCode,
  (newVal) => {
    console.log(newVal);
    if (newVal) {
      location.href = "/bety";
    }
  }
);
</script>

<template>
  <div class="container_code">
    <div class="password-box">
      <div class="title">접속 코드를 입력해주세요</div>
      <div class="form_pwd">
        <input
          class="ipt_pwd"
          v-model="password"
          type="password"
          placeholder="패스워드를 입력해주세요"
        />
      </div>

      <button class="btn_confirm" @click="checkPassword">확인</button>
      <div class="ps">인증은 12시간 유효합니다.</div>
    </div>
  </div>
</template>

<style scoped>
.container_code {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url("@/assets/bety_cm_bg.png");
  background-size: cover;
  background-position: center;
}
.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* opacity: 0.4; */
  z-index: -1;
}
.password-box {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.title {
  margin-bottom: 50px;
  color: var(--color-bety-primary);
  font-size: 20px;
  font-weight: 700;
}
.form_pwd {
  margin-bottom: 20px;
  height: 50px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #dbdbdb;
  border-radius: 10px;
}
.ipt_pwd {
  border: 0;
  width: 100%;
  height: 100%;
}

.btn_confirm {
  padding: 10px;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: var(--color-bety-primary);
  color: white;
  border: 0;
  border-radius: 10px;
  font-size: 18px;
}

.ps {
  margin-top: 5px;
  font-size: 12px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #898989;
}
</style>
