<script setup>
import BetyDialogSuccess from "@/components/bety/sprite/BetyDialogSuccess";
import BetyDialogFail from "@/components/bety/sprite/BetyDialogFail";
import BetyDialogRestart from "@/components/bety/sprite/BetyDialogRestart";
import BetyDialogNext from "@/components/bety/sprite/BetyDialogNext";

const props = defineProps({
  type: {
    type: String,
    required: true,
    default: "success",
  },
  bgCover: {
    type: Boolean,
    required: true,
    default: true,
  },
  noContinue: {
    type: Boolean,
    required: true,
    default: false,
  },
});
const emit = defineEmits(["closed"]);
const emitEvent = (value) => {
  emit("closed", value);
};

const next = () => {
  emitEvent("next");
  // location.reload();
};

const refresh = () => {
  emitEvent("reload");
  // location.reload();
};
</script>

<template>
  <div class="wrap">
    <div class="bg-cover" v-if="props.bgCover"></div>
    <div class="dialog">
      <div class="success" v-if="props.type === 'success'">
        <bety-dialog-success style="height: 273px" />
        <div class="nav">
          <div class="restart" @click="refresh">
            <bety-dialog-restart style="height: 55px" />
          </div>
          <div class="next" v-if="!props.noContinue">
            <bety-dialog-next @click="next" style="height: 55px" />
          </div>
        </div>
      </div>
      <div class="fail" v-if="props.type === 'fail'">
        <bety-dialog-fail style="height: 273px" />
        <div class="nav">
          <div class="restart" @click="refresh">
            <bety-dialog-restart style="height: 55px" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.bg-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--color-bety-black);
  opacity: 0.5;
}
.dialog {
  position: absolute;
  width: 400px;
  height: 400px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid var(--color-bety-white);
  background-color: var(--color-bety-primary);
  border-radius: 30px;
  padding: 30px;
}

.success,
.fail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.restart:hover,
.next:hover {
  cursor: pointer;
}

.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 170px;
}
</style>
