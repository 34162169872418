<script setup>
import MotionBlockCircle from "@/components/block/MotionBlockCircle";
import MotionBlockLeft from "@/components/block/MotionBlockLeft";
import MotionBlockRight from "@/components/block/MotionBlockRight";
import MotionBlockUp from "@/components/block/MotionBlockUp";
import MotionBlockDown from "@/components/block/MotionBlockDown";
import MotionBlockShoot from "@/components/block/MotionBlockShoot";

import BetyBlockCircle from "@/components/bety/block/BetyBlockCircle";
import BetyBlockGo from "@/components/bety/block/BetyBlockGo";
import BetyBlockJump from "@/components/bety/block/BetyBlockJump";
import BetyBlockTurnLeft from "@/components/bety/block/BetyBlockTurnLeft";
import BetyBlockTurnRight from "@/components/bety/block/BetyBlockTurnRight";
import BetyBlockShoot from "@/components/bety/block/BetyBlockShoot";
// import BetyBlockLoopStart from "@/components/bety/block/BetyBlockLoopStart";
// import BetyBlockLoopEnd from "@/components/bety/block/BetyBlockLoopEnd";

const props = defineProps({
  name: {
    type: String,
    required: true,
    default: "",
  },
  blockType: {
    type: String,
    required: true,
    default: "normal",
  },
});
</script>

<template>
  <div class="block">
    <!-- normal -->
    <motion-block-circle
      v-if="props.name == 'circle' && props.blockType == 'normal'"
    />
    <motion-block-left
      v-if="props.name == 'left go' && props.blockType == 'normal'"
    />
    <motion-block-right
      v-if="props.name == 'right go' && props.blockType == 'normal'"
    />
    <motion-block-up
      v-if="props.name == 'up go' && props.blockType == 'normal'"
    />
    <motion-block-down
      v-if="props.name == 'down go' && props.blockType == 'normal'"
    />
    <motion-block-shoot
      v-if="props.name == 'shoot' && props.blockType == 'normal'"
    />

    <!-- rotate -->
    <bety-block-circle
      v-if="props.name == 'circle' && props.blockType == 'rotate'"
    />
    <bety-block-turn-left
      v-if="props.name == 'left turn' && props.blockType == 'rotate'"
    />
    <bety-block-turn-right
      v-if="props.name == 'right turn' && props.blockType == 'rotate'"
    />
    <bety-block-go v-if="props.name == 'go' && props.blockType == 'rotate'" />
    <bety-block-jump
      v-if="props.name == 'jump' && props.blockType == 'rotate'"
    />
    <bety-block-shoot
      v-if="props.name == 'shoot' && props.blockType == 'rotate'"
    />
  </div>
</template>

<style scoped>
.block {
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 70px;
  height: 70px;
  border: 0;
}
</style>
