<script setup>
import BetyHeaderLogoIcon from "@/components/bety/icon/BetyHeaderLogoIcon";
// import BetyHeaderSettingIcon from "@/components/bety/icon/BetyHeaderSettingIcon";
// import BetyHeaderFileIcon from "@/components/bety/icon/BetyHeaderFileIcon";
// import BetyHeaderEditIcon from "@/components/bety/icon/BetyHeaderEditIcon";
import BetyHeaderHelpIcon from "@/components/bety/icon/BetyHeaderHelpIcon";
import BetyHeaderHangeulIcon from "@/components/bety/icon/BetyHeaderHangeulIcon";
import BetyHeaderMathIcon from "@/components/bety/icon/BetyHeaderMathIcon";
import BetyHeaderCodingIcon from "@/components/bety/icon/BetyHeaderCodingIcon";
import BetyHeaderAionIcon from "@/components/bety/icon/BetyHeaderAionIcon";
import BetyHeaderSearchIcon from "@/components/bety/icon/BetyHeaderSearchIcon";
import BetyHeaderProfileIcon from "@/components/bety/icon/BetyHeaderProfileIcon";

import { useStore } from "vuex";
const store = useStore();

const openHelp = () => {
  store.commit("Bety/setHelpOpen", true);
};
</script>

<template>
  <div class="wrap">
    <div class="left">
      <div class="logo">
        <bety-header-logo-icon />
      </div>
      <a :href="`/bety/hangeul/represent/1`">
        <div class="menu_item hangeul">
          <div class="hangeul_icon icon">
            <bety-header-hangeul-icon />
          </div>
          국어
        </div>
      </a>
      <a :href="`/bety/math/order/1`">
        <div class="menu_item math">
          <div class="math_icon icon">
            <bety-header-math-icon />
          </div>
          수학
        </div>
      </a>
      <a :href="`/bety/coding/maze_compute_pose/1`">
        <div class="menu_item coding">
          <div class="coding_icon icon">
            <bety-header-coding-icon />
          </div>
          코딩
        </div>
      </a>
      <a :href="`/bety/aion/control_hand/1`">
        <div class="menu_item aion">
          <div class="aion_icon icon">
            <bety-header-aion-icon />
          </div>
          아이온
        </div>
      </a>
      <!-- <div class="menu_item setting">
        <div class="setting_icon icon">
          <bety-header-setting-icon />
        </div>
        설정
      </div>
      <div class="menu_item file">
        <div class="file_icon icon">
          <bety-header-file-icon />
        </div>
        파일
      </div>
      <div class="menu_item edit">
        <div class="edit_icon icon">
          <bety-header-edit-icon />
        </div>
        편집
      </div> -->
      <div class="hr-v"></div>
      <div class="menu_item help" @click.stop="openHelp">
        <div class="help_icon icon">
          <bety-header-help-icon />
        </div>
        도움말
      </div>
    </div>
    <div class="right">
      <div class="search">
        <div class="search_icon">
          <bety-header-search-icon />
        </div>
        <div class="search_input">
          <input type="text" placeholder="검색" />
        </div>
      </div>
      <div class="user">
        <bety-header-profile-icon />
      </div>
    </div>
  </div>
</template>
<style scoped>
.wrap {
  width: 100%;
  height: 100%;
  background-color: var(--color-bety-primary);
  color: var(--color-bety-white);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.left {
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
}
.logo {
  padding: 0 30px;
}

.icon {
  width: 14px;
  height: 14px;
  margin-right: 7px;
}

.aion_icon {
  margin-right: 10px;
  margin-bottom: 2px;
}

.hr-v {
  border: 0;
  border-left: 1px solid gray;
  height: 20px;
  padding-left: 10px;
}

.menu_item {
  padding-left: 15px;
  padding-right: 20px;
  display: flex;
  align-items: center;
}

.right {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.search {
  width: 170px;
  height: 30px;
  border: 1px solid gray;
  background-color: var(--color-bety-white);
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 10px;
  box-sizing: border-box;
}

.search_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.search_input {
  padding-left: 5px;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
}

.search_input input {
  width: 100%;
  height: 100%;
  border: 0;
}
.search_input input:focus {
  outline: none;
}

.user {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-left: 20px;
}

.help {
  cursor: pointer;
}
</style>
