<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.66312 6.97418C3.57972 6.91881 3.55011 6.81031 3.59382 6.72024L4.095 5.6875C4.21167 5.4425 4.38387 5.25 4.6116 5.11C4.83933 4.97 5.08713 4.9 5.355 4.9H6.1C6.21046 4.9 6.3 4.81046 6.3 4.7V0.2C6.3 0.0895432 6.38954 0 6.5 0H10.8764C11.0251 0 11.1218 0.156463 11.0553 0.289443L10.5447 1.31056C10.5166 1.36686 10.5166 1.43314 10.5447 1.48944L11.0553 2.51056C11.1218 2.64354 11.0251 2.8 10.8764 2.8H7.9C7.78954 2.8 7.7 2.88954 7.7 3V4.7C7.7 4.81046 7.78954 4.9 7.9 4.9H8.5925C8.86083 4.9 9.10303 4.97 9.3191 5.11C9.53517 5.25 9.70713 5.43667 9.835 5.67L10.3685 6.73708C10.4136 6.8273 10.3845 6.93699 10.3006 6.99294L8.44465 8.23023C8.38462 8.27025 8.3077 8.27484 8.24334 8.24224L7.09037 7.65827C7.03356 7.6295 6.96644 7.6295 6.90963 7.65827L5.75643 8.24236C5.6922 8.27489 5.61543 8.27039 5.55544 8.23056L3.66312 6.97418ZM0.321451 14C0.173297 14 0.0765714 13.8445 0.142033 13.7116L2.78694 8.34166C2.84026 8.23342 2.97655 8.19667 3.07705 8.26345L5.45154 9.84123C5.51094 9.8807 5.58686 9.88556 5.6508 9.85397L6.91142 9.23125C6.96725 9.20368 7.03275 9.20368 7.08858 9.23125L8.34957 9.85415C8.41331 9.88564 8.48898 9.88092 8.54832 9.84176L10.8895 8.29657C10.9895 8.23056 11.1247 8.26671 11.1785 8.37383L13.8547 13.7103C13.9214 13.8433 13.8247 14 13.676 14H0.321451Z"
      fill="#EF414F"
    />
  </svg>
</template>
