<script setup>
import SpaceHangeulRepresent from "@/components/bety/space/SpaceHangeulRepresent";
import SpaceHangeulWordselect from "@/components/bety/space/SpaceHangeulWordselect";
import SpaceMathOrder from "@/components/bety/space/SpaceMathOrder";
import SpaceMathRepresent from "@/components/bety/space/SpaceMathRepresent";
import SpaceAionControl from "@/components/bety/space/SpaceAionControl";
import SpaceAionChain1 from "@/components/bety/space/SpaceAionChain1";
import SpaceAionChain2 from "@/components/bety/space/SpaceAionChain2";
import SpaceAionChain3 from "@/components/bety/space/SpaceAionChain3";
import SpaceAionChain4 from "@/components/bety/space/SpaceAionChain4";
import SpaceAionChain5 from "@/components/bety/space/SpaceAionChain5";
import SpaceComputeRobot from "@/components/bety/space/SpaceComputeRobot";

const props = defineProps({
  type: {
    type: String,
    required: true,
    default: "",
  },
  pageno: {
    type: Number,
    required: true,
    default: 0,
  },
});
</script>

<template>
  <div class="wrap">
    <space-hangeul-represent
      v-if="props.type === 'represent'"
      :pageno="props.pageno"
    />
    <space-hangeul-represent
      v-if="props.type === 'represent_hand'"
      :pageno="props.pageno"
    />
    <space-hangeul-wordselect
      v-if="props.type === 'wordselect'"
      :pageno="props.pageno"
    />
    <space-math-order v-if="props.type === 'order'" :pageno="props.pageno" />
    <space-math-represent
      v-if="props.type === 'math_represent'"
      :pageno="props.pageno"
    />
    <space-aion-control
      v-if="props.type === 'control_hand'"
      :type="props.type"
    />
    <space-aion-control
      v-if="props.type === 'control_face'"
      :type="props.type"
    />
    <space-aion-chain1 v-if="props.type === 'chain1'" :type="props.type" />
    <space-aion-chain2 v-if="props.type === 'chain2'" :type="props.type" />
    <space-aion-chain3 v-if="props.type === 'chain3'" :type="props.type" />
    <space-aion-chain4 v-if="props.type === 'chain4'" :type="props.type" />
    <space-aion-chain5 v-if="props.type === 'chain5'" :type="props.type" />
    <space-compute-robot
      v-if="props.type === 'compute_robot_face'"
      :type="props.type"
    />
  </div>
</template>

<style scoped>
.wrap {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}
</style>
