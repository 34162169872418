<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_98_63)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2477 8.69931L13.3727 11.8251L10.2469 14.9508C10.1088 15.089 9.92148 15.1666 9.72611 15.1667H7.6435C7.44811 15.1667 7.26074 15.0891 7.12258 14.9509C6.98442 14.8128 6.90681 14.6254 6.90681 14.43V12.3459C6.90685 12.1505 6.98449 11.9632 7.12266 11.8251L10.2477 8.69931ZM12.0636 1.16669C12.4353 1.16657 12.7934 1.30696 13.0659 1.55971C13.3385 1.81246 13.5054 2.15889 13.5333 2.52956L13.537 2.64006V5.60154C12.9804 5.49597 12.407 5.52028 11.8614 5.67259C11.3158 5.8249 10.8127 6.1011 10.3913 6.47967L10.2469 6.61596L6.08025 10.7834C5.70152 11.1622 5.47324 11.6656 5.43785 12.2L5.4327 12.3459V14.43C5.43276 14.6302 5.46002 14.8294 5.51373 15.0223L5.55793 15.1637H3.22337C2.85166 15.1639 2.49364 15.0235 2.22108 14.7707C1.94852 14.518 1.78156 14.1715 1.75368 13.8009L1.75 13.6904V2.64006C1.74988 2.26835 1.89027 1.91032 2.14302 1.63776C2.39577 1.3652 2.7422 1.19825 3.11287 1.17037L3.22337 1.16669H12.0636ZM14.4144 7.65764C14.6196 7.86286 14.7825 8.10652 14.8936 8.37468C15.0047 8.64284 15.0618 8.93026 15.0618 9.22052C15.0618 9.51078 15.0047 9.7982 14.8936 10.0664C14.7825 10.3345 14.6196 10.5782 14.4144 10.7834L11.2901 7.6569C11.7045 7.24258 12.2666 7.00982 12.8526 7.00982C13.4386 7.00982 14.0007 7.24258 14.4151 7.6569L14.4144 7.65764ZM7.6435 4.11344H4.69675C4.50137 4.11344 4.31399 4.19105 4.17583 4.32921C4.03768 4.46736 3.96006 4.65474 3.96006 4.85012C3.96006 5.0455 4.03768 5.23288 4.17583 5.37104C4.31399 5.50919 4.50137 5.58681 4.69675 5.58681H7.6435C7.83888 5.58681 8.02626 5.50919 8.16441 5.37104C8.30257 5.23288 8.38018 5.0455 8.38018 4.85012C8.38018 4.65474 8.30257 4.46736 8.16441 4.32921C8.02626 4.19105 7.83888 4.11344 7.6435 4.11344Z"
        fill="#5F5E5C"
      />
    </g>
    <defs>
      <clipPath id="clip0_98_63">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
