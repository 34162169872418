<template>
  <the-header />
  <main>
    <div class="container">
      <div class="gap-10"></div>
      <swiper-container
        :slides-per-view="1.1"
        :space-between="5"
        :centered-slides="true"
        :pagination="{
          hideOnClick: true,
        }"
        :breakpoints="{
          768: {
            slidesPerView: 1,
          },
        }"
      >
        <swiper-slide v-for="card in cards" :key="card.id">
          <checklist-card :card="card" @toggle-chk="toggleChk" />
        </swiper-slide>
      </swiper-container>
      <div class="gap-10"></div>
      <checklist-search />
      <div class="gap-10"></div>
      <checklist-modal />
    </div>
  </main>
</template>

<script setup>
import TheHeader from "@/components/TheHeader.vue";
import ChecklistCard from "@/components/ChecklistCard.vue";
import ChecklistModal from "@/components/ChecklistModal.vue";
import ChecklistSearch from "@/components/ChecklistSearch.vue";
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();

let cards = computed(() => store.state.Checklist.cardItem);
const toggleChk = (cardIdx, itemIdx) =>
  store.commit("Checklist/toggleCardItem", { cardIdx, itemIdx });

document
  .querySelector("body")
  .addEventListener("scroll touchmove mousewheel", (e) => e.preventDefault());
</script>

<!-- <style>
body {
  height: 100%;
  min-height: 100%;
  overflow: hidden !important;
  touch-action: none;
}
</style> -->

<style scoped>
.gap-10 {
  padding: 5px 0;
}
main {
  height: calc(100vh - 50px);
  background-color: #e6e9ed;
}
.container {
  width: 100vw;
  max-width: var(--container-width);
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

swiper-container {
  z-index: 0;
  width: 100%;
  height: calc(100% - 100px);
  flex: 1;
}
swiper-slide {
  height: auto;
  padding-bottom: 2px;
}
</style>
