<template>
  <svg
    width="80"
    height="72"
    viewBox="0 0 80 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="margin-right: -10px"
  >
    <path
      d="M72.4816 24.4338H72.3976C71.6223 24.4338 71 23.8065 71 23.0361V12.2008C71 6.01764 65.9873 1 59.7992 1H12.2008C6.0127 1 1 6.0127 1 12.2008V59.7992C1 65.9824 6.0127 71 12.2008 71H59.7992C65.9824 71 71 65.9873 71 59.7992V48.9639C71 48.1885 71.6272 47.5662 72.3976 47.5662H72.4816C76.3485 47.5662 79.4796 44.4302 79.4796 40.5682V31.4417C79.4796 27.5747 76.3436 24.4436 72.4816 24.4436V24.4338Z"
      fill="#F7B000"
      stroke="#FFFCFC"
      stroke-miterlimit="10"
    />
    <path
      d="M12.8527 67.4985C8.28942 67.4985 4.59534 63.7995 4.59534 59.2411V12.7588C4.59534 8.20049 8.29436 4.50146 12.8527 4.50146H59.335C63.8933 4.50146 67.5924 8.20049 67.5924 12.7588V23.2781C67.5924 25.5696 69.4493 27.4265 71.7408 27.4265C74.0323 27.4265 75.8893 29.2835 75.8893 31.575V40.42C75.8893 42.7116 74.0323 44.5685 71.7408 44.5685C69.4493 44.5685 67.5924 46.4254 67.5924 48.7169V59.2362C67.5924 63.7945 63.8933 67.4936 59.335 67.4936H12.8527V67.4985Z"
      fill="#FBBF17"
    />
    <g clip-path="url(#clip0_101_460)">
      <path
        d="M36.0631 27.2934C39.6612 27.2934 42.578 24.4003 42.578 20.8314C42.578 17.2625 39.6612 14.3694 36.0631 14.3694C32.4649 14.3694 29.5481 17.2625 29.5481 20.8314C29.5481 24.4003 32.4649 27.2934 36.0631 27.2934Z"
        fill="white"
      />
      <path
        d="M50.125 20.8314C50.125 17.4495 48.7609 14.2367 46.2829 11.7846C45.2232 10.737 43.5074 10.7381 42.4512 11.7892C41.3938 12.8403 41.3961 14.5421 42.4558 15.5898C43.9075 17.0251 44.7066 18.8871 44.7066 20.8303C44.7066 25.1284 40.8288 28.6247 36.0631 28.6247C31.2974 28.6247 27.4207 25.1284 27.4207 20.8303C27.4207 18.9066 28.2036 17.064 29.6254 15.6332C30.6724 14.5799 30.7289 12.8368 29.675 11.7892C28.621 10.7415 26.9006 10.7358 25.8409 11.7846C23.3641 14.2379 22 17.4506 22 20.8314C22 25.0804 24.1597 28.8649 27.5048 31.2747C27.7585 31.4577 27.9107 31.7494 27.9107 32.0616V57.2829C27.9107 59.3359 29.5885 61 31.6583 61C33.7281 61 35.4058 59.3359 35.4058 57.2829V46.8202C35.4058 46.4633 35.6826 46.1523 36.0423 46.142C36.4021 46.1317 36.7192 46.4267 36.7192 46.7927V57.9726C36.7192 59.6447 38.0856 61 39.7714 61H41.162C42.8479 61 44.2143 59.6447 44.2143 57.9726V32.0616C44.2143 31.7505 44.3653 31.4577 44.6202 31.2747C47.9653 28.8649 50.125 25.0804 50.125 20.8314Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_101_460">
        <rect
          width="28.125"
          height="50"
          fill="white"
          transform="translate(22 11)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
