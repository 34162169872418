<script setup>
import { computed } from "vue";
import BetyCharacterDown from "@/components/bety/sprite/character/BetyCharacterDown";
import BetyCharacterUp from "@/components/bety/sprite/character/BetyCharacterUp";
import BetyCharacterLeft from "@/components/bety/sprite/character/BetyCharacterLeft";
import BetyCharacterRight from "@/components/bety/sprite/character/BetyCharacterRight";
import BetyCharacterSuccess from "@/components/bety/sprite/character/BetyCharacterSuccess";
import BetyCharacterFail from "@/components/bety/sprite/character/BetyCharacterFail";
import BetyCharacterHurt from "@/components/bety/sprite/character/BetyCharacterHurt";
import BetyCharacterFall from "@/components/bety/sprite/character/BetyCharacterFall";

const props = defineProps({
  direction: {
    type: String, // down, up, right, left
    required: true,
    default: "down",
  },
  expression: {
    type: String, // normal
    required: true,
    default: "normal",
  },
  width: {
    type: Number,
    required: true,
    default: 80,
  },
  height: {
    type: Number,
    required: true,
    default: 80,
  },
  style: {
    type: String,
    required: true,
    default: "",
  },
});

const characterStyle = computed(() => {
  return `width:${props.width}px;height:${props.height}px;` + props.style;
});
</script>

<template>
  <bety-character-down
    v-if="props.direction === 'down' && props.expression === 'normal'"
    :style="characterStyle"
  />
  <bety-character-up
    v-if="props.direction === 'up' && props.expression === 'normal'"
    :style="characterStyle"
  />
  <bety-character-left
    v-if="props.direction === 'left' && props.expression === 'normal'"
    :style="characterStyle"
  />
  <bety-character-right
    v-if="props.direction === 'right' && props.expression === 'normal'"
    :style="characterStyle"
  />
  <bety-character-success
    v-if="props.expression === 'success'"
    :style="characterStyle"
  />
  <bety-character-fail
    v-if="props.expression === 'fail'"
    :style="characterStyle"
  />
  <bety-character-hurt
    v-if="props.expression === 'hurt'"
    :style="characterStyle"
  />
  <bety-character-fall
    v-if="props.expression === 'fall'"
    :style="characterStyle"
  />
</template>
