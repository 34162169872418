<script setup>
import HeaderProfileIcon from "@/components/icon/HeaderProfileIcon";
import { ref } from "vue";
import axios from "@/api";

axios.defaults.withCredentials = true;

let isLoggedIn = ref(false);

async function loggedin() {
  try {
    await axios.get(`/api/user/profile`);
    //const response = await axios.get(`/api/user/profile`);
    //console.log(response.data);
    isLoggedIn.value = true;
  } catch (error) {
    isLoggedIn.value = false;
    console.log(error);
  }
}

async function logout() {
  if (!isLoggedIn.value) return;
  try {
    const response = await axios.get(`/api/auth/logout`);
    console.log(response.data);
    console.log("Logout Success");
    isLoggedIn.value = false;
  } catch (error) {
    console.log("Logout Failed");
  }
}

async function clickLogout() {
  await logout();
}
function downloadTablet() {
  location.replace(
    `https://drive.google.com/file/d/1hwiysTIATneviYsmLzZr_gbXXlN60d4T/view?usp=drive_link`
  );
}

loggedin();
</script>

<template>
  <header id="header">
    <div class="container container-desktop">
      <div class="header-left">
        <router-link to="/">
          <div class="brand_header nav-link">RE:WOND</div>
        </router-link>
        <nav role="navigation" class="header-nav">
          <!--
          <router-link to="/travel">
            <div class="nav-travel nav-link">Travel</div>
          </router-link>
          -->
          <router-link to="/company">
            <div class="nav-company nav-link">회사</div>
          </router-link>
          <!-- <router-link to="/product">
            <div class="nav-product nav-link">제품</div>
          </router-link> -->
          <router-link to="/price">
            <div class="nav-price nav-link">요금</div>
          </router-link>
          <!-- <router-link to="/event">
            <div class="nav-price nav-link">활동</div>
          </router-link> -->
        </nav>
      </div>
      <div class="header-right-loggedin" v-if="isLoggedIn">
        <div class="logout" @click="clickLogout">로그아웃</div>
        <router-link to="/motion">
          <div class="profile"><header-profile-icon /></div>
        </router-link>
      </div>
      <div class="header-right" v-if="!isLoggedIn">
        <div class="tablet_download" @click="downloadTablet">태블릿용</div>
        <router-link to="/login">
          <div class="login">로그인</div>
        </router-link>

        <router-link to="/register">
          <div class="register">
            <button class="register-button">회원가입</button>
          </div>
        </router-link>
      </div>
    </div>
  </header>
</template>

<style scoped>
#header {
  background-color: var(--color-bety-primary);
  color: white;
}

#header .container {
  align-items: center;
}

.container {
  width: 100%;
  height: 100%;
  max-width: var(--container-width);
  min-height: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.header-left {
  width: 100%;
  align-items: center;
  display: flex;
  color: black;
}
.header-right {
  display: flex;
  align-items: center;
  gap: 5px;
}
.header-right-loggedin {
  display: flex;
  align-items: center;
  gap: 10px;
}
.header-nav {
  display: flex;
  width: 100%;
}
.nav-link {
  display: flex;
  color: white;
  padding: 8px 12px;
}
.brand_header {
  font-weight: 700;
  font-size: 24px;
}

.login {
  display: flex;
  align-items: center;
  width: 60px;
}
.register {
  display: flex;
  align-items: center;
  width: 80px;
}
.logout {
  display: flex;
  align-items: center;
  width: 70px;
}
.logout:hover {
  cursor: pointer;
}
.profile {
  display: flex;
  align-items: center;
}
.register-button {
  width: 100%;
  background-color: #f35050;
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
}
.tablet_download {
  width: 70px;
  background-color: var(--color-bety-complementary);
  color: black;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
</style>
