export const Bety = {
  namespaced: true,
  state: () => ({
    isHelpOpen: false,
  }),
  mutations: {
    setHelpOpen(state, value) {
      state.isHelpOpen = value;
    },
  },
  actions: {},
  getters: {},
};
