<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.91675 5.08333V2.5H11.5001V5.08333H8.91675ZM8.75008 1C8.0137 1 7.41675 1.59695 7.41675 2.33333V5.25C7.41675 5.98638 8.0137 6.58333 8.75008 6.58333H11.6667C12.4031 6.58333 13.0001 5.98638 13.0001 5.25V2.33333C13.0001 1.59695 12.4031 1 11.6667 1H8.75008ZM2.33333 3.33337C1.97971 3.33337 1.64057 3.47385 1.39052 3.7239C1.14048 3.97395 1 4.31309 1 4.66671V8.16671V11.6667C1 12.0203 1.14048 12.3595 1.39052 12.6095C1.64057 12.8596 1.97971 13 2.33333 13H5.83333H9.33333C9.68695 13 10.0261 12.8596 10.2761 12.6095C10.5262 12.3595 10.6667 12.0203 10.6667 11.6667V8.75004C10.6667 8.39642 10.5262 8.05728 10.2761 7.80723C10.0261 7.55718 9.68696 7.41671 9.33333 7.41671H6.58333V4.66671C6.58333 4.31308 6.44286 3.97395 6.19281 3.7239C5.94276 3.47385 5.60362 3.33337 5.25 3.33337H2.33333ZM2.5 11.5V8.91671H5.08333V11.5H2.5ZM2.5 7.41671H5.08333V4.83337H2.5V7.41671ZM6.58333 11.5V8.91671H9.16667V11.5H6.58333Z"
      fill="#2A7DE7"
    />
  </svg>
</template>
