<template>
  <svg
    width="78"
    height="59"
    viewBox="0 0 78 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_123_262)">
      <path
        d="M60.4402 42.3238C58.3938 39.1134 58.2203 35.6304 58.1943 32.1298C58.1857 30.6874 58.125 29.2449 58.0816 27.7937C62.5214 26.0961 66.8571 24.1963 71.5744 23.0177C72.537 22.7803 73.5689 22.5076 74.5054 23.1321C76.4391 26.7206 76.5258 30.617 77.3063 34.3903C77.5404 35.5073 77.6965 36.6331 77.8613 37.7589C78.3989 41.3123 77.3496 44.5314 74.6788 47.39C73.9331 48.1816 73.248 48.9995 73.3694 50.0726C72.8058 50.3804 72.2421 50.6971 71.6785 51.0049C66.8832 48.9995 62.9897 46.2202 60.4402 42.3062V42.3238Z"
        fill="#A2A2A2"
      />
      <path
        d="M74.5054 23.1408C69.6234 23.4575 65.7039 25.8235 61.2988 27.178C60.2408 27.5034 59.2003 27.7497 58.073 27.8024C55.3588 27.7849 52.9828 26.9405 50.8063 25.7355C46.2711 23.2288 41.7879 20.6605 37.2874 18.1186C36.6978 16.9312 37.8424 16.0957 38.354 15.2161C40.3224 11.8563 42.2822 8.49639 43.5135 4.89904C46.0109 3.31586 48.9245 2.99922 51.9682 3.05199C57.9342 3.94913 63.1805 6.22716 68.2273 8.81303C70.109 9.78053 70.1177 11.6891 70.8894 13.202C72.537 16.4123 73.6816 19.737 74.4968 23.1496L74.5054 23.1408Z"
        fill="#B3B3B3"
      />
      <path
        d="M51.9769 3.0432C49.1586 3.65889 46.3404 4.27457 43.5222 4.89025C43.7476 3.48298 43.1406 2.15486 42.9325 0.791565C45.9502 1.53918 48.9678 2.29559 51.9769 3.0432Z"
        fill="#A2A2A2"
      />
      <path
        d="M42.9325 0.791586C36.689 -0.712439 31.2954 -0.0703701 26.474 2.67382C26.3266 2.76177 26.1792 2.83213 26.0318 2.92009C25.737 3.06961 25.3294 3.25432 24.9132 3.35107C24.1154 3.53577 20.9156 4.53845 20.9156 4.53845C20.9156 4.53845 17.8459 6.27996 16.9788 7.52891C16.1203 8.77787 14.7675 10.836 14.7675 10.836C12.3655 13.2108 9.2091 15.0754 7.44879 17.89C8.6628 21.0915 10.3798 24.0996 13.0159 26.6942C14.1432 27.8025 15.2358 29.2273 17.5511 28.682C20.0398 28.8491 22.0429 27.8728 23.9766 26.8174C27.8354 24.7153 31.4775 22.3933 34.6686 19.6315C35.397 18.9982 35.9866 18.1187 37.2874 18.0923C39.9929 13.7297 42.9325 9.45512 44.1552 4.60002C44.4067 3.18395 44.0771 1.89102 42.9238 0.773995L42.9325 0.791586Z"
        fill="#A2A2A2"
      />
      <path
        d="M9.70339 21.1443C9.00967 20.0448 9.05303 18.5936 7.4488 17.9075C4.33574 21.5928 2.36732 25.6563 1.30939 30.0277L0 31.4174V35.1554L2.37599 37.2312L4.21434 39.3157C5.93997 41.6905 8.06448 43.863 10.241 46.0178C11.1689 45.7188 11.2296 45.0503 11.4637 44.3819C12.2788 42.0687 13.172 39.7731 14.0912 37.4862C15.2618 34.5661 16.9701 31.778 17.5598 28.6996C13.2067 27.3627 11.6198 24.1523 9.71206 21.1443H9.70339Z"
        fill="#868686"
      />
      <path
        d="M59.0441 35.1379C58.706 32.7631 59.3737 30.3355 58.5846 27.9783C58.3071 27.5298 57.7955 27.3099 57.2405 27.2483C53.8846 26.8349 51.2138 25.2957 48.5083 23.8093C44.8142 21.7775 41.3283 19.4819 37.3568 17.7844C36.0821 17.6789 35.2583 18.3209 34.4778 18.9718C29.4917 23.0881 24.2195 26.8701 17.2823 28.6996C14.7329 34.4254 11.8106 40.0457 10.241 46.0266C10.4665 46.4312 10.7093 46.8094 10.9694 47.1876L14.2646 52.4473L22.8667 56.3525L36.3856 57.9357L42.2822 59.0176L45.8201 58.7449C46.2277 58.5602 46.6526 58.3843 46.9474 58.0852C50.7889 54.0921 54.9686 50.3012 58.4545 46.0882C59.5298 44.7953 60.6484 43.432 60.6831 41.7608C59.946 39.5884 59.365 37.3895 59.0441 35.1554V35.1379Z"
        fill="#8A8A8A"
      />
      <path
        d="M60.4403 42.3238C63.614 45.71 67.6896 48.3311 71.6785 51.0225C71.1235 51.5326 70.6813 52.1835 69.9962 52.5265C62.5821 56.2294 54.6217 58.569 45.8115 58.7361C49.0199 54.3912 53.5464 50.8642 57.1191 46.7303C58.3504 45.3055 58.8794 43.564 60.4316 42.3238H60.4403Z"
        fill="#868686"
      />
      <g style="mix-blend-mode: multiply" opacity="0.3">
        <path
          d="M34.2474 15.839C34.7733 13.7492 32.9614 11.4756 30.2004 10.7607C27.4394 10.0459 24.7748 11.1604 24.2489 13.2502C23.723 15.34 25.5349 17.6136 28.2959 18.3284C31.0569 19.0433 33.7214 17.9288 34.2474 15.839Z"
          fill="#868686"
        />
      </g>
      <g style="mix-blend-mode: multiply" opacity="0.3">
        <path
          d="M12.7467 31.1287C13.2726 29.039 11.8181 26.8579 9.49793 26.2571C7.17776 25.6564 4.87055 26.8635 4.34462 28.9533C3.81869 31.043 5.2732 33.2241 7.59336 33.8248C9.91353 34.4256 12.2207 33.2185 12.7467 31.1287Z"
          fill="#868686"
        />
      </g>
      <g style="mix-blend-mode: multiply" opacity="0.3">
        <path
          d="M27.3378 43.411C30.3922 42.5314 32.3446 39.947 31.6984 37.6385C31.0523 35.3301 28.0523 34.1718 24.9978 35.0514C21.9434 35.931 19.991 38.5155 20.6372 40.8239C21.2833 43.1323 24.2833 44.2906 27.3378 43.411Z"
          fill="#868686"
        />
      </g>
      <g style="mix-blend-mode: multiply" opacity="0.3">
        <path
          d="M51.213 40.2175C53.475 39.5661 54.7224 36.9432 53.9991 34.3591C53.2758 31.7749 50.8556 30.2082 48.5936 30.8596C46.3315 31.511 45.0841 34.1339 45.8074 36.718C46.5308 39.3022 48.9509 40.8689 51.213 40.2175Z"
          fill="#868686"
        />
      </g>
      <g style="mix-blend-mode: multiply" opacity="0.3">
        <path
          d="M40.6151 52.4729C40.7892 51.3451 39.9178 50.2701 38.6687 50.0716C37.4196 49.8732 36.2658 50.6267 36.0917 51.7545C35.9176 52.8823 36.789 53.9573 38.0381 54.1557C39.2872 54.3541 40.441 53.6007 40.6151 52.4729Z"
          fill="#868686"
        />
      </g>
      <g style="mix-blend-mode: multiply" opacity="0.3">
        <path
          d="M49.2203 7.27066C49.3945 6.14287 48.523 5.06778 47.2739 4.86938C46.0248 4.67098 44.871 5.42439 44.6969 6.55219C44.5228 7.67998 45.3943 8.75507 46.6434 8.95348C47.8925 9.15188 49.0462 8.39846 49.2203 7.27066Z"
          fill="#868686"
        />
      </g>
      <g style="mix-blend-mode: multiply" opacity="0.3">
        <path
          d="M62.5545 19.6486C65.2542 18.8712 66.743 15.7407 65.8797 12.6566C65.0164 9.57248 62.128 7.70255 59.4283 8.48C56.7285 9.25745 55.2398 12.3879 56.1031 15.472C56.9664 18.5561 59.8548 20.4261 62.5545 19.6486Z"
          fill="#868686"
        />
      </g>
      <g style="mix-blend-mode: multiply" opacity="0.3">
        <path
          d="M66.6215 49.815C69.3212 49.0376 70.81 45.9072 69.9467 42.823C69.0834 39.7389 66.195 37.869 63.4953 38.6464C60.7956 39.4239 59.3068 42.5543 60.1701 45.6384C61.0334 48.7226 63.9218 50.5925 66.6215 49.815Z"
          fill="#868686"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_123_262">
        <rect width="78" height="59" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
