<template>
  <svg
    width="18"
    height="11"
    viewBox="0 0 18 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 7.07142V2C0 0.89543 0.895431 0 2 0H9.42856C13.75 0 17.2857 3.53571 17.2857 7.85714V8.42856C17.2857 8.98085 16.838 9.42856 16.2857 9.42856H15.5807C15.4191 9.88897 15.1182 10.2877 14.7198 10.5693C14.3213 10.8509 13.8451 11.0014 13.3571 11C12.8692 11.0014 12.3929 10.8509 11.9945 10.5693C11.596 10.2877 11.2951 9.88897 11.1336 9.42856H7.07142C6.5762 10.0872 5.88646 10.5736 5.09973 10.8188C4.313 11.064 3.46909 11.0558 2.68732 10.7952C1.90554 10.5346 1.22546 10.0348 0.743218 9.36662C0.260979 8.69839 0.000986709 7.89549 0 7.07142ZM3.92857 4.71428C2.62428 4.71428 1.57143 5.76714 1.57143 7.07142C1.57143 8.37571 2.62428 9.42856 3.92857 9.42856C5.23285 9.42856 6.28571 8.37571 6.28571 7.07142C6.28571 5.76714 5.23285 4.71428 3.92857 4.71428ZM11 3.92857V5.5H15.125C14.8657 4.92642 14.5357 4.4 14.1428 3.92857H11Z"
      fill="white"
    />
  </svg>
</template>
