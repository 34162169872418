<script setup>
import { ref, onMounted } from "vue";

//const sensorRangeElement = ref(null);
// const sensorOutput = 30;
const sensorValue = ref(50);
const motorValue = ref(100);

const emit = defineEmits(["sensorValue", "motorValue"]);

const props = defineProps({
  sensorOutput: {
    type: Number,
    required: true,
    default: 0,
  },
});
onMounted(() => {});

// 값을 변경할 때 emit을 사용하여 부모 컴포넌트로 전달합니다.
const updateSensorValue = (value) => {
  emit("sensorValue", value);
};

const updateMotorValue = (value) => {
  emit("motorValue", value);
};
</script>

<template>
  <div class="slider_wrap">
    <p>[센서 / 모터]</p>
    <div class="hr"></div>
    <div class="sensor_wrap">
      <p>센서 값 : {{ props.sensorOutput }}</p>
      <p>센서 민감도</p>
      <input
        class="slider"
        type="range"
        id="sensorRange"
        min="0"
        max="255"
        step="1"
        v-model="sensorValue"
        @change="updateSensorValue($event.target.value)"
      />
      <div class="sensorValue value">{{ sensorValue }}</div>
    </div>

    <div class="hr"></div>

    <div class="motor_wrap">
      <p>모터 출력</p>
      <input
        class="slider"
        type="range"
        id="motorRange"
        min="0"
        max="100"
        step="1"
        v-model="motorValue"
        @change="updateMotorValue($event.target.value)"
      />
      <div class="motorValue value">{{ motorValue }}</div>
    </div>
  </div>
</template>

<style scoped>
.slider_wrap {
  position: absolute;
  left: 30px;
  bottom: 30px;
  width: 250px;
  background-color: var(--color-bety-primary);
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  color: var(--color-bety-white);
  display: flex;
  flex-direction: column;
  gap: 15px;
  opacity: 0.5;
}
.slider_wrap:hover {
  opacity: 1;
}
.slidecontainer {
  width: 100%; /* Width of the outside container */
}
.slider {
  -webkit-appearance: none;
  width: 90%;
  height: 10px;
  border-radius: 5px;
  background: var(--color-bety-white);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--color-bety-complementary);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--color-bety-complementary);
  cursor: pointer;
}
.hr {
  width: 90%;
  border: 1px solid gray;
}

.sensor_wrap,
.motor_wrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
