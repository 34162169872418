<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_98_59)">
      <path
        d="M7.00004 1.16669V4.95835C7.00005 5.17587 7.08108 5.38558 7.22732 5.5466C7.37356 5.70761 7.57453 5.80839 7.79104 5.82927L7.87504 5.83335H11.6667V11.6667C11.6668 11.961 11.5556 12.2445 11.3555 12.4603C11.1554 12.6762 10.881 12.8084 10.5875 12.8304L10.5 12.8334H3.50004C3.2057 12.8334 2.92221 12.7223 2.70639 12.5221C2.49057 12.322 2.35837 12.0477 2.33629 11.7542L2.33337 11.6667V2.33335C2.33328 2.03902 2.44444 1.75552 2.64458 1.5397C2.84472 1.32388 3.11903 1.19168 3.41254 1.1696L3.50004 1.16669H7.00004ZM8.16671 1.19177C8.35534 1.23182 8.53111 1.318 8.67829 1.4426L8.75004 1.50852L11.3249 4.08335C11.4616 4.21992 11.5622 4.38829 11.6177 4.57335L11.641 4.66669H8.16671V1.19177Z"
        fill="#5F5E5C"
      />
    </g>
    <defs>
      <clipPath id="clip0_98_59">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
