<template>
  <svg
    width="80"
    height="72"
    viewBox="0 0 80 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="margin-right: -10px"
  >
    <g clip-path="url(#clip0_854_970)">
      <mask
        id="mask0_854_970"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="80"
        height="72"
      >
        <path d="M79.9718 0H0V72H79.9718V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_854_970)">
        <path
          opacity="0.99"
          d="M79.5023 31.3678V40.6331C79.5023 44.5552 76.3538 47.7383 72.4534 47.7383H72.3689C71.5888 47.7383 70.9591 48.3731 70.9591 49.1594V60.1583C70.9591 62.3183 70.3575 64.3362 69.3237 66.051C69.1357 66.3636 68.9384 66.6667 68.7128 66.9604C68.4967 67.2542 68.2617 67.5383 68.0173 67.8036C65.9591 70.0962 62.9797 71.5267 59.6714 71.5267H11.7485C8.63763 71.5267 5.81808 70.2572 3.77861 68.2015C3.52485 67.9457 3.28049 67.671 3.04552 67.3962C2.585 66.8372 2.18087 66.231 1.83311 65.5772C0.959057 63.9667 0.470336 62.1194 0.470336 60.1583V49.1688C0.470336 48.3826 1.10004 47.7478 1.88011 47.7478H1.95529C5.84628 47.7478 9.00417 44.5647 9.00417 40.6426V31.3772C9.00417 27.4552 5.85568 24.272 1.95529 24.272H1.87071C1.09064 24.272 0.460938 23.6372 0.460938 22.851V11.852C0.460938 9.891 0.949659 8.03412 1.82372 6.42359C2.17147 5.77939 2.5756 5.17307 3.03612 4.61412C3.27109 4.32991 3.51545 4.06464 3.76921 3.80886C5.81808 1.74359 8.63763 0.474121 11.7485 0.474121H59.6809C62.9892 0.474121 65.9685 1.90464 68.0267 4.19728C68.2711 4.47202 68.4967 4.74675 68.7222 5.04044C69.1546 5.61834 69.5399 6.25307 69.85 6.91623C70.0098 7.23834 70.1508 7.57939 70.273 7.92991C70.7147 9.15202 70.9591 10.4688 70.9591 11.8426V22.8415C70.9591 23.6278 71.5888 24.2626 72.3689 24.2626H72.4534C76.3444 24.2626 79.5023 27.4457 79.5023 31.3678Z"
          fill="#E03C3C"
          stroke="#FFFCFC"
          stroke-miterlimit="10"
        />
        <path
          d="M12.3586 67.9736C10.1499 67.9736 8.07285 67.1021 6.5127 65.5294C6.32473 65.3494 6.14616 65.141 5.95819 64.9136C5.63864 64.5157 5.33789 64.0705 5.08413 63.5968C4.42623 62.3841 4.08789 61.0105 4.08789 59.6178V50.1631C8.80594 49.5189 12.4526 45.4452 12.4526 40.5189V31.4526C12.4526 26.5263 8.80594 22.4526 4.08789 21.8084V12.3536C4.08789 10.961 4.43563 9.57785 5.08413 8.37469C5.33789 7.91048 5.63864 7.45574 5.97699 7.0389C6.14616 6.83995 6.33413 6.63153 6.5127 6.45153C8.07285 4.8789 10.1499 4.00732 12.3586 4.00732H59.2571C61.5879 4.00732 63.8153 5.00206 65.3754 6.73574C65.554 6.93469 65.7233 7.14311 65.9018 7.37048C66.2214 7.79679 66.4939 8.25153 66.7101 8.71574C66.8416 8.98101 66.9356 9.20837 67.0109 9.42626L67.1612 9.86206C67.4055 10.6673 67.5372 11.501 67.5372 12.3441V23.0589C67.5372 25.3894 69.4074 27.2652 71.7194 27.2652C74.0315 27.2652 75.9018 29.1505 75.9018 31.481V40.4621C75.9018 42.7926 74.0315 44.6778 71.7194 44.6778C69.4074 44.6778 67.5372 46.5631 67.5372 48.8936V59.6084C67.5372 61.1336 67.1236 62.6305 66.3341 63.9284C66.1837 64.1747 66.0428 64.3926 65.8924 64.5821C65.7138 64.8189 65.554 65.0178 65.3943 65.1978C63.8154 66.96 61.5879 67.9547 59.2476 67.9547H12.3586V67.9736Z"
          fill="#F35050"
        />
        <path
          d="M29.7458 48.373H16.5128C15.4228 48.373 14.5391 49.2637 14.5391 50.3626V53.6972C14.5391 54.796 15.4228 55.6867 16.5128 55.6867H29.7458C30.8359 55.6867 31.7195 54.796 31.7195 53.6972V50.3626C31.7195 49.2637 30.8359 48.373 29.7458 48.373Z"
          fill="white"
          stroke="#DF3D3E"
          stroke-width="2"
        />
        <path
          d="M60.2536 48.373H47.0206C45.9306 48.373 45.0469 49.2637 45.0469 50.3626V53.6972C45.0469 54.796 45.9306 55.6867 47.0206 55.6867H60.2536C61.3437 55.6867 62.2274 54.796 62.2274 53.6972V50.3626C62.2274 49.2637 61.3437 48.373 60.2536 48.373Z"
          fill="white"
          stroke="#DF3D3E"
          stroke-width="2"
        />
        <path
          d="M24.0039 44.5266C22.3779 44.5266 21.0527 43.1907 21.0527 41.5518V19.9518C21.0527 18.3128 22.3779 16.9771 24.0039 16.9771H52.1711C53.7313 16.9771 55.0095 18.256 55.0095 19.8286V28.4686L55.7613 27.6823C56.2688 27.1139 57.0302 26.7728 57.8196 26.7728C58.5621 26.7728 59.267 27.0665 59.8027 27.6065L60.6392 28.4496C61.1749 28.9991 61.4756 29.7191 61.4756 30.4866C61.4756 31.2539 61.1749 31.9739 60.6392 32.5139L53.6091 39.6096C53.0827 40.1496 52.3591 40.4528 51.5978 40.4528C50.8365 40.4528 50.1692 40.1686 49.6429 39.6665L42.6599 32.6275C41.5885 31.5475 41.4944 29.7666 42.4343 28.5728L42.5001 28.4971L43.4211 27.5591C43.9474 27.0286 44.6429 26.735 45.3666 26.735C46.1937 26.735 46.9738 27.1139 47.5001 27.7866L48.1674 28.4875V23.7507H27.7726V41.5707C27.7726 43.2096 26.4474 44.5455 24.8215 44.5455H24.0226L24.0039 44.5266Z"
          fill="white"
        />
        <path
          d="M52.1626 17.9243C53.2058 17.9243 54.0611 18.7769 54.0611 19.8284V30.8369L56.4294 28.3358C56.7772 27.9473 57.2847 27.72 57.8111 27.72C58.3091 27.72 58.779 27.9189 59.1362 28.2789L59.9727 29.1127C60.3298 29.4821 60.5366 29.9653 60.5272 30.4769C60.5272 30.9884 60.3298 31.4716 59.9632 31.8316L52.9238 38.9273C52.5667 39.2969 52.0873 39.4958 51.5798 39.4958C51.0723 39.4958 50.6306 39.3064 50.2734 38.9653L43.3091 31.9453C42.5854 31.2158 42.5103 29.9653 43.1588 29.1505L44.0798 28.2127C44.4275 27.8621 44.8787 27.6727 45.358 27.6727C45.9031 27.6727 46.4107 27.9284 46.7584 28.3643L49.0987 30.8369V22.7843H26.8242V41.5516C26.8242 42.6695 25.9219 43.5789 24.8129 43.5789H24.014C22.905 43.5789 22.0028 42.6695 22.0028 41.5516V19.9516C22.0028 18.8337 22.905 17.9243 24.014 17.9243H52.1813M52.1813 16.0295H24.014C21.8618 16.0295 20.123 17.7916 20.123 19.9516V41.5516C20.123 43.7211 21.8711 45.4737 24.014 45.4737H24.8129C26.9651 45.4737 28.7039 43.7116 28.7039 41.5516V24.6789H47.219V26.2895C46.6644 25.9579 46.0253 25.7779 45.358 25.7779C44.3806 25.7779 43.4595 26.1664 42.7547 26.8769L41.8242 27.8148L41.749 27.8905L41.6832 27.9664C40.4426 29.5295 40.5648 31.86 41.9746 33.2811L48.9389 40.3011C49.6719 41.0116 50.6024 41.3811 51.5704 41.3811C52.5385 41.3811 53.5441 40.9737 54.2584 40.2443L61.279 33.1579C61.9933 32.4473 62.3881 31.4905 62.3881 30.4673C62.3881 29.4443 61.9933 28.4873 61.279 27.7579L60.4426 26.9148C59.7283 26.1948 58.7885 25.8064 57.7922 25.8064C57.1344 25.8064 56.4858 25.9864 55.9219 26.3084V19.8189C55.9219 17.7253 54.2302 16.02 52.1437 16.02L52.1813 16.0295Z"
          fill="#DF3D3E"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_854_970">
        <rect width="80" height="72" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
