<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.70379 12.25H8.29613C10.1167 12.25 11.0273 12.25 11.6812 11.8213C11.9634 11.6363 12.2064 11.3977 12.3964 11.1189C12.8333 10.4773 12.8333 9.58303 12.8333 7.79569C12.8333 6.00778 12.8333 5.11411 12.3964 4.47244C12.2064 4.19368 11.9634 3.95503 11.6812 3.77011C11.2612 3.49419 10.735 3.39561 9.92946 3.36061C9.54504 3.36061 9.21429 3.07478 9.13904 2.70436C9.08154 2.43303 8.93212 2.18988 8.71604 2.016C8.49996 1.84212 8.23047 1.74817 7.95313 1.75003H6.04679C5.47046 1.75003 4.97404 2.14961 4.86088 2.70436C4.78563 3.07478 4.45488 3.36061 4.07046 3.36061C3.26546 3.39561 2.73929 3.49478 2.31871 3.77011C2.03674 3.95506 1.79393 4.19371 1.60413 4.47244C1.16663 5.11411 1.16663 6.00778 1.16663 7.79569C1.16663 9.58303 1.16663 10.4767 1.60354 11.1189C1.79254 11.3966 2.03521 11.6352 2.31871 11.8213C2.97263 12.25 3.88321 12.25 5.70379 12.25ZM6.99996 5.40928C5.65771 5.40928 4.56921 6.47736 4.56921 7.79511C4.56921 9.11344 5.65771 10.1821 6.99996 10.1821C8.34221 10.1821 9.43071 9.11344 9.43071 7.79569C9.43071 6.47736 8.34221 5.40928 6.99996 5.40928ZM6.99996 6.36361C6.19496 6.36361 5.54163 7.00469 5.54163 7.79569C5.54163 8.58611 6.19496 9.22719 6.99996 9.22719C7.80496 9.22719 8.45829 8.58611 8.45829 7.79569C8.45829 7.00469 7.80496 6.36361 6.99996 6.36361ZM9.75446 5.88644C9.75446 5.62278 9.97204 5.40928 10.241 5.40928H10.8885C11.1568 5.40928 11.375 5.62278 11.375 5.88644C11.3737 6.01413 11.3219 6.13611 11.2307 6.22558C11.1396 6.31505 11.0167 6.3647 10.889 6.36361H10.241C10.1777 6.36423 10.1149 6.35237 10.0562 6.32872C9.99752 6.30508 9.94406 6.2701 9.89888 6.22579C9.8537 6.18148 9.8177 6.12871 9.79292 6.07048C9.76814 6.01226 9.75507 5.94972 9.75446 5.88644Z"
      fill="#FBBF17"
    />
  </svg>
</template>
