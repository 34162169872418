export const Camera = {
  namespaced: true,
  state: () => ({
    isUsable: false,
    shutterEnable: true,
    actionPresent: "",
    facePresent: "",
    handPresent: "",
    poseLandmark: [],
    handLandmark: [],
    faceLandmark: [],
    handStatus: {},
    faceScore: {},
    cameraWidth: undefined,
    cameraHeight: undefined,
  }),
  mutations: {
    setCameraSize(state, value) {
      const { width, height } = value;
      state.cameraWidth = width;
      state.cameraHeight = height;
    },
    setIsUsable(state, value) {
      state.isUsable = value;
    },
    setShutterEnable(state, value) {
      state.shutterEnable = value;
    },
    getShutterEnable(state) {
      return state.shutterEnable;
    },
    setActionPresent(state, value) {
      state.actionPresent = value;
    },
    setFacePresent(state, value) {
      state.facePresent = value;
    },
    setHandPresent(state, value) {
      state.handPresent = value;
    },
    setPoseLandmark(state, value) {
      state.poseLandmark = value;
    },
    setHandLandmark(state, value) {
      state.handLandmark = value;
    },
    setFaceLandmark(state, value) {
      state.faceLandmark = value;
    },
    setFaceScore(state, value) {
      state.faceScore = value;
    },
    setHandStatus(state, value) {
      state.handStatus = value;
    },
  },
  actions: {},
  getters: {},
};
