<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
      fill="#E9E9E9"
    />
    <path
      d="M30 55C43.8071 55 55 43.8071 55 30C55 16.1929 43.8071 5 30 5C16.1929 5 5 16.1929 5 30C5 43.8071 16.1929 55 30 55Z"
      fill="#D9D9D9"
    />
    <path
      d="M28.213 25.25C28.213 25.6589 28.0505 26.051 27.7614 26.3401C27.4723 26.6292 27.0802 26.7916 26.6713 26.7916C26.2624 26.7916 25.8703 26.6292 25.5812 26.3401C25.2921 26.051 25.1296 25.6589 25.1296 25.25C25.1296 24.8411 25.2921 24.449 25.5812 24.1599C25.8703 23.8707 26.2624 23.7083 26.6713 23.7083C27.0802 23.7083 27.4723 23.8707 27.7614 24.1599C28.0505 24.449 28.213 24.8411 28.213 25.25ZM32.8303 26.7916C33.2391 26.7916 33.6313 26.6292 33.9204 26.3401C34.2095 26.051 34.3719 25.6589 34.3719 25.25C34.3719 24.8411 34.2095 24.449 33.9204 24.1599C33.6313 23.8707 33.2391 23.7083 32.8303 23.7083C32.4214 23.7083 32.0293 23.8707 31.7401 24.1599C31.451 24.449 31.2886 24.8411 31.2886 25.25C31.2886 25.6589 31.451 26.051 31.7401 26.3401C32.0293 26.6292 32.4214 26.7916 32.8303 26.7916Z"
      fill="#959595"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.5833 43.75V26.7917C40.5833 25.3745 40.3042 23.9712 39.7619 22.6619C39.2195 21.3526 38.4246 20.1629 37.4225 19.1608C36.4204 18.1587 35.2308 17.3638 33.9215 16.8215C32.6122 16.2791 31.2088 16 29.7917 16C28.3745 16 26.9712 16.2791 25.6619 16.8215C24.3526 17.3638 23.1629 18.1587 22.1608 19.1608C21.1587 20.1629 20.3638 21.3526 19.8215 22.6619C19.2791 23.9712 19 25.3745 19 26.7917V43.75H23.3598L25.5413 41.5701L27.7212 43.75H31.8621L34.042 41.5701L36.2235 43.75H40.5833ZM37.5 26.7917C37.5 24.7473 36.6879 22.7866 35.2423 21.3411C33.7967 19.8955 31.836 19.0833 29.7917 19.0833C27.7473 19.0833 25.7866 19.8955 24.3411 21.3411C22.8955 22.7866 22.0833 24.7473 22.0833 26.7917V40.6667L25.5413 37.2087L29.7917 41.4606L34.042 37.2087L37.5 40.6667V26.7917Z"
      fill="#959595"
    />
    <path
      d="M43.7961 11.5456L41.6569 13.6848L39.5177 11.5456C39.2494 11.2774 38.8138 11.2774 38.5456 11.5456C38.2774 11.8138 38.2774 12.2494 38.5456 12.5177L40.6848 14.6569L38.5456 16.7961C38.2774 17.0643 38.2774 17.4999 38.5456 17.7681C38.8138 18.0364 39.2494 18.0364 39.5177 17.7681L41.6569 15.6289L43.7961 17.7681C44.0643 18.0364 44.4999 18.0364 44.7681 17.7681C45.0364 17.4999 45.0363 17.0643 44.7681 16.7961L42.6289 14.6569L44.7681 12.5177C45.0364 12.2494 45.0363 11.8138 44.7681 11.5456C44.4999 11.2774 44.0643 11.2774 43.7961 11.5456Z"
      fill="#959595"
      stroke="#959595"
      stroke-width="0.8"
    />
  </svg>
</template>
