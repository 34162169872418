<script setup>
import { ref, watch, computed, onMounted } from "vue";
import { useStore } from "vuex";
const store = useStore();

let port = undefined;
// let handPresent = computed(() => store.state.Camera.handPresent);
const handLandmark = computed(() => store.state.Camera.handLandmark);
const faceLandmark = computed(() => store.state.Camera.faceLandmark);
const faceScore = computed(() => store.state.Camera.faceScore);
// let cameraWidth = computed(() => store.state.Camera.cameraWidth);
// let cameraHeight = computed(() => store.state.Camera.cameraHeight);
const serialConnected = ref(false);
const robotValid = ref(false);
const sensorIrLeft = ref(0);
const sensorIrRight = ref(0);
const sensorColor = ref(0);

// const props = defineProps({
//   type: {
//     type: String,
//     required: true,
//     default: "compute_robot_hand",
//   },
// });

onMounted(() => {
  if (!("serial" in navigator)) {
    alert("Serial Not Supported");
    return;
  }
});

const connectSerial = async () => {
  const filters = [
    { usbVendorId: 0x10c4, usbProductId: 0xea60 },
    // { usbVendorId: 0x0d28, usbProductId: 0x0204 },
  ];

  try {
    port = await navigator.serial.requestPort({ filters });
    await port.open({
      baudRate: 500000,
      dataBits: 8,
      stopBits: 1,
      parity: "none",
      bufferSize: 255, //byte
      flowControl: "none",
    });
    serialConnected.value = true;
    robotValid.value = true;

    // const reader = port.readable.getReader();
    // while (true) {
    //   const { value, done } = await reader.read();
    //   if (done) break;
    //   receivedData.value = new TextDecoder().decode(value);
    //   console.log("Received Data:", receivedData.value);
    // }
  } catch (error) {
    console.error("Error connecting to serial:", error);
  }

  const data = new Uint8Array([0xaa, 0xaa, 0xcc, 0x02, 0x02, 0x03, 0x05]);
  sendCommand(data);
  console.log("aa");
  while (port.readable) {
    console.log("aa");
    const reader = port.readable.getReader();
    let done = false;
    let value = null;
    try {
      while (!done) {
        const r = await reader.read();
        value = r.value;
        done = r.done;
        if (value.length == 43) {
          // console.log("Received data:", value);
          // console.log(value[18], value[20], value[29]);
          console.log(value[31], value[33], value[35]);
          sensorIrLeft.value = value[18];
          sensorIrRight.value = value[20];
          sensorColor.value = value[29];
          switch (value[29]) {
            case 40:
              sensorColor.value = "White";
              break;
            case 80:
              sensorColor.value = "Red";
              break;
            case 120:
              sensorColor.value = "Yellow";
              break;
            case 160:
              sensorColor.value = "Green";
              break;
            case 200:
              sensorColor.value = "Blue";
              break;
            default:
              sensorColor.value = "Undefined";
              break;
          }
        }
      }
    } finally {
      console.log("Stream complete");
      reader.releaseLock();
    }
  }
};

const toggleRobot = async () => {
  if (!serialConnected.value) return;
  robotValid.value = !robotValid.value;
  const code = makeCommand("both", 0);
  await sendCommand(code);
};

const sendCommand = async (serialData) => {
  const writer = port.writable.getWriter();
  await writer.write(serialData);
  writer.releaseLock();
};

const calibrateIr = async (color) => {
  const cmd = color == "white" ? 0x06 : 0x07;
  const parity = color == "white" ? 0x29 : 0x2a;
  const data = new Uint8Array([
    0xaa,
    0xaa,
    0xcc,
    0x05,
    0x01,
    0x02,
    0x20,
    cmd,
    0x00,
    parity,
  ]);
  await sendCommand(data);
};

const makeCommand = (direction = "both", speed = 0) => {
  let directionCode = 0x00;
  if (speed < 0) directionCode = 0x01;

  let speedCode = Math.abs(speed);
  speedCode = speedCode <= 100 ? speedCode : 100;

  let motorCode = 0x00;
  if (direction === "left") motorCode = 0x01;
  if (direction === "right") motorCode = 0x02;

  const ackCode = 0x11;
  const dataStart = new Uint8Array([0xaa, 0xaa, 0xcc]);
  const dataPayload = new Uint8Array([
    0x01,
    0x10,
    ackCode,
    0x00,
    0x00,
    0x03,
    speedCode,
    0x00,
    motorCode,
    0x00,
    directionCode,
    0x00,
  ]);
  const dataLength = new Uint8Array([dataPayload.length]);
  const dataParity = new Uint8Array([
    dataPayload.reduce((acc, cur) => acc + cur, 0),
  ]);
  const data = new Uint8Array([
    ...dataStart,
    ...dataLength,
    ...dataPayload,
    ...dataParity,
  ]);

  return data;
};

console.log(sendCommand);
console.log(makeCommand);

watch(handLandmark, async (newValue) => {
  if (!serialConnected.value) return;
  if (!robotValid.value) return;
  let handX = 1 - newValue[8].x;
  let handY = 1 - newValue[8].y;
  handX = handX >= 0 ? handX : 0;
  handX = handX <= 1 ? handX : 1;
  handX -= 0.5;
  handX *= 2;
  if (handX >= -0.1 && handX <= 0.1) handX = 0;
  handY = handY >= 0 ? handY : 0;
  handY = handY <= 1 ? handY : 1;
  handY -= 0.5;
  handY *= 2;
  if (handY >= -0.1 && handY <= 0.1) handY = 0;

  let leftSpeed = parseInt(handY * 100);
  let rightSpeed = parseInt(handY * 100);
  if (handX > 0) {
    leftSpeed = parseInt(handY * 100);
    rightSpeed = parseInt(handY * (1 - handX) * 100);
  } else if (handX < 0) {
    leftSpeed = parseInt(handY * (1 + handX) * 100);
    rightSpeed = parseInt(handY * 100);
  }
  console.log(leftSpeed, rightSpeed);
  const leftCode = makeCommand("left", leftSpeed);
  const rightCode = makeCommand("right", rightSpeed);
  await sendCommand(leftCode);
  await sendCommand(rightCode);
});

watch(faceLandmark, async (newValue) => {
  if (!serialConnected.value) return;
  if (!robotValid.value) return;

  let faceX = 1 - newValue[1].x;
  faceX -= 0.5;
  faceX *= 4;
  if (faceX >= -0.1 && faceX <= 0.1) faceX = 0;
  faceX = faceX >= -1 ? faceX : -1;
  faceX = faceX <= 1 ? faceX : 1;

  console.log(faceScore.value.mouthOpen, faceScore.value.mouthSmile);
  const mouthOpen = faceScore.value.mouthOpen;
  const mouthSmile = faceScore.value.mouthSmile;
  let score = mouthOpen > mouthSmile ? mouthOpen : mouthSmile;
  score = score < 0.1 ? 0 : score;
  score *= 10 / 8;
  score = score >= 1.0 ? 1 : score;

  let leftSpeed = parseInt(score * 100);
  let rightSpeed = parseInt(score * 100);
  if (faceX > 0) {
    leftSpeed = parseInt(score * 100);
    rightSpeed = parseInt(score * (1 - faceX) * 100);
  } else if (faceX < 0) {
    leftSpeed = parseInt(score * (1 + faceX) * 100);
    rightSpeed = parseInt(score * 100);
  }
  if (mouthSmile > mouthOpen) {
    leftSpeed = -leftSpeed;
    rightSpeed = -rightSpeed;
  }
  const leftCode = makeCommand("left", leftSpeed);
  const rightCode = makeCommand("right", rightSpeed);
  await sendCommand(leftCode);
  await sendCommand(rightCode);
});

// const btnClick = async (direction, speed) => {
//   if (!port) return;
//   const dataCode = makeCommand(direction, speed);
//   await sendCommand(dataCode);
// };

// const btnLED = async (ledValue) => {
//   const dataStart = new Uint8Array([0xaa, 0xaa, 0xcc]);
//   const dataPayload = new Uint8Array([
//     0x01,
//     0x10,
//     0x11,
//     0x00,
//     0x00,
//     0x01,
//     ledValue,
//     0x00,
//   ]);
//   const dataLength = new Uint8Array([dataPayload.length]);
//   const dataParity = new Uint8Array([
//     dataPayload.reduce((acc, cur) => acc + cur, 0),
//   ]);
//   const data = new Uint8Array([
//     ...dataStart,
//     ...dataLength,
//     ...dataPayload,
//     ...dataParity,
//   ]);

//   await sendCommand(data);
// };

// const btnBuzzer = async (buzz) => {
//   const dataStart = new Uint8Array([0xaa, 0xaa, 0xcc]);
//   const dataPayload = new Uint8Array([
//     0x01,
//     0x10,
//     0x11,
//     0x00,
//     0x00,
//     0x02,
//     buzz,
//     0x00,
//   ]);
//   const dataLength = new Uint8Array([dataPayload.length]);
//   const dataParity = new Uint8Array([
//     dataPayload.reduce((acc, cur) => acc + cur, 0),
//   ]);
//   const data = new Uint8Array([
//     ...dataStart,
//     ...dataLength,
//     ...dataPayload,
//     ...dataParity,
//   ]);

//   await sendCommand(data);
// };
</script>

<template>
  <div class="wrap">
    <div class="connect_robot" @click="connectSerial">로봇 연결</div>
    <div class="toggle_robot" @click="toggleRobot" v-if="serialConnected">
      {{ robotValid ? "멈춤" : "재개" }}
    </div>
    <div
      class="calib calib_white"
      @click="calibrateIr('white')"
      v-if="serialConnected"
    >
      흰색 조정
    </div>
    <div
      class="calib calib_black"
      @click="calibrateIr('black')"
      v-if="serialConnected"
    >
      검은색 조정
    </div>
    <div class="robot_sensor" v-if="serialConnected">
      [센서 값]
      <div class="sensor_ir_left">적외선(L) : {{ sensorIrLeft }}</div>
      <div class="sensor_ir_right">적외선(R) : {{ sensorIrRight }}</div>
      <div class="sensor_color">색깔 : {{ sensorColor }}</div>
    </div>
  </div>
</template>

<style scoped>
.wrap {
  position: absolute;
  width: 100%;
  height: 100%;
}
.toggle_robot {
  position: absolute;
  right: 170px;
  top: 20px;
  width: 100px;
  height: 50px;
  border: 0;
  color: var(--color-bety-white);
  background-color: var(--color-bety-error);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calib {
  position: absolute;
  width: 100px;
  height: 50px;
  border: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calib_white {
  right: 50px;
  top: 90px;
  color: var(--color-bety-black);
  background-color: var(--color-bety-white);
}

.calib_black {
  right: 50px;
  top: 160px;
  color: var(--color-bety-white);
  background-color: var(--color-bety-black);
}

.connect_robot {
  position: absolute;
  right: 50px;
  top: 20px;
  width: 100px;
  height: 50px;
  border: 0;
  background-color: var(--color-bety-complementary);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.robot_sensor {
  position: absolute;
  top: 70px;
  left: 50px;
  width: 150px;
  height: 150px;
  border: 0;
  padding: 10px;
  font-size: 18px;
  color: var(--color-bety-white);
  background-color: var(--color-bety-tertiary);
  border-radius: 10px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
}
</style>
