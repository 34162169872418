<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.68005 10.5286C2.4828 6.97652 4.19805 3.6952 6.65647 1.0086C6.70018 0.96104 6.75329 0.923075 6.81243 0.897106C6.87157 0.871136 6.93546 0.857727 7.00005 0.857727C7.06465 0.857727 7.12854 0.871136 7.18768 0.897106C7.24682 0.923075 7.29993 0.96104 7.34364 1.0086C9.80203 3.69522 11.5173 6.97653 12.3201 10.5286C11.0545 10.795 9.77062 10.9656 8.47939 11.039L7.2608 13.475C7.23656 13.5234 7.19932 13.5641 7.15327 13.5925C7.10722 13.6209 7.05417 13.636 7.00005 13.636C6.94594 13.636 6.89288 13.6209 6.84683 13.5925C6.80078 13.5641 6.76355 13.5234 6.7393 13.475L5.52072 11.0384C4.22947 10.9653 2.94559 10.7952 1.68005 10.5286ZM7.00005 8.7471C7.46418 8.7471 7.9093 8.56272 8.23749 8.23453C8.56568 7.90634 8.75005 7.46123 8.75005 6.9971C8.75005 6.53297 8.56568 6.08785 8.23749 5.75966C7.9093 5.43147 7.46418 5.2471 7.00005 5.2471C6.53592 5.2471 6.09081 5.43147 5.76262 5.75966C5.43443 6.08785 5.25005 6.53297 5.25005 6.9971C5.25005 7.46123 5.43443 7.90634 5.76262 8.23453C6.09081 8.56272 6.53592 8.7471 7.00005 8.7471Z"
      fill="#8267D1"
    />
  </svg>
</template>
