<script setup>
const props = defineProps({
  type: String, //"3x3","4x4,","5x5","7x5"
});
</script>

<template>
  <svg
    width="240"
    height="240"
    viewBox="0 0 240 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-if="props.type === '3x3'"
  >
    <rect
      x="1"
      y="1"
      width="240"
      height="240"
      rx="11"
      fill="white"
      stroke="#312D71"
      stroke-width="2"
    />
    <path
      d="M2 82L242 82"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M2 162L242 162"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M82 2V242"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M162 2V242"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
  </svg>
  <svg
    width="324"
    height="324"
    viewBox="0 0 324 324"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-if="props.type === '4x4'"
  >
    <rect
      x="1"
      y="1"
      width="322"
      height="322"
      rx="11"
      fill="white"
      stroke="#312D71"
      stroke-width="2"
    />
    <path
      d="M2 82L322 82"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M2 162L322 162"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M2 242L322 242"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M82 2V322"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M162 2V322"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M242 2V322"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
  </svg>
  <svg
    width="404"
    height="404"
    viewBox="0 0 404 404"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-if="props.type === '5x5'"
  >
    <rect
      x="1"
      y="1"
      width="402"
      height="402"
      rx="11"
      fill="white"
      stroke="#312D71"
      stroke-width="2"
    />
    <path
      d="M402 82L2 82"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M402 162L2 162"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M402 242L2 242"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M402 322L2 322"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M82 2V402"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M162 2V402"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M242 2V402"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M322 2V402"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
  </svg>
  <svg
    width="564"
    height="404"
    viewBox="0 0 564 404"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-if="props.type === '7x5'"
  >
    <rect
      x="1"
      y="1"
      width="562"
      height="402"
      rx="11"
      fill="white"
      stroke="#312D71"
      stroke-width="2"
    />
    <path
      d="M2 82L562 82"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M2 162L562 162"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M2 242L562 242"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M2 322L562 322"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M82 2V402"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M162 2V402"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M402 2V402"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M482 2V402"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M242 2V402"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
    <path
      d="M322 2V402"
      stroke="#312D71"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="8 8"
    />
  </svg>
</template>
