<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="30" cy="30" r="30" fill="#E9E9E9" />
    <circle cx="30" cy="30" r="25" fill="#D9D9D9" />
    <path
      d="M28.213 25.2499C28.213 25.6588 28.0505 26.0509 27.7614 26.34C27.4723 26.6292 27.0802 26.7916 26.6713 26.7916C26.2624 26.7916 25.8703 26.6292 25.5812 26.34C25.2921 26.0509 25.1296 25.6588 25.1296 25.2499C25.1296 24.841 25.2921 24.4489 25.5812 24.1598C25.8703 23.8707 26.2624 23.7083 26.6713 23.7083C27.0802 23.7083 27.4723 23.8707 27.7614 24.1598C28.0505 24.4489 28.213 24.841 28.213 25.2499ZM32.8303 26.7916C33.2391 26.7916 33.6313 26.6292 33.9204 26.34C34.2095 26.0509 34.3719 25.6588 34.3719 25.2499C34.3719 24.841 34.2095 24.4489 33.9204 24.1598C33.6313 23.8707 33.2391 23.7083 32.8303 23.7083C32.4214 23.7083 32.0293 23.8707 31.7401 24.1598C31.451 24.4489 31.2886 24.841 31.2886 25.2499C31.2886 25.6588 31.451 26.0509 31.7401 26.34C32.0293 26.6292 32.4214 26.7916 32.8303 26.7916Z"
      fill="#959595"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.5833 43.75V26.7917C40.5833 25.3745 40.3042 23.9712 39.7619 22.6619C39.2195 21.3526 38.4246 20.1629 37.4225 19.1608C36.4204 18.1587 35.2308 17.3638 33.9215 16.8215C32.6122 16.2791 31.2088 16 29.7917 16C28.3745 16 26.9712 16.2791 25.6619 16.8215C24.3526 17.3638 23.1629 18.1587 22.1608 19.1608C21.1587 20.1629 20.3638 21.3526 19.8215 22.6619C19.2791 23.9712 19 25.3745 19 26.7917V43.75H23.3598L25.5413 41.5701L27.7212 43.75H31.8621L34.042 41.5701L36.2235 43.75H40.5833ZM37.5 26.7917C37.5 24.7473 36.6879 22.7866 35.2423 21.3411C33.7967 19.8955 31.836 19.0833 29.7917 19.0833C27.7473 19.0833 25.7866 19.8955 24.3411 21.3411C22.8955 22.7866 22.0833 24.7473 22.0833 26.7917V40.6667L25.5413 37.2087L29.7917 41.4606L34.042 37.2087L37.5 40.6667V26.7917Z"
      fill="#959595"
    />
    <path
      d="M44.7126 14.3126H41.6874V11.2874C41.6874 10.908 41.3793 10.6 41 10.6C40.6207 10.6 40.3126 10.908 40.3126 11.2874V14.3126H37.2874C36.908 14.3126 36.6 14.6207 36.6 15C36.6 15.3793 36.908 15.6874 37.2874 15.6874H40.3126V18.7126C40.3126 19.092 40.6207 19.4 41 19.4C41.3793 19.4 41.6874 19.092 41.6874 18.7126V15.6874H44.7126C45.092 15.6874 45.4 15.3793 45.4 15C45.4 14.6207 45.092 14.3126 44.7126 14.3126Z"
      fill="#959595"
      stroke="#959595"
      stroke-width="0.8"
    />
  </svg>
</template>
