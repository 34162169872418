<template>
  <svg
    width="33"
    height="25"
    viewBox="0 0 33 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.5301 8.15361C32.0605 6.4022 29.7164 5.33814 26.3368 5.03999C24.0587 1.98172 20.4148 0 16.3086 0C9.89386 0 4.61285 4.83111 3.89336 11.0512C1.1136 13.0008 -0.384295 15.095 0.0852475 16.8464C0.55479 18.5978 2.89893 19.6619 6.27856 19.96C8.55665 23.0183 12.2005 25 16.3086 25C22.7215 25 28.0043 20.1689 28.722 13.9488C31.5017 11.9992 32.9996 9.90502 32.5319 8.15361H32.5301Z"
      fill="#D9B44E"
    />
    <path
      d="M11.8077 5.90405H16.7495C19.6793 5.90405 21.1432 7.05559 21.1432 9.36045C21.1432 10.5031 20.6416 11.4529 19.6382 12.2098C21.0933 12.8401 21.8199 13.9666 21.8199 15.5877C21.8199 16.8303 21.3914 17.8194 20.5344 18.5567C20.1167 18.9084 19.6686 19.1548 19.1901 19.294C18.7116 19.4333 18.0671 19.5029 17.2566 19.5029H16.4889V16.0055H17.1762C17.5815 16.0055 17.8672 15.9537 18.0332 15.8519C18.1992 15.7502 18.2814 15.5681 18.2814 15.3092C18.2814 14.87 17.9421 14.6522 17.2655 14.6522H16.4889V11.0262C17.2334 11.0262 17.6047 10.7709 17.6047 10.2585C17.6047 9.78715 17.2691 9.55149 16.5978 9.55149H15.8515V19.5029H11.8077V5.90405Z"
      fill="white"
    />
  </svg>

  <!-- <svg
    width="69"
    height="23"
    viewBox="0 0 69 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.6251 7.56257H22.5986C22.3531 7.56257 22.1562 7.36404 22.1562 7.12019V3.69063C22.1562 1.73355 20.5696 0.145386 18.6109 0.145386H3.54524C1.5866 0.145386 0 1.73199 0 3.69063V18.7563C0 20.7134 1.5866 22.3016 3.54524 22.3016H18.6109C20.568 22.3016 22.1562 20.715 22.1562 18.7563V15.3268C22.1562 15.0814 22.3547 14.8844 22.5986 14.8844H22.6251C23.8491 14.8844 24.8401 13.8918 24.8401 12.6694V9.78069C24.8401 8.55673 23.8475 7.56569 22.6251 7.56569V7.56257Z"
      fill="#FBBF17"
    />
    <path
      opacity="0.99"
      d="M46.8697 9.77756V12.6678C46.8697 13.8902 45.8786 14.8828 44.6547 14.8828H44.6281C44.3827 14.8828 44.1857 15.0814 44.1857 15.3252V18.7548C44.1857 19.4269 43.9982 20.0569 43.6715 20.593C43.6121 20.6915 43.5495 20.7853 43.4807 20.876C43.412 20.9682 43.3401 21.0557 43.2635 21.1386C42.6163 21.8529 41.68 22.3 40.6405 22.3H25.5748C24.5962 22.3 23.7099 21.903 23.069 21.2621C22.9893 21.1824 22.9127 21.0979 22.8393 21.0104C22.6954 20.8353 22.5673 20.6462 22.4578 20.4445C22.1843 19.9428 22.0295 19.366 22.0295 18.7548V15.3268C22.0295 15.0814 22.2281 14.8828 22.4735 14.8828H22.4985C23.7224 14.8828 24.715 13.8902 24.715 12.6663V9.77756C24.715 8.55361 23.724 7.56257 22.5001 7.56257H22.4735C22.2281 7.56257 22.0295 7.36405 22.0295 7.12019V3.69063C22.0295 3.07787 22.1843 2.50106 22.4578 1.99929C22.5673 1.79921 22.6954 1.61006 22.8393 1.43499C22.9127 1.34745 22.9893 1.26304 23.069 1.18332C23.7099 0.542428 24.5962 0.145386 25.5748 0.145386H40.6405C41.68 0.145386 42.6163 0.592449 43.2635 1.30681C43.3401 1.39122 43.412 1.47876 43.4807 1.56942C43.6167 1.75075 43.7371 1.94614 43.8356 2.15404C43.8856 2.25565 43.9309 2.36194 43.9685 2.4698H43.97C44.1091 2.85121 44.1857 3.26232 44.1857 3.69063V7.12019C44.1857 7.36405 44.3827 7.56257 44.6281 7.56257H44.6547C45.8786 7.56257 46.8697 8.55517 46.8697 9.77756Z"
      fill="#2A7DE7"
    />
    <path
      d="M68.7337 9.63217V12.5225C68.7337 13.7448 67.7426 14.7374 66.5187 14.7374H66.4921C66.2467 14.7374 66.0497 14.936 66.0497 15.1798V18.6094C66.0497 19.2815 65.8622 19.9115 65.5355 20.4477C65.4761 20.5461 65.4135 20.6399 65.3448 20.7306C65.276 20.8228 65.2041 20.9104 65.1275 20.9932C64.4803 21.7076 63.544 22.1546 62.5045 22.1546H47.4388C46.4603 22.1546 45.574 21.7576 44.9331 21.1167C44.8533 21.037 44.7767 20.9526 44.7033 20.865C44.5595 20.6899 44.4313 20.5008 44.3219 20.2992C44.0483 19.7974 43.8936 19.2206 43.8936 18.6094V15.1814C43.8936 14.936 44.0921 14.7374 44.3375 14.7374H44.3625C45.5865 14.7374 46.5791 13.7448 46.5791 12.5209V9.63217C46.5791 8.40822 45.588 7.41718 44.3641 7.41718H44.3375C44.0921 7.41718 43.8936 7.21866 43.8936 6.97481V3.54524C43.8936 2.93248 44.0483 2.35568 44.3219 1.8539C44.4313 1.65382 44.5595 1.46468 44.7033 1.2896C44.7767 1.20207 44.8533 1.11766 44.9331 1.03794C45.574 0.397042 46.4603 0 47.4388 0H62.5045C63.544 0 64.4803 0.447063 65.1275 1.16143C65.2041 1.24584 65.276 1.33337 65.3448 1.42404C65.4808 1.60536 65.6011 1.80076 65.6996 2.00866C65.7496 2.11026 65.795 2.21656 65.8325 2.32441H65.834C65.9732 2.70583 66.0497 3.11694 66.0497 3.54524V6.97481C66.0497 7.21866 66.2467 7.41718 66.4921 7.41718H66.5187C67.7426 7.41718 68.7337 8.40979 68.7337 9.63217Z"
      fill="#16B150"
    />
    <path
      d="M11.2236 11.447C11.2236 9.14917 11.838 9.21795 13.9607 9.21795C17.259 9.21795 17.0839 10.0245 17.0839 13.0086C17.0839 14.4483 16.8901 16.0443 16.171 16.8337C15.2941 17.7981 13.7325 17.7981 12.0646 17.7981C10.0638 17.7981 8.13484 17.992 7.04689 16.9384C5.87139 15.816 5.62598 13.3759 5.62598 11.2C5.62598 7.56726 6.45132 5.72586 8.48655 4.97086C9.87307 4.46127 12.049 4.56756 14.1014 4.56756C15.4879 4.56756 16.3477 4.68949 16.3477 6.32142C16.3477 6.81225 16.3305 7.30465 16.2601 7.79548C16.1726 8.427 15.874 8.63802 15.2425 8.67241C13.8029 8.74275 12.3476 8.75995 10.9079 8.83029C10.3639 8.86468 10.2592 8.91783 10.1185 9.4618C9.97781 10.0402 9.89027 10.6201 9.89027 11.2172C9.89027 13.34 10.2592 14.0606 12.3647 13.884C12.6633 13.848 12.8384 13.6026 12.8556 13.3572C12.8915 12.9179 12.3991 12.9008 12.1006 12.8476C11.3987 12.7069 11.2236 12.585 11.2236 11.4439V11.447Z"
      fill="white"
    />
    <path
      d="M35.8024 4.65663C37.575 4.65663 38.4692 4.51594 38.4692 6.63871C38.4692 6.99042 38.4348 7.35776 38.3457 7.70947C38.205 8.27065 37.9596 8.44572 37.3812 8.46448C36.0822 8.51763 34.766 8.53482 33.4686 8.53482C32.7496 8.53482 32.3979 9.42895 33.3983 9.53524C33.9595 9.60558 34.7489 9.51805 35.013 9.51805C35.6617 9.51805 36.9779 9.39612 36.9779 10.3606C36.9779 11.2547 37.1186 12.7819 35.9431 12.8695C35.6273 12.8867 34.1001 12.8523 33.2592 12.957C32.6448 13.0273 32.3291 13.8511 33.1888 13.9215C34.2596 14.0262 36.645 13.8511 36.9967 13.8511C38.6114 13.8511 38.2425 15.2376 38.2425 16.4303C38.2425 17.87 36.856 17.8512 35.8384 17.8512C34.469 17.8512 33.1013 17.7981 31.7148 17.7981C30.5924 17.7981 28.9073 17.9388 28.8198 16.4819C28.5916 12.7444 28.626 9.3758 28.7854 6.30576C28.8901 4.34087 31.2067 4.60348 32.698 4.60348C33.7328 4.60348 34.7692 4.65663 35.804 4.65663H35.8024Z"
      fill="white"
    />
    <path
      d="M55.8502 14.7984C54.1839 14.7984 53.6211 13.6933 52.5863 12.5881C52.3941 13.7105 52.5691 15.0094 52.5863 16.1505C52.6223 17.9576 51.8141 17.8528 50.2697 17.8528C49.4803 17.8528 48.147 17.9232 48.147 16.8352C48.147 16.2209 48.2345 15.6253 48.2517 15.011L48.2345 6.53557C48.2345 6.18386 48.2689 5.79932 48.322 5.44761C48.4455 4.65822 49.4975 4.64102 50.1291 4.64102C50.4276 4.64102 51.8485 4.65822 52.1299 4.72856C53.1131 4.97397 54.5684 8.43168 55.3766 9.3258C57.2008 6.83413 57.7276 5.48356 58.728 4.85205C59.2016 4.55348 59.8691 4.57068 60.5709 4.57068C61.7636 4.57068 62.5186 4.57068 62.6421 5.79932C62.9235 8.64114 63.0626 13.5557 63.0626 16.3631C63.0626 17.0118 62.9579 17.6621 62.1857 17.7309C61.2916 17.8184 60.3427 17.8544 59.4486 17.8544C58.939 17.8544 58.6592 17.5386 58.6233 17.0478C58.5357 15.5909 58.6764 13.626 58.3606 12.1879C57.7119 13.1524 57.132 14.8015 55.8518 14.8015L55.8502 14.7984Z"
      fill="white"
    />
  </svg> -->
</template>
