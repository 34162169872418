<template>
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.18 0.82C12.36 -2.98023e-07 11.04 0 8.4 0H5.6C2.96 0 1.64 -2.98023e-07 0.82 0.82C5.96046e-08 1.64 0 2.96 0 5.6C0 8.24 5.96046e-08 9.56 0.82 10.38C1.64 11.2 2.96 11.2 5.6 11.2H8.4C11.04 11.2 12.36 11.2 13.18 10.38C14 9.56 14 8.24 14 5.6C14 2.96 14 1.64 13.18 0.82ZM4.22 1.62C4.54 1.62 4.79 1.87 4.79 2.18C4.79 2.49 4.54 2.75 4.22 2.75C3.9 2.75 3.66 2.5 3.66 2.18C3.66 1.86 3.91 1.62 4.22 1.62ZM2.16 1.62C2.47 1.62 2.72 1.87 2.72 2.18C2.72 2.49 2.47 2.75 2.16 2.75C1.85 2.75 1.59 2.5 1.59 2.18C1.59 1.86 1.84 1.62 2.16 1.62ZM9.73 9.12C9.86 9.25 9.86 9.47 9.73 9.61C9.66 9.68 9.57 9.71 9.48 9.71C9.39 9.71 9.3 9.68 9.23 9.61L8.42 8.8C8.28 8.66 8.28 8.44 8.42 8.3L9.23 7.49C9.37 7.35 9.59 7.35 9.73 7.49C9.86 7.63 9.86 7.85 9.73 7.99L9.16 8.55L9.73 9.12ZM12.22 8.8L11.4 9.61C11.34 9.68 11.25 9.71 11.16 9.71C11.07 9.71 10.98 9.68 10.91 9.61C10.77 9.47 10.77 9.25 10.91 9.12L11.47 8.55L10.91 7.99C10.77 7.85 10.77 7.63 10.91 7.49C11.04 7.35 11.27 7.35 11.4 7.49L12.22 8.3C12.28 8.37 12.32 8.46 12.32 8.55C12.32 8.64 12.28 8.73 12.22 8.8ZM12.28 4.36H1.72C1.52 4.36 1.35 4.2 1.35 3.99C1.35 3.78 1.52 3.61 1.72 3.61H12.28C12.49 3.61 12.66 3.78 12.66 3.99C12.66 4.2 12.49 4.36 12.28 4.36Z"
      fill="white"
    />
  </svg>
</template>
