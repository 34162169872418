<template>
  <footer>
    <div class="footer">
      <div class="container">
        <div class="footer_left footer_info">
          <span>(주)리원드 | </span>
          <span>대표자: 한승엽 | </span>
          <span>사업자번호: 789-86-02949 | </span>
          <span
            ><a
              href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=7898602949"
              target="_blank"
              >사업자 정보 확인</a
            ></span
          >
          <br />
          <span>통신판매업: 2024-인천연수구-0624 | </span
          ><span>개인정보보호책임자: 한승엽 | </span
          ><span>이메일: support@rewond.com</span>
          <br />
          <span>전화번호: 010-4388-4008 | </span>
          <span>주소: 인천광역시 연수구 갯벌로 12 미추홀타워 본관 1401호</span>
          <br />
          <span>©REWOND. ALL RIGHTS RESERVED</span>
        </div>
        <div class="footer_right footer_sns"></div>
      </div>
    </div>
  </footer>
  <!--  -->
</template>

<script setup></script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-width: var(--container-width);
  min-height: 60px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.footer {
  background-color: #303740;
  padding: 10px 20px;
  margin: 0 auto;
}

.footer_info {
  display: block;
  font-size: 12px;
  line-height: 1.5;
  color: #bdbdbd;
}

span a:hover {
  color: var(--color-bety-gray);
}
</style>
