<script setup>
import BetyBlockIcon from "@/components/bety/icon/BetyBlockIcon";
import MotionBlockCircle from "@/components/block/MotionBlockCircle";
import MotionBlockUp from "@/components/block/MotionBlockUp";
import MotionBlockDown from "@/components/block/MotionBlockDown";
import MotionBlockLeft from "@/components/block/MotionBlockLeft";
import MotionBlockRight from "@/components/block/MotionBlockRight";
import MotionBlockShoot from "@/components/block/MotionBlockShoot";

import BetyBlockCircle from "@/components/bety/block/BetyBlockCircle";
import BetyBlockGo from "@/components/bety/block/BetyBlockGo";
import BetyBlockJump from "@/components/bety/block/BetyBlockJump";
import BetyBlockTurnLeft from "@/components/bety/block/BetyBlockTurnLeft";
import BetyBlockTurnRight from "@/components/bety/block/BetyBlockTurnRight";
import BetyBlockShoot from "@/components/bety/block/BetyBlockShoot";
import BetyBlockLoopStart from "@/components/bety/block/BetyBlockLoopStart";
import BetyBlockLoopEnd from "@/components/bety/block/BetyBlockLoopEnd";

import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const router = useRouter();
const store = useStore();
const props = defineProps({
  blockType: {
    type: String,
    required: true,
    default: "normal",
  },
});

const block_list = computed(() => store.state.Block.block);
watch(store.state.Block.block, (newValue) => {
  let routePageno = parseInt(router.currentRoute.value.params.pageno || "");
  routePageno = isNaN(routePageno) ? 0 : routePageno;

  if (newValue.length > 7 && routePageno > 30) newValue.pop();
  if (newValue.length > 9) newValue.pop();
});
let isLoop = ref(false);

const pageNo = computed(() => store.state.Space.pageNumber);
watch(pageNo, (newValue) => {
  store.commit("Block/clearBlock");
  let routeMinor = router.currentRoute.value.params.minor || "";
  // let routePageno = parseInt(router.currentRoute.value.params.pageno || "");
  // routePageno = isNaN(routePageno) ? 0 : routePageno;

  if (
    (routeMinor == "maze_compute_pose" && newValue > 30) ||
    (routeMinor == "maze_compute_face" && newValue > 30) ||
    (routeMinor == "maze_compute_hand" && newValue > 30)
  )
    isLoop.value = true;
  else isLoop.value = false;
});

onMounted(() => {
  // let routeMajor = router.currentRoute.value.params.major || "";
  let routeMinor = router.currentRoute.value.params.minor || "";
  let routePageno = parseInt(router.currentRoute.value.params.pageno || "");
  routePageno = isNaN(routePageno) ? 0 : routePageno;

  if (
    (routeMinor == "maze_compute_pose" && routePageno > 30) ||
    (routeMinor == "maze_compute_face" && routePageno > 30) ||
    (routeMinor == "maze_compute_hand" && routePageno > 30)
  )
    isLoop.value = true;
  else isLoop.value = false;
});

const popSound = new Audio(require("@/assets/pop.mp3"));
const clickBlock = (idx) => {
  popSound.currentTime = 0;
  popSound.play();
  store.commit("Block/deleteBlockByIdx", idx);
};
</script>

<template>
  <div class="wrap">
    <div class="bg"></div>
    <div class="title">
      <div class="title_icon">
        <bety-block-icon />
      </div>
      <div class="title_text">블록</div>
    </div>
    <div class="block_list">
      <motion-block-circle v-if="props.blockType == 'normal'" />
      <bety-block-circle v-if="props.blockType == 'rotate'" />
      <bety-block-loop-start v-if="isLoop == true" />

      <div
        v-for="(item, idx) in block_list"
        :key="item.id"
        @click.stop="clickBlock(idx)"
      >
        <motion-block-circle
          v-if="item == 'circle' && props.blockType == 'normal'"
        />
        <motion-block-left
          v-if="item == 'left go' && props.blockType == 'normal'"
        />
        <motion-block-right
          v-if="item == 'right go' && props.blockType == 'normal'"
        />
        <motion-block-up
          v-if="item == 'up go' && props.blockType == 'normal'"
        />
        <motion-block-down
          v-if="item == 'down go' && props.blockType == 'normal'"
        />
        <motion-block-shoot
          v-if="item == 'shoot' && props.blockType == 'normal'"
        />

        <bety-block-circle
          v-if="item == 'circle' && props.blockType == 'rotate'"
        />
        <bety-block-turn-left
          v-if="item == 'left turn' && props.blockType == 'rotate'"
        />
        <bety-block-turn-right
          v-if="item == 'right turn' && props.blockType == 'rotate'"
        />
        <bety-block-go v-if="item == 'go' && props.blockType == 'rotate'" />
        <bety-block-jump v-if="item == 'jump' && props.blockType == 'rotate'" />
        <bety-block-shoot
          v-if="item == 'shoot' && props.blockType == 'rotate'"
        />
      </div>

      <bety-block-loop-end v-if="isLoop == true" />
    </div>
  </div>
</template>
<style scoped>
.block_list {
  display: flex;
  position: absolute;
  top: 50px;
  left: 50px;
  filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.2));
}
.wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--color-bety-block-bg);
  padding: 7px;
  box-sizing: border-box;
  border-radius: 10px 0 10px 0;
  box-shadow: -2px 2px 4px 2px rgba(0, 0, 0, 0.05),
    2px -2px 4px 2px rgba(0, 0, 0, 0.05);
}

.bg {
  width: 100%;
  height: 100%;
  background: repeating-radial-gradient(
    circle at 10px 10px,
    var(--color-bety-block-dot) 0,
    var(--color-bety-block-dot) 2.5px,
    transparent 2.5px,
    transparent 25px
  );
  background-size: 25px 25px; /* 동그라미 간격 설정 */
}

.title {
  position: absolute;
  top: 10px;
  left: 15px;
  width: 100px;
  display: flex;
  align-items: center;
  background-color: var(--color-bety-block-bg);
  color: var(--color-bety-primary);
  font-size: 25px;
}

.title_icon {
  width: 30px;
  height: 30px;
  margin-right: 7px;
}
.title_text {
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
