<template>
  <div class="wrapper">
    <button @click="profile">profile</button>
    <div class="nav">
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
      <router-link to="/admin">Admin</router-link>
    </div>
    <div class="sortable">
      <button class="btn btn-secondary" @click="add">Add</button>
      <draggable class="dragArea list-group" :list="list" @change="log">
        <div class="list-group-item" v-for="element in list" :key="element.key">
          {{ element.name }}
        </div>
      </draggable>
    </div>
    <br /><br />
    <!-- Swiper -->
    <div class="swiper-simple">
      <swiper-container
        :slides-per-view="3"
        :space-between="spaceBetween"
        :centered-slides="true"
        :pagination="{
          hideOnClick: true,
        }"
        :breakpoints="{
          768: {
            slidesPerView: 1,
          },
        }"
        @progress="onProgress"
        @slidechange="onSlideChange"
      >
        <swiper-slide>Slide 1</swiper-slide>
        <swiper-slide>Slide 2</swiper-slide>
        <swiper-slide>Slide 3</swiper-slide>
      </swiper-container>
    </div>

    <div class="swiper-card">
      <swiper-container
        class="mySwiper"
        effect="cards"
        grab-cursor="true"
        :pagination-dynamic-bullets="{
          hideOnClick: true,
        }"
      >
        <swiper-slide v-for="item in swiperList" :key="item.id">
          <input type="checkbox" id="item" />
          <label for="item">{{ item }}</label>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>

  <div>
    <h2>여권</h2>
    <blockquote>
      <p>2023.10.18. 기준 잘못된 정보가 있다면 리포트!!</p>
    </blockquote>
    <h3>팁</h3>
    <ul>
      <li>
        성인이 재발급하는 경우, <code>온라인 신청</code>도 가능해요. (정부24)
      </li>
      <li>
        2024년 12월까지 종전여권(녹색 구 여권)을 저렴하게 발급할 수 있어요.
      </li>
      <li>
        분실을 대비해 <code>여권 사본</code>과 <code>여권용 사진</code>을
        가져가면 좋아요.
      </li>
    </ul>
    <h3>신청 절차</h3>
    <ul>
      <li>
        준비물
        <ol>
          <li>신분증</li>
          <li>여권용 사진 1매(6개월 이내 촬영)</li>
          <li>발급수수료</li>
          <li>기존 여권(유효기간 남았을 시)</li>
          <li>법정대리인 동의서 (<code>만 18세 미만</code>)</li>
          <li>법정대리인 인감증명서 (<code>만 18세 미만</code>)</li>
        </ol>
      </li>
      <li>
        접수
        <ol>
          <li>인근 시군구청 방문</li>
          <li>여권 관련 민원과 방문</li>
          <li>여권발급신청서 작성 후 제출</li>
        </ol>
      </li>
      <li>
        수령
        <ol>
          <li>발급 완료 알림 수신</li>
          <li>신청한 장소 재방문 후 여권 수령</li>
        </ol>
      </li>
      <li>
        주의사항
        <ol>
          <li>
            통상 발급 소요기간은 <code>5일~2주</code>이니, 여권이 없거나
            유효기간이 6개월 미만인 경우에는 미리 신청을 해야해요.
          </li>
          <li>신청한 장소에서만 수령이 가능해요.</li>
          <li>개별우편배송서비스는 온라인 신청했을 경우 사용할 수 없어요.</li>
        </ol>
      </li>
    </ul>
    <h3>발급 수수료</h3>
    <table>
      <thead>
        <tr>
          <th align="center">복수여권</th>
          <th align="center">58면</th>
          <th align="center">26면</th>
          <th align="center">비고</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">10년</td>
          <td align="center"><code>53,000</code>원</td>
          <td align="center">50,000원</td>
          <td align="center">만 18세 이상</td>
        </tr>
        <tr>
          <td align="center">5년</td>
          <td align="center">45,000원</td>
          <td align="center"><code>42,000</code>원</td>
          <td align="center">만 18세 미만</td>
        </tr>
        <tr>
          <td align="center">5년</td>
          <td align="center">33,000원</td>
          <td align="center"><code>30,000</code>원</td>
          <td align="center">만 8세 미만</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead>
        <tr>
          <th align="center">종전여권</th>
          <th align="center">48면</th>
          <th align="center">24면</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">4년 11개월</td>
          <td align="center">15,000</td>
          <td align="center">15,000</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead>
        <tr>
          <th align="center">단수여권</th>
          <th align="center"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="center">1년이내</td>
          <td align="center">20,000원</td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li>
        참고 사항
        <ul>
          <li>
            기타 여권 발급 수수료 :
            <a href="https://www.passport.go.kr/home/kor/contents.do?menuPos=41"
              >외교부 여권안내 수수료</a
            >
          </li>
          <li>병역미필자는 만 18세 미만에 해당돼요.</li>
          <li>종전여권은 24면이 우선 발급되고, 소진 시에 48면을 발급해줘요.</li>
        </ul>
      </li>
    </ul>
    <h3>분실 시 요령</h3>
    <ul>
      <li>
        <p>준비물</p>
        <ol>
          <li>여권용 사진 2매(중국 3매)</li>
          <li>출국 항공권</li>
          <li>여권 분실 신고서 (경찰서 발급)</li>
          <li>재발급 신청서 (대사관/영사관 작성)</li>
        </ol>
      </li>
      <li>
        <p>절차</p>
        <ol>
          <li>경찰서 방문 후 여권 분실 증명서 작성</li>
          <li>한국 대사관 또는 영사관 방문</li>
          <li>전자여권 및 단수여권(여행증명서) 발급</li>
        </ol>
      </li>
      <li>
        <p>주의사항</p>
        <ol>
          <li>
            전자여권은 <code>1주일</code>, 단수여권은 <code>2~3일</code>이
            소요되니, 처음부터 분실하지 않도록 주의해요.
          </li>
          <li>
            증명서 작성에 언어 문제로 의사소통이 힘든 경우 외교부 영사
            콜센터(+82-2-3210-0404)가 실시하는 &#39;해외 긴급상황 시
            통역서비스&#39;를 이용해요. (영어, 중국어, 일본어, 프랑스어,
            러시아어, 스페인어)
          </li>
          <li>
            분실신고 후에는 입국심사가 까다로워지거나 유효기간이 짧아지는 등의
            불이익이 있어요.
          </li>
        </ol>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { VueDraggableNext as draggable } from "vue-draggable-next";
import { ref, reactive } from "vue";
import axios from "@/api";

let id = ref(3);
let list = reactive([
  { name: "Han", id: 0 },
  { name: "Kim", id: 1 },
  { name: "Lee", id: 2 },
]);

let swiperList = reactive(["One", "Two", "Three", "Four", "Five"]);

function add() {
  list.push({ name: "Park", id });
  id.value++;
}
function log(event) {
  console.log(event);
}
const spaceBetween = 10;
const onProgress = (e) => {
  const [swiper, progress] = e.detail;
  console.log(swiper);
  console.log(progress);
};

const onSlideChange = (e) => {
  console.log("slide changed" + e.target);
};

async function profile() {
  try {
    const response = await axios.get(`/api/user/profile`);
    console.log("user profile: ", response.data);
  } catch (error) {
    console.error("profile failed", error);
  }
}
</script>

<style scoped>
.logo {
  align-items: center;
}

.logo img {
  width: 500px;
}

swiper-container {
  width: 240px;
  height: 320px;
}

swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

swiper-slide:nth-child(1n) {
  background-color: rgb(206, 17, 17);
}

swiper-slide:nth-child(2n) {
  background-color: rgb(0, 140, 255);
}

swiper-slide:nth-child(3n) {
  background-color: rgb(10, 184, 111);
}

swiper-slide:nth-child(4n) {
  background-color: rgb(211, 122, 7);
}

swiper-slide:nth-child(5n) {
  background-color: rgb(118, 163, 12);
}

swiper-slide:nth-child(6n) {
  background-color: rgb(180, 10, 47);
}

swiper-slide:nth-child(7n) {
  background-color: rgb(35, 99, 19);
}

swiper-slide:nth-child(8n) {
  background-color: rgb(0, 68, 255);
}

swiper-slide:nth-child(9n) {
  background-color: rgb(218, 12, 218);
}

swiper-slide:nth-child(10n) {
  background-color: rgb(54, 94, 77);
}
</style>
