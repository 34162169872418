<script setup>
import { ref, onMounted } from "vue";
const serialConnected = ref(false);
const serialPortName = ref("");
const receivedData = ref("");

onMounted(() => {
  if (!("serial" in navigator)) {
    alert("Serial Not Supported");
  }
});

let port = undefined;

const connectSerial = async () => {
  const filters = [
    { usbVendorId: 0x10c4, usbProductId: 0xea60 },
    // { usbVendorId: 0x0d28, usbProductId: 0x0204 },
  ];

  try {
    port = await navigator.serial.requestPort({ filters });
    await port.open({
      baudRate: 500000,
      dataBits: 8,
      stopBits: 1,
      parity: "none",
      bufferSize: 255, //byte
      flowControl: "none",
    });
    serialConnected.value = true;

    // const reader = port.readable.getReader();
    // while (true) {
    //   const { value, done } = await reader.read();
    //   if (done) break;
    //   receivedData.value = new TextDecoder().decode(value);
    //   console.log("Received Data:", receivedData.value);
    // }
  } catch (error) {
    console.error("Error connecting to serial:", error);
  }
};

const sendCommand = async (serialData) => {
  const writer = port.writable.getWriter();
  await writer.write(serialData);
  writer.releaseLock();
};

const makeCommand = (direction = "front", speed = 50) => {
  let speedCode = speed;
  if (speed < 0) {
    speedCode = 0;
  } else if (speed > 100) {
    speedCode = 100;
  }
  let directionCode = 0x00;
  switch (direction) {
    case "stop":
      directionCode = 0x00;
      break;
    case "front":
      directionCode = 0x01;
      break;
    case "back":
      directionCode = 0x02;
      break;
    case "left":
      directionCode = 0x03;
      break;
    case "right":
      directionCode = 0x04;
      break;
  }
  const dataStart = new Uint8Array([0xaa, 0xaa, 0xcc]);
  const dataPayload = new Uint8Array([
    0x01,
    0x10,
    0x11,
    0x00,
    0x00,
    0x05,
    speedCode,
    0x00,
    directionCode,
    0x00,
  ]);
  const dataLength = new Uint8Array([dataPayload.length]);
  const dataParity = new Uint8Array([
    dataPayload.reduce((acc, cur) => acc + cur, 0),
  ]);
  const data = new Uint8Array([
    ...dataStart,
    ...dataLength,
    ...dataPayload,
    ...dataParity,
  ]);

  return data;
};

const btnClick = async (direction, speed) => {
  if (!port) return;
  const dataCode = makeCommand(direction, speed);
  await sendCommand(dataCode);
};

const btnLED = async (ledValue) => {
  const dataStart = new Uint8Array([0xaa, 0xaa, 0xcc]);
  const dataPayload = new Uint8Array([
    0x01,
    0x10,
    0x11,
    0x00,
    0x00,
    0x01,
    ledValue,
    0x00,
  ]);
  const dataLength = new Uint8Array([dataPayload.length]);
  const dataParity = new Uint8Array([
    dataPayload.reduce((acc, cur) => acc + cur, 0),
  ]);
  const data = new Uint8Array([
    ...dataStart,
    ...dataLength,
    ...dataPayload,
    ...dataParity,
  ]);

  await sendCommand(data);
};

const btnBuzzer = async (buzz) => {
  const dataStart = new Uint8Array([0xaa, 0xaa, 0xcc]);
  const dataPayload = new Uint8Array([
    0x01,
    0x10,
    0x11,
    0x00,
    0x00,
    0x02,
    buzz,
    0x00,
  ]);
  const dataLength = new Uint8Array([dataPayload.length]);
  const dataParity = new Uint8Array([
    dataPayload.reduce((acc, cur) => acc + cur, 0),
  ]);
  const data = new Uint8Array([
    ...dataStart,
    ...dataLength,
    ...dataPayload,
    ...dataParity,
  ]);

  await sendCommand(data);
};

// const { usbProductId, usbVendorId } = port.getInfo();
// console.log(usbProductId, usbVendorId);
// [EnforceRange] octet dataBits = 8;
//   [EnforceRange] octet stopBits = 1;
//   ParityType parity = "none";
//   [EnforceRange] unsigned long bufferSize = 255;
//   FlowControlType flowControl = "none"; or "hardware"
// };
</script>

<template>
  <div>
    <button @click="connectSerial">Connect to Serial</button>
    <div v-if="serialConnected">
      <p>Serial Port: {{ serialPortName }}</p>
      <p v-if="receivedData">Received Data: {{ receivedData }}</p>
      <p v-else>No data received yet.</p>
      <button @click="btnClick('stop', 0)">Stop</button>
      <button @click="btnClick('front', 100)">Front</button>
      <button @click="btnClick('back', 100)">Back</button>
      <button @click="btnClick('left', 100)">Left</button>
      <button @click="btnClick('right', 100)">Right</button>
      <button @click="btnLED(100)">LED</button>
      <button @click="btnBuzzer(30)">Buzzer</button>
    </div>
    <div v-else>
      <p>Serial Port: Not connected</p>
    </div>
  </div>
</template>

<style scoped></style>
