<template>
  <svg
    width="80"
    height="72"
    viewBox="0 0 80 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="margin-right: -10px"
  >
    <path
      opacity="0.99"
      d="M79.4554 31.4339V40.5661C79.4554 44.4284 76.3241 47.5646 72.4568 47.5646H72.3729C71.5975 47.5646 70.9752 48.1918 70.9752 48.9623V59.7984C70.9752 61.9222 70.3825 63.9126 69.3502 65.6067C69.1626 65.9178 68.965 66.2141 68.7477 66.5006C68.5304 66.792 68.3032 67.0686 68.0612 67.3304C66.0164 69.5875 63.058 71 59.7736 71H12.1718C9.08 71 6.2796 69.7455 4.25463 67.7205C4.00274 67.4686 3.76073 67.2019 3.5286 66.9254C3.07421 66.3722 2.66922 65.7746 2.32349 65.1375C1.45917 63.552 0.970215 61.7296 0.970215 59.7984V48.9673C0.970215 48.1918 1.59746 47.5646 2.37288 47.5646H2.45191C6.31912 47.5646 9.45536 44.4284 9.45536 40.5611V31.4339C9.45536 27.5667 6.32405 24.4354 2.45684 24.4354H2.37288C1.59746 24.4354 0.970215 23.8082 0.970215 23.0377V12.2016C0.970215 10.2655 1.45917 8.44303 2.32349 6.85762C2.66922 6.22543 3.07421 5.62781 3.5286 5.07465C3.76073 4.79807 4.00274 4.53136 4.25463 4.27948C6.2796 2.2545 9.08 1 12.1718 1H59.7736C63.058 1 66.0164 2.41254 68.0612 4.66965C68.3032 4.93636 68.5304 5.21294 68.7477 5.4994C69.1774 6.07232 69.5577 6.68969 69.8688 7.34657C70.0269 7.66761 70.1701 8.00346 70.2886 8.34425H70.2936C70.7331 9.54935 70.9752 10.8483 70.9752 12.2016V23.0377C70.9752 23.8082 71.5975 24.4354 72.3729 24.4354H72.4568C76.3241 24.4354 79.4554 27.5717 79.4554 31.4339Z"
      fill="#006BE0"
      stroke="#FFFCFC"
      stroke-miterlimit="10"
    />
    <g opacity="0.99">
      <path
        d="M12.7794 67.5033C10.5865 67.5033 8.52197 66.6488 6.97113 65.098C6.78839 64.9202 6.60565 64.7177 6.42291 64.4954C6.10187 64.1052 5.80554 63.6607 5.55365 63.2014C4.90171 62.0111 4.56091 60.6579 4.56091 59.2848V49.9699C9.24305 49.3377 12.8633 45.3174 12.8633 40.4673V31.5377C12.8633 26.6876 9.24305 22.6673 4.56091 22.0401V12.7252C4.56091 11.3521 4.9017 9.99391 5.54871 8.80856C5.80059 8.34923 6.10187 7.90472 6.43772 7.48985C6.60565 7.29229 6.78839 7.08979 6.9662 6.91199C8.51703 5.36116 10.5815 4.50671 12.7744 4.50671H59.3439C61.6554 4.50671 63.873 5.48957 65.4238 7.19845C65.5967 7.39107 65.7696 7.59851 65.9424 7.8257C66.2585 8.24551 66.5302 8.69496 66.7475 9.14934C66.8759 9.41111 66.9697 9.6383 67.0487 9.85068L67.1969 10.2754C67.4439 11.0657 67.5673 11.8855 67.5673 12.7202V23.2797C67.5673 25.5714 69.4244 27.4285 71.7161 27.4285C74.0078 27.4285 75.8648 29.2855 75.8648 31.5772V40.4278C75.8648 42.7195 74.0078 44.5766 71.7161 44.5766C69.4244 44.5766 67.5673 46.4336 67.5673 48.7253V59.2848C67.5673 60.7912 67.1574 62.263 66.3771 63.5422C66.2289 63.7892 66.0906 63.9966 65.9424 64.1843C65.7646 64.4213 65.6066 64.614 65.4436 64.7918C63.873 66.5253 61.6603 67.5033 59.3439 67.5033H12.7744H12.7794Z"
        fill="#2A7DE7"
      />
    </g>
    <path
      d="M52.0117 33.7553L53.4982 35.2417C54.1276 35.8711 54.1276 36.8889 53.4982 37.5115L40.4885 50.528C39.8591 51.1573 38.8413 51.1573 38.2186 50.528L25.2089 37.5182C24.5795 36.8889 24.5795 35.8711 25.2089 35.2484L26.6954 33.762C27.3314 33.1259 28.3693 33.1393 28.992 33.7888L36.6719 41.8504V22.607C36.6719 21.7164 37.3884 21 38.2789 21L40.4215 21C41.312 21 42.0285 21.7164 42.0285 22.607V41.8504L49.7151 33.7821C50.3378 33.1259 51.3756 33.1125 52.0117 33.7553Z"
      fill="white"
    />
  </svg>
</template>
