import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { Bety } from "./modules/Bety";
import { User } from "./modules/User";
import { Block } from "./modules/Block";
import { Mission } from "./modules/Mission";
import { Space } from "./modules/Space";
import { Camera } from "./modules/Camera";
const storageState = createPersistedState({
  paths: ["User"],
});

export default createStore({
  modules: { Bety, Block, Mission, Space, Camera, User },
  plugins: [storageState],
});
