<script setup>
//import SpaceshipSprite from "@/components/sprite/SpaceshipSprite";
//import GemBasicSprite from "@/components/sprite/GemBasicSprite";
import CharacterSprite from "@/components/sprite/CharacterSprite";
import AsteroidSprite from "@/components/sprite/AsteroidSprite";
import CoinSprite from "@/components/sprite/CoinSprite";
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
const store = useStore();

let maze = [
  [
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 1],
    [1, 0, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 1, 0, 0, 0, 1, 0, 0],
    [1, 1, 1, 0, 1, 1, 1, 0, 1],
    [1, 0, 1, 0, 0, 0, 1, 3, 1],
    [1, 0, 1, 1, 1, 0, 1, 0, 1],
    [1, 0, 0, 0, 0, 2, 1, 0, 1],
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
  ],
  [
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 1],
    [1, 1, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 0, 0, 1, 0, 1, 0, 0],
    [1, 0, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 1, 0, 1, 0, 1, 0, 1],
    [1, 1, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 0, 0, 0, 2, 1, 3, 1],
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
  ],
  [
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
    [1, 0, 1, 0, 0, 0, 0, 3, 1],
    [1, 0, 1, 0, 1, 0, 1, 1, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 0],
    [1, 1, 1, 1, 1, 1, 1, 0, 1],
    [1, 0, 1, 0, 0, 2, 1, 0, 1],
    [1, 0, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 1],
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
  ],
  [
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
    [1, 3, 1, 0, 0, 0, 0, 0, 1],
    [1, 0, 1, 0, 1, 0, 1, 1, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 0],
    [1, 1, 1, 0, 1, 1, 1, 1, 1],
    [1, 0, 1, 0, 0, 0, 0, 0, 1],
    [1, 0, 1, 1, 1, 1, 1, 0, 1],
    [1, 0, 0, 0, 0, 0, 0, 2, 1],
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
  ],
  [
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
    [1, 0, 0, 0, 0, 0, 0, 0, 1],
    [1, 0, 1, 1, 1, 1, 1, 0, 1],
    [1, 0, 0, 0, 0, 0, 1, 0, 0],
    [1, 1, 1, 0, 1, 0, 1, 1, 1],
    [1, 0, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 0, 2, 1, 3, 0, 0, 1],
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
  ],
  [
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
    [1, 0, 0, 3, 0, 0, 1, 0, 1],
    [1, 0, 1, 1, 1, 0, 1, 0, 1],
    [1, 0, 1, 0, 0, 2, 0, 0, 0],
    [1, 1, 1, 0, 1, 1, 1, 1, 1],
    [1, 0, 1, 0, 0, 0, 0, 0, 1],
    [1, 0, 1, 1, 1, 0, 1, 0, 1],
    [1, 0, 0, 0, 0, 0, 1, 0, 1],
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
  ],
  [
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
    [1, 0, 0, 0, 0, 0, 0, 0, 1],
    [1, 0, 1, 1, 1, 1, 1, 0, 1],
    [1, 0, 0, 0, 0, 0, 1, 0, 0],
    [1, 1, 1, 1, 1, 0, 1, 0, 1],
    [1, 0, 0, 0, 0, 2, 1, 0, 1],
    [1, 1, 1, 0, 1, 1, 1, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 3, 1],
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
  ],
  [
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 1],
    [1, 0, 1, 0, 1, 1, 1, 0, 1],
    [1, 0, 1, 0, 0, 2, 0, 0, 0],
    [1, 1, 1, 0, 1, 1, 1, 0, 1],
    [1, 0, 0, 0, 1, 3, 1, 0, 1],
    [1, 1, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 1],
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
  ],
  [
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
    [1, 0, 0, 2, 0, 0, 0, 3, 1],
    [1, 0, 1, 1, 1, 0, 1, 1, 1],
    [1, 0, 0, 0, 1, 0, 1, 0, 0],
    [1, 1, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 1],
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
  ],
  [
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
    [1, 0, 0, 0, 0, 2, 1, 3, 1],
    [1, 0, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 1, 0, 1, 0, 1, 0, 0],
    [1, 1, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 1, 0, 1, 0, 1, 0, 1],
    [1, 0, 0, 0, 1, 0, 0, 0, 1],
    [1, 1, 1, 1, 1, 1, 1, 1, 1],
  ],
];
const mazeNum = Math.floor(Math.random() * maze.length);
//const mazeNum = 9;

let shipPosition = { x: 1, y: 5 };
let shipEndPosition = { x: 9, y: 3 };
let asteroidPosition = undefined;
let coinPosition = undefined;

const wallSize = 15;
const cellSize = 85;

let spaceshipElem = ref(null);
let asteroidElem = ref(null);
let coinElem = ref(null);
let mbShow = ref(false);
let completeShow = ref(false);

let asteroidAlive = true;

function getPositionByCoord(coord) {
  return {
    x: ((wallSize + cellSize) * coord.x) / 2 + wallSize / 2,
    y: ((wallSize + cellSize) * coord.y) / 2 + wallSize / 2,
  };
}

function findPosition(target) {
  for (let i = 0; i < maze[mazeNum].length; i++) {
    for (let j = 0; j < maze[mazeNum][i].length; j++) {
      if (maze[mazeNum][i][j] == target) return { x: j, y: i };
    }
  }
  return undefined;
}

onMounted(() => {
  let s = getPositionByCoord(shipPosition);
  asteroidPosition = findPosition(2);
  let a = getPositionByCoord(asteroidPosition);
  coinPosition = findPosition(3);
  let c = getPositionByCoord(coinPosition);
  spaceshipElem.value.$el.style.left = `${s.x}px`;
  spaceshipElem.value.$el.style.top = `${s.y}px`;
  asteroidElem.value.$el.style.left = `${a.x}px`;
  asteroidElem.value.$el.style.top = `${a.y}px`;
  coinElem.value.$el.style.left = `${c.x}px`;
  coinElem.value.$el.style.top = `${c.y}px`;
});

watch(
  () => store.state.Space.isPlaying,
  async (newValue) => {
    if (newValue) {
      console.log(store.state.Block.block);
      for (let block of store.state.Block.block) {
        if (block == "left go") {
          if (
            maze[mazeNum][shipPosition.y][shipPosition.x - 1] == 1 ||
            (maze[mazeNum][shipPosition.y][shipPosition.x - 2] == 2 &&
              asteroidAlive)
          ) {
            const explodeSound = new Audio(require("@/assets/explode.mp3"));
            explodeSound.play();
            mbShow.value = true;
            await new Promise((resolve) => setTimeout(resolve, 1000));
            mbShow.value = false;
            break;
          }
          shipPosition.x -= 2;
        }
        if (block == "right go") {
          if (
            maze[mazeNum][shipPosition.y][shipPosition.x + 1] == 1 ||
            (maze[mazeNum][shipPosition.y][shipPosition.x + 2] == 2 &&
              asteroidAlive)
          ) {
            const explodeSound = new Audio(require("@/assets/explode.mp3"));
            explodeSound.play();
            mbShow.value = true;
            await new Promise((resolve) => setTimeout(resolve, 1000));
            mbShow.value = false;
            break;
          }
          shipPosition.x += 2;
        }
        if (block == "up go") {
          if (
            maze[mazeNum][shipPosition.y - 1][shipPosition.x] == 1 ||
            (maze[mazeNum][shipPosition.y - 2][shipPosition.x] == 2 &&
              asteroidAlive)
          ) {
            const explodeSound = new Audio(require("@/assets/explode.mp3"));
            explodeSound.play();
            mbShow.value = true;
            await new Promise((resolve) => setTimeout(resolve, 1000));
            mbShow.value = false;
            break;
          }
          shipPosition.y -= 2;
        }
        if (block == "down go") {
          if (
            maze[mazeNum][shipPosition.y + 1][shipPosition.x] == 1 ||
            (maze[mazeNum][shipPosition.y + 2][shipPosition.x] == 2 &&
              asteroidAlive)
          ) {
            const explodeSound = new Audio(require("@/assets/explode.mp3"));
            explodeSound.play();
            mbShow.value = true;
            await new Promise((resolve) => setTimeout(resolve, 1000));
            mbShow.value = false;
            break;
          }
          shipPosition.y += 2;
        }
        if (block == "shoot") {
          if (maze[mazeNum][shipPosition.y][shipPosition.x + 2] == 2) {
            const lazerSound = new Audio(require("@/assets/lazer_rock.mp3"));
            lazerSound.play();
            await new Promise((resolve) => setTimeout(resolve, 1900));
            asteroidElem.value.$el.style.display = "none";
            await new Promise((resolve) => setTimeout(resolve, 300));
            asteroidAlive = false;
            store.commit("Mission/setMissionComplete", 0);
          } else {
            const lazerSound = new Audio(require("@/assets/lazer.mp3"));
            lazerSound.play();
            await new Promise((resolve) => setTimeout(resolve, 600));
          }
        }

        if (
          shipPosition.x == coinPosition.x &&
          shipPosition.y == coinPosition.y
        ) {
          coinElem.value.$el.style.display = "none";
          store.commit("Mission/setMissionComplete", 1);
          const coinSound = new Audio(require("@/assets/coin.mp3"));
          coinSound.play();
        }

        let s = getPositionByCoord(shipPosition);
        spaceshipElem.value.$el.style.left = `${s.x}px`;
        spaceshipElem.value.$el.style.top = `${s.y}px`;
        if (
          shipPosition.x == shipEndPosition.x &&
          shipPosition.y == shipEndPosition.y
        ) {
          store.commit("Mission/setMissionComplete", 2);
          completeShow.value = true;
          await new Promise((resolve) => setTimeout(resolve, 5000));
          location.reload();
        }
        await new Promise((resolve) => setTimeout(resolve, 300));
      }
    }
    //store.commit("Block/clearBlock");
    store.commit("Space/stop");
  }
);
</script>
<template>
  <div class="maze">
    <div
      v-for="(row, rowIndex) in maze[mazeNum]"
      :key="row.id"
      :class="['row', rowIndex % 2 === 0 ? 'row_even' : 'row_odd']"
    >
      <div
        v-for="(col, colIndex) in row"
        :key="col.id"
        :class="[
          'col',
          colIndex % 2 === 0 ? 'col_even' : 'col_odd',
          ((rowIndex % 2 === 0 && colIndex % 2 === 0) ||
            rowIndex % 2 === 1 ||
            colIndex % 2 === 1) &&
          col === 1
            ? 'wall'
            : '',
          col == 2 ? 'ast' : '',
          col == 3 ? 'coin' : '',
        ]"
      ></div>
    </div>

    <!--
    <spaceship-sprite
      style="position: absolute; transform: translate(-50%, -50%)"
      ref="spaceshipElem"
    />
    -->
    <!-- <gem-basic-sprite
      style="position: absolute; width: 80px; transform: translate(-50%, -50%)"
      ref="spaceshipElem"
    /> -->
    <character-sprite
      style="
        position: absolute;
        width: 60px;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease;
      "
      ref="spaceshipElem"
    />
    <asteroid-sprite
      style="position: absolute; transform: translate(-50%, -50%)"
      ref="asteroidElem"
    />
    <coin-sprite
      style="position: absolute; transform: translate(-50%, -50%)"
      ref="coinElem"
    />
  </div>
  <div v-show="mbShow" class="message-box">더 나아갈 수 없어요</div>
  <div v-show="completeShow" class="message-box">성공!!</div>
</template>

<style scoped>
.message-box {
  display: flex;
  position: absolute;
  width: 300px;
  height: 50px;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0);
  background-color: #ddd;
  border: 0;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}
.maze {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
}
.row_odd {
  height: 85px;
}
.row_even {
  height: 15px;
}
.col {
  height: 100%;
}
.col_odd {
  width: 85px;
}
.col_even {
  width: 15px;
}
.wall {
  background-color: #8267d1;
}
</style>
