<script setup>
const props = defineProps({
  type: {
    type: String,
    required: true,
    default: "",
  },
});
</script>

<template>
  <div class="wrap">
    <router-link to="/motion">
      <div class="mission">
        <div
          class="mission-icon"
          :class="{ mission_selected: props.type === 'pose' }"
        ></div>
        <div class="mission-text">몸짓</div>
      </div>
    </router-link>
    <router-link to="/face">
      <div class="mission">
        <div
          class="mission-icon"
          :class="{ mission_selected: props.type === 'face' }"
        ></div>
        <div class="mission-text">얼굴</div>
      </div>
    </router-link>
    <router-link to="/hand">
      <div class="mission">
        <div
          class="mission-icon"
          :class="{ mission_selected: props.type === 'hand' }"
        ></div>
        <div class="mission-text">손가락</div>
      </div>
    </router-link>
    <!-- <div class="mission">
      <div class="mission-icon"></div>
      <div class="mission-text">X</div>
    </div> -->
  </div>
</template>
<style scoped>
.wrap {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.2);
  padding: 25px 10px;
  box-sizing: border-box;
}

.mission {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}

.mission-icon {
  width: 26px;
  height: 26px;
  border-radius: 13px;
  background-color: #959595;
}
.mission_selected {
  background-color: #2a7de7;
}
.mission-text {
  font-size: 13px;
  margin-top: 5px;
  color: #959595;
}
</style>
