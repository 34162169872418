<template>
  <svg
    width="80"
    height="72"
    viewBox="0 0 80 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="margin-right: -10px"
  >
    <path
      opacity="0.99"
      d="M79.4851 31.4339V40.5661C79.4851 44.4284 76.3539 47.5646 72.4866 47.5646H72.4027C71.6273 47.5646 71.0049 48.1918 71.0049 48.9623V59.7984C71.0049 61.9222 70.4123 63.9126 69.38 65.6067C69.1923 65.9178 68.9948 66.2141 68.7775 66.5006C68.5602 66.792 68.333 67.0686 68.0909 67.3304C66.0462 69.5875 63.0878 71 59.8034 71H12.2016C9.10978 71 6.30939 69.7455 4.28441 67.7205C4.03252 67.4686 3.79051 67.2019 3.55838 66.9254C3.104 66.3722 2.699 65.7746 2.35327 65.1375C1.48895 63.552 1 61.7296 1 59.7984V48.9673C1 48.1918 1.62725 47.5646 2.40267 47.5646H2.48169C6.3489 47.5646 9.48514 44.4284 9.48514 40.5611V31.4339C9.48514 27.5667 6.35384 24.4354 2.48663 24.4354H2.40267C1.62725 24.4354 1 23.8082 1 23.0377V12.2016C1 10.2655 1.48895 8.44303 2.35327 6.85762C2.699 6.22543 3.104 5.62781 3.55838 5.07465C3.79051 4.79807 4.03252 4.53136 4.28441 4.27948C6.30939 2.2545 9.10978 1 12.2016 1H59.8034C63.0878 1 66.0462 2.41254 68.0909 4.66965C68.333 4.93636 68.5602 5.21294 68.7775 5.4994C69.2072 6.07232 69.5874 6.68969 69.8986 7.34657C70.0567 7.66761 70.1999 8.00346 70.3184 8.34425H70.3234C70.7629 9.54935 71.0049 10.8483 71.0049 12.2016V23.0377C71.0049 23.8082 71.6273 24.4354 72.4027 24.4354H72.4866C76.3539 24.4354 79.4851 27.5717 79.4851 31.4339Z"
      fill="#006BE0"
      stroke="#FFFCFC"
      stroke-miterlimit="10"
    />
    <g opacity="0.99">
      <path
        d="M12.8091 67.5033C10.6162 67.5033 8.55175 66.6488 7.00092 65.098C6.81818 64.9202 6.63543 64.7177 6.45269 64.4954C6.13166 64.1052 5.83532 63.6607 5.58344 63.2014C4.93149 62.0111 4.5907 60.6579 4.5907 59.2848V49.9699C9.27284 49.3377 12.8931 45.3174 12.8931 40.4673V31.5377C12.8931 26.6876 9.27284 22.6673 4.5907 22.0401V12.7252C4.5907 11.3521 4.93149 9.99391 5.57849 8.80856C5.83038 8.34923 6.13166 7.90472 6.46751 7.48985C6.63543 7.29229 6.81818 7.08979 6.99598 6.91199C8.54682 5.36116 10.6113 4.50671 12.8042 4.50671H59.3737C61.6852 4.50671 63.9028 5.48957 65.4536 7.19845C65.6265 7.39107 65.7993 7.59851 65.9722 7.8257C66.2883 8.24551 66.5599 8.69496 66.7773 9.14934C66.9057 9.41111 66.9995 9.6383 67.0785 9.85068L67.2267 10.2754C67.4736 11.0657 67.5971 11.8855 67.5971 12.7202V23.2797C67.5971 25.5714 69.4542 27.4285 71.7459 27.4285C74.0375 27.4285 75.8946 29.2855 75.8946 31.5772V40.4278C75.8946 42.7195 74.0375 44.5766 71.7459 44.5766C69.4542 44.5766 67.5971 46.4336 67.5971 48.7253V59.2848C67.5971 60.7912 67.1872 62.263 66.4068 63.5422C66.2587 63.7892 66.1204 63.9966 65.9722 64.1843C65.7944 64.4213 65.6363 64.614 65.4734 64.7918C63.9028 66.5253 61.6901 67.5033 59.3737 67.5033H12.8042H12.8091Z"
        fill="#2A7DE7"
      />
    </g>
    <path
      d="M26.9585 38.2447L25.472 36.7583C24.8427 36.1289 24.8427 35.1111 25.472 34.4885L38.4818 21.472C39.1111 20.8427 40.1289 20.8427 40.7516 21.472L53.7613 34.4818C54.3907 35.1111 54.3907 36.1289 53.7613 36.7516L52.2749 38.238C51.6388 38.8741 50.6009 38.8607 49.9782 38.2112L42.2983 30.1496V49.393C42.2983 50.2836 41.5819 51 40.6913 51H38.5487C37.6582 51 36.9417 50.2836 36.9417 49.393V30.1496L29.2551 38.2179C28.6324 38.8741 27.5946 38.8875 26.9585 38.2447Z"
      fill="white"
    />
  </svg>
</template>
