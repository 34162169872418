<script setup>
import MotionHeader from "@/components/MotionHeader";
import MotionAppbar from "@/components/MotionAppbar";
import MotionCamera from "@/components/MotionCamera";
import MotionSpace from "@/components/MotionSpace";
import MotionMission from "@/components/MotionMission";
import MotionBlock from "@/components/MotionBlock";
import { ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
const store = useStore();

//let step = ref(0);
let headerElem = ref(null);
let appbarElem = ref(null);
let missionElem = ref(null);
let cameraElem = ref(null);
let spaceElem = ref(null);
let blockElem = ref(null);

let shutter = ref("off");

onMounted(() => {
  store.commit("Camera/setShutterEnable", false);
  spaceElem.value.$el.style.zIndex = "11";
});

let step = computed(() => {
  return store.getters["Space/getTutorialStep"];
});

watch(step, (newValue) => {
  switch (newValue) {
    case 2:
      cameraElem.value.$el.style.zIndex = "11";
      break;
    case 6:
      blockElem.value.$el.style.zIndex = "11";
      break;
    case 16:
      missionElem.value.$el.style.zIndex = "11";
      break;
    case 17:
      appbarElem.value.$el.style.zIndex = "11";
      break;
    case 18:
      headerElem.value.$el.style.zIndex = "11";
      break;
  }
});
</script>

<template>
  <div class="fullscreen">
    <div class="wraps">
      <div class="wraps_cover" ref="wrapsCoverElem"></div>
      <header>
        <nav>
          <div class="nav_menu">
            <motion-header ref="headerElem" />
          </div>
        </nav>
      </header>
      <main>
        <div class="content">
          <div class="nav_appbar">
            <motion-appbar ref="appbarElem" />
          </div>
          <div class="playground">
            <div class="top">
              <div class="mission">
                <motion-mission ref="missionElem" />
              </div>
              <div class="camera">
                <motion-camera ref="cameraElem" :shutter="shutter" />
              </div>
              <div class="space">
                <motion-space ref="spaceElem" type="tutorial" />
              </div>
            </div>
            <div class="blocks">
              <motion-block ref="blockElem" />
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<style scoped>
.fullscreen {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(0, 0, 0);
}
.wraps {
  width: 1600px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.wraps_cover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);
}

.nav_menu {
  height: 50px;
}
main {
  flex: 1;
}
.content {
  display: flex;
  width: 100%;
  height: 100%;
}

.nav_appbar {
  min-width: 90px;
  width: 90px;
  height: 100%;
}
.playground {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: rgb(255 251 229);
}

.playground .top {
  height: 600px;
  display: flex;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.mission {
  flex: 1;
  padding-right: 20px;
}

.camera {
  width: 350px;
  display: flex;
  flex-direction: column;
}

.space {
  width: 720px;
}
.blocks {
  flex: 1;
  padding-left: 20px;
  box-sizing: content-box;
}
</style>
