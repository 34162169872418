export const Space = {
  namespaced: true,
  state: () => ({
    isPlaying: false,
    tutorialStep: 0,
    completeState: "none",
    pageNumber: 1,
  }),
  mutations: {
    play(state) {
      state.isPlaying = true;
    },
    stop(state) {
      state.isPlaying = false;
    },
    incTutorialStep(state) {
      state.tutorialStep++;
    },
    setCompleteState(state, value) {
      state.completeState = value;
    },
    setPageNumber(state, value) {
      state.pageNumber = value;
    },
  },
  actions: {},
  getters: {
    getTutorialStep(state) {
      return state.tutorialStep;
    },
  },
};
