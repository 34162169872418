export const User = {
  namespaced: true,
  state: () => ({
    completePage: {
      maze_compute_pose: 0,
      maze_compute_face: 0,
      maze_compute_hand: 0,
    },
    isAuthenticatedByCode: false,
    authTime: null, // 인증 시간이 저장될 곳
  }),
  mutations: {
    setCompleteStage(state, value) {
      const { space, page } = value;
      state.completePage[space] = page;
    },
    resetCompleteStage(state) {
      state.completePage = {
        maze_compute_pose: 0,
        maze_compute_face: 0,
        maze_compute_hand: 0,
      };
    },
    authenticateByCode(state) {
      state.isAuthenticatedByCode = true;
      state.authTime = Date.now();
    },
    resetAuthentication(state) {
      state.isAuthenticatedByCode = false;
      state.authTime = null;
    },
  },
  actions: {
    checkPassword({ commit, dispatch }, password) {
      if (
        password === "110034" ||
        password === "145523" ||
        password === "0000" ||
        password === "387972"
      ) {
        commit("authenticateByCode");
        dispatch("startAuthTimer");
      }
    },
    startAuthTimer({ commit }) {
      const twelveHours = 12 * 60 * 60 * 1000;
      setTimeout(() => {
        commit("resetAuthentication");
      }, twelveHours);
    },
    initializeAuthState({ state, commit }) {
      if (state.isAuthenticatedByCode && state.authTime) {
        const twelveHours = 12 * 60 * 60 * 1000;
        const timeElapsed = Date.now() - state.authTime;

        if (timeElapsed >= twelveHours) {
          commit("resetAuthentication");
        } else {
          const remainingTime = twelveHours - timeElapsed;
          setTimeout(() => {
            commit("resetAuthentication");
          }, remainingTime);
        }
      }
    },
  },
  getters: {},
};

/*

// store.js
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

const storageState = createPersistedState({
  paths: ["User"],
})

const store = createStore({
  state: {
    User: {
      isAuthenticatedByCode: false,
      authTime: null,  // 인증 시간이 저장될 곳
    }
  },
  mutations: {
    authenticateByCode(state) {
      state.User.isAuthenticatedByCode = true
      state.User.authTime = Date.now()
    },
    resetAuthentication(state) {
      state.User.isAuthenticatedByCode = false
      state.User.authTime = null
    }
  },
  actions: {
    checkPassword({ commit, dispatch }, password) {
      if (password === '1357') {
        commit('authenticateByCode')
        dispatch('startAuthTimer')
      }
    },
    startAuthTimer({ commit }) {
      const twelveHours = 12 * 60 * 60 * 1000
      setTimeout(() => {
        commit('resetAuthentication')
      }, twelveHours)
    },
    initializeAuthState({ state, commit, dispatch }) {
      if (state.User.isAuthenticatedByCode && state.User.authTime) {
        const twelveHours = 12 * 60 * 60 * 1000
        const timeElapsed = Date.now() - state.User.authTime

        if (timeElapsed >= twelveHours) {
          commit('resetAuthentication')
        } else {
          const remainingTime = twelveHours - timeElapsed
          setTimeout(() => {
            commit('resetAuthentication')
          }, remainingTime)
        }
      }
    }
  },
  plugins: [storageState]
})

export default store

*/
