<script setup>
import BetyDialog from "@/components/bety/BetyDialog";
import { ref, onMounted, computed, watch, onUpdated } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useStore();
const items = ref(undefined);
const itemsFull = [
  { text: "8", l: 250, t: 650, r: undefined, b: undefined, answer: true },
  { text: "5", l: 350, t: 650, r: undefined, b: undefined, answer: false },
  { text: "1", l: 450, t: 650, r: undefined, b: undefined, answer: false },
  { text: "2", l: 550, t: 650, r: undefined, b: undefined, answer: true },
  { text: "7", l: 650, t: 650, r: undefined, b: undefined, answer: false },
  { text: "3", l: 750, t: 650, r: undefined, b: undefined, answer: false },
  { text: "6", l: 850, t: 650, r: undefined, b: undefined, answer: true },
  { text: "4", l: 950, t: 650, r: undefined, b: undefined, answer: false },
  { text: "9", l: 1050, t: 650, r: undefined, b: undefined, answer: false },
];
const basket = ref({
  l: 250,
  t: 150,
  r: undefined,
  b: undefined,
});
const itemElements = ref([]);
const basketElement = ref(null);
const spaceState = ref("none");

const showValidateButton = ref(false);

let handPresent = computed(() => store.state.Camera.handPresent);
let handLandmark = computed(() => store.state.Camera.handLandmark);
let cameraWidth = computed(() => store.state.Camera.cameraWidth);
let cameraHeight = computed(() => store.state.Camera.cameraHeight);
let cursor = undefined;
let grasp = false;
let selected = null;

onMounted(() => {
  items.value = itemsFull;
  for (let i = 0; i < itemElements.value.length; i++) {
    const style = window.getComputedStyle(itemElements.value[i]);
    const width = parseInt(style.width);
    const height = parseInt(style.height);
    const padding = parseInt(style.padding);
    items.value[i].r = items.value[i].l + width + 2 * padding;
    items.value[i].b = items.value[i].t + height + 2 * padding;
    itemElements.value[i].style.left = `${items.value[i].l}px`;
    itemElements.value[i].style.top = `${items.value[i].t}px`;
    itemElements.value[i].style.opacity = 1;
    itemElements.value[i].style.transform = "scale(1)";
  }
  const style = window.getComputedStyle(basketElement.value);
  const width = parseInt(style.width);
  const height = parseInt(style.height);
  const padding = parseInt(style.padding);
  basket.value.r = basket.value.l + width + 2 * padding;
  basket.value.b = basket.value.t + height + 2 * padding;
  basketElement.value.style.left = `${basket.value.l}px`;
  basketElement.value.style.top = `${basket.value.t}px`;
});

let startFlag = ref(false);

onUpdated(() => {
  if (startFlag.value) return;

  items.value = itemsFull;
  console.error(itemElements.value);
  for (let i = 0; i < itemElements.value.length; i++) {
    const style = window.getComputedStyle(itemElements.value[i]);
    const width = parseInt(style.width);
    const height = parseInt(style.height);
    const padding = parseInt(style.padding);
    items.value[i].r = items.value[i].l + width + 2 * padding;
    items.value[i].b = items.value[i].t + height + 2 * padding;
    itemElements.value[i].style.left = `${items.value[i].l}px`;
    itemElements.value[i].style.top = `${items.value[i].t}px`;
    itemElements.value[i].style.opacity = 1;
    itemElements.value[i].style.transform = "scale(1)";
  }
  const style = window.getComputedStyle(basketElement.value);
  const width = parseInt(style.width);
  const height = parseInt(style.height);
  const padding = parseInt(style.padding);
  basket.value.r = basket.value.l + width + 2 * padding;
  basket.value.b = basket.value.t + height + 2 * padding;
  basketElement.value.style.left = `${basket.value.l}px`;
  basketElement.value.style.top = `${basket.value.t}px`;

  startFlag.value = true;
});

const collision = (cursor, items) => {
  for (let i = 0; i < items.value.length; i++) {
    if (
      cursor.l <= items.value[i].r &&
      cursor.r >= items.value[i].l &&
      cursor.t <= items.value[i].b &&
      cursor.b >= items.value[i].t
    ) {
      return i;
    }
  }
  return null;
};

const collisionInside = (cursor, items) => {
  let cnt = 0;
  for (let i = 0; i < items.value.length; i++) {
    if (
      cursor.l <= items.value[i].l &&
      cursor.r >= items.value[i].r &&
      cursor.t <= items.value[i].t &&
      cursor.b >= items.value[i].b
    ) {
      //   return i;
      cnt++;
    }
  }
  return cnt;
};

const getDelta = (o, n) => {
  const sx = (o.l + o.r) / 2;
  const sy = (o.t + o.b) / 2;
  const ex = (n.l + n.r) / 2;
  const ey = (n.t + n.b) / 2;
  return { dx: ex - sx, dy: ey - sy };
};

const dialogClosed = (value) => {
  if (value === "next") {
    let routeMajor = router.currentRoute.value.params.major || "";
    let routeMinor = router.currentRoute.value.params.minor || "";
    let routePageno = parseInt(router.currentRoute.value.params.pageno || "");
    routePageno = isNaN(routePageno) ? 0 : routePageno;
    // router.replace(`/bety/${routeMajor}/${routeMinor}/${routePageno + 1}`);
    location.replace(`/bety/${routeMajor}/${routeMinor}/${routePageno + 1}`);
  } else if (value === "reload") {
    console.log("reload");
    startFlag.value = false;
    location.reload();
  }
  spaceState.value = "none";
};

watch(handPresent, (newValue) => {
  if (newValue === "circle" || newValue === "grab" || newValue === "shoot") {
    grasp = true;
    selected = collision(cursor, items);
    console.log(selected);
  } else {
    grasp = false;
    selected = null;
  }
});
watch(handLandmark, async (newValue) => {
  if (cameraWidth.value === undefined) return;
  if (cameraHeight.value === undefined) return;
  let l = parseInt(
    cameraWidth.value -
      Math.max(newValue[0].x, newValue[5].x, newValue[17].x) * cameraWidth.value
  );
  let r = parseInt(
    cameraWidth.value -
      Math.min(newValue[0].x, newValue[5].x, newValue[17].x) * cameraWidth.value
  );
  let t = parseInt(
    Math.min(newValue[0].y, newValue[5].y, newValue[17].y) * cameraHeight.value
  );
  let b = parseInt(
    Math.max(newValue[0].y, newValue[5].y, newValue[17].y) * cameraHeight.value
  );

  const cntInside = collisionInside(basket.value, items);
  console.log(cntInside);
  if (cntInside >= itemsFull.length) {
    showValidateButton.value = true;
  } else {
    showValidateButton.value = false;
  }

  //   const basketItem = collisionInside(basket.value, items);
  //   if (basketItem !== null) {
  //     // console.log(items.value[basketItem].answer);
  //     itemElements.value[basketItem].style.opacity = 0;
  //     itemElements.value[basketItem].style.transform = "scale(0)";
  //     await new Promise((resolve) => setTimeout(resolve, 1000));
  //     items.value[basketItem].r -= items.value[basketItem].l;
  //     items.value[basketItem].b -= items.value[basketItem].t;
  //     items.value[basketItem].l = 150 + parseInt(basketItem / 3) * 200;
  //     items.value[basketItem].t = 250 + (basketItem % 3) * 200;
  //     items.value[basketItem].r += items.value[basketItem].l;
  //     items.value[basketItem].b += items.value[basketItem].t;
  //     if (items.value[basketItem].answer) spaceState.value = "success";
  //     else spaceState.value = "fail";
  //   }

  if (selected !== null && grasp) {
    let tmp = { l, r, t, b };
    const d = getDelta(cursor, tmp);
    items.value[selected].l += d.dx;
    items.value[selected].r += d.dx;
    items.value[selected].t += d.dy;
    items.value[selected].b += d.dy;
    itemElements.value[selected].style.left = `${items.value[selected].l}px`;
    itemElements.value[selected].style.top = `${items.value[selected].t}px`;

    console.log(d);
  }

  cursor = { l, r, t, b };
});

const clickValidate = () => {
  const sortedData = items.value.toSorted(
    (a, b) => parseInt(a.text) - parseInt(b.text)
  );
  let x = sortedData[0].l;
  let flag = true;
  for (let i = 1; i < sortedData.length; i++) {
    let newX = sortedData[i].l;
    if (newX < x) {
      flag = false;
      break;
    }
    x = newX;
  }
  if (flag) {
    console.log("success");
    spaceState.value = "success";
  } else {
    console.log("fail");
    spaceState.value = "fail";
  }
};
</script>

<template>
  <div class="wrap">
    <div class="title">숫자를 차례대로 담아보아요</div>
    <div class="basket" ref="basketElement">여기에 순서대로 나열해보아요</div>

    <div v-for="item in items" :key="item.id" ref="itemElements" class="item">
      {{ item.text }}
    </div>
    <div class="validateBtn" v-if="showValidateButton" @click="clickValidate">
      완료
    </div>

    <div
      style="position: absolute; width: 100%; height: 100%"
      v-if="spaceState !== 'none'"
    >
      <bety-dialog
        v-if="spaceState !== 'none'"
        :type="spaceState"
        :noContinue="true"
        @closed="dialogClosed"
      />
    </div>
  </div>
</template>

<style scoped>
.validateBtn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 600px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 100px;
  color: var(--color-bety-black);
  background-color: var(--color-bety-complementary);
  font-size: 30px;
  border: 0;
  border-radius: 15px;
}
.validateBtn:hover {
  cursor: pointer;
}
.wrap {
  position: absolute;
  width: 100%;
  height: 100%;
}
.title {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--color-bety-white);
  font-size: 40px;
  padding-top: 80px;
}
.item {
  position: absolute;
  background-color: var(--color-bety-primary);
  border: 2px dashed var(--color-bety-secondary);
  color: var(--color-bety-white);
  border-radius: 10px;
  font-size: 50px;
  padding: 20px 30px;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  box-sizing: content-box;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.basket {
  position: absolute;
  background-color: var(--color-bety-white);
  opacity: 0.5;
  border-radius: 10px;
  font-size: 20px;
  padding: 20px;
  width: 1000px;
  height: 150px;
  display: flex;
  justify-content: center;
}
</style>
