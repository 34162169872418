<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_501_551)">
      <path
        d="M2.87988 18.249C4.25602 12.1598 7.19645 6.53466 11.4109 1.92904C11.4858 1.84752 11.5769 1.78244 11.6782 1.73792C11.7796 1.6934 11.8892 1.67041 11.9999 1.67041C12.1106 1.67041 12.2201 1.6934 12.3215 1.73792C12.4229 1.78244 12.5139 1.84752 12.5889 1.92904C16.8033 6.53468 19.7437 12.1598 21.1199 18.249C18.9503 18.7057 16.7494 18.9982 14.5359 19.124L12.4469 23.3C12.4053 23.383 12.3415 23.4527 12.2625 23.5015C12.1836 23.5502 12.0927 23.576 11.9999 23.576C11.9071 23.576 11.8162 23.5502 11.7372 23.5015C11.6583 23.4527 11.5945 23.383 11.5529 23.3L9.46388 19.123C7.25031 18.9976 5.04938 18.7061 2.87988 18.249ZM11.9999 15.195C12.7955 15.195 13.5586 14.879 14.1212 14.3164C14.6838 13.7538 14.9999 12.9907 14.9999 12.195C14.9999 11.3994 14.6838 10.6363 14.1212 10.0737C13.5586 9.51111 12.7955 9.19504 11.9999 9.19504C11.2042 9.19504 10.4412 9.51111 9.87856 10.0737C9.31595 10.6363 8.99988 11.3994 8.99988 12.195C8.99988 12.9907 9.31595 13.7538 9.87856 14.3164C10.4412 14.879 11.2042 15.195 11.9999 15.195Z"
        fill="#29BBCF"
      />
    </g>
    <defs>
      <clipPath id="clip0_501_551">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.199951)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
