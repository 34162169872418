<template>
  <div
    class="modal-background"
    :class="{ modal_open: isModalOpen }"
    @click="closeModal"
  ></div>
  <div class="modal" :class="{ modal_open: isModalOpen }">
    <div class="modal-header">
      <div class="modal-header-left">
        <div class="modal-header-title">{{ title }}</div>
      </div>
      <div class="modal-header-right">
        <button @click="closeModal">X</button>
      </div>
    </div>
    <div class="modal-main" v-html="content"></div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { marked } from "marked";
import sanitizeHtml from "sanitize-html";
const store = useStore();

const isModalOpen = computed(() => store.state.Checklist.isModalOpen);
const closeModal = () => store.commit("Checklist/closeModal");

const title = computed(() => store.state.Checklist.modalTitle);
const content = computed(() =>
  sanitizeHtml(
    marked(`## 여권

> 2023.10.18. 기준
> 잘못된 정보가 있다면 리포트!!

### 팁

- 성인이 재발급하는 경우, \`온라인 신청\`도 가능해요. (정부24)
- 2024년 12월까지 종전여권(녹색 구 여권)을 저렴하게 발급할 수 있어요.
- 분실을 대비해 \`여권 사본\`과 \`여권용 사진\`을 가져가면 좋아요.

### 신청 절차

- 준비물
  1. 신분증
  1. 여권용 사진 1매(6개월 이내 촬영)
  1. 발급수수료
  1. 기존 여권(유효기간 남았을 시)
  1. 법정대리인 동의서 (\`만 18세 미만\`)
  1. 법정대리인 인감증명서 (\`만 18세 미만\`)
- 접수
  1. 인근 시군구청 방문
  1. 여권 관련 민원과 방문
  1. 여권발급신청서 작성 후 제출
- 수령
  1. 발급 완료 알림 수신
  1. 신청한 장소 재방문 후 여권 수령
- 주의사항
  1. 통상 발급 소요기간은 \`5일~2주\`이니, 여권이 없거나 유효기간이 6개월 미만인 경우에는 미리 신청을 해야해요.
  1. 신청한 장소에서만 수령이 가능해요.
  1. 개별우편배송서비스는 온라인 신청했을 경우 사용할 수 없어요.

### 발급 수수료

| 복수여권 |    58면    |    26면    |     비고     |
| :------: | :--------: | :--------: | :----------: |
|   10년   | \`53,000\`원 |  50,000원  | 만 18세 이상 |
|   5년    |  45,000원  | \`42,000\`원 | 만 18세 미만 |
|   5년    |  33,000원  | \`30,000\`원 | 만 8세 미만  |

|  종전여권  |  48면  |  24면  |
| :--------: | :----: | :----: |
| 4년 11개월 | 15,000 | 15,000 |

| 단수여권 |          |
| :------: | :------: |
| 1년이내  | 20,000원 |

- 참고 사항
  - 기타 여권 발급 수수료 : [외교부 여권안내 수수료](https://www.passport.go.kr/home/kor/contents.do?menuPos=41)
  - 병역미필자는 만 18세 미만에 해당돼요.
  - 종전여권은 24면이 우선 발급되고, 소진 시에 48면을 발급해줘요.

### 분실 시 요령

- 준비물
  1. 여권용 사진 2매(중국 3매)
  1. 출국 항공권
  1. 여권 분실 신고서 (경찰서 발급)
  1. 재발급 신청서 (대사관/영사관 작성)
- 절차
  1. 경찰서 방문 후 여권 분실 증명서 작성
  1. 한국 대사관 또는 영사관 방문
  1. 전자여권 및 단수여권(여행증명서) 발급
- 주의사항
  1. 전자여권은 \`1주일\`, 단수여권은 \`2~3일\`이 소요되니, 처음부터 분실하지 않도록 주의해요.
  2. 증명서 작성에 언어 문제로 의사소통이 힘든 경우 외교부 영사 콜센터(+82-2-3210-0404)가 실시하는 '해외 긴급상황 시 통역서비스'를 이용해요. (영어, 중국어, 일본어, 프랑스어, 러시아어, 스페인어)
  3. 분실신고 후에는 입국심사가 까다로워지거나 유효기간이 짧아지는 등의 불이익이 있어요.
`)
  )
);
console.log(content);
</script>

<style scoped>
.modal-background {
  background-color: black;
  position: fixed;
  top: 100%;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 0.5;
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.86, 0, 0.07, 1);
}
.modal-background.modal_open {
  top: 0px;
  opacity: 0.4;
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  top: 100%;
  bottom: 0;
  left: 0;
  transition: all 600ms cubic-bezier(0.86, 0, 0.07, 1);
  z-index: 1;
}

.modal.modal_open {
  top: 100px;
}

.modal-header {
  display: flex;
  align-items: center;
  background-color: var(--color-primary);
  border-radius: 15px 15px 0 0;
  height: 40px;
  padding: 0 15px;
}

.modal-header-left {
  width: 100%;
  display: flex;
  margin: auto;
}
.modal-header-title {
  font-size: 20px;
  color: white;
}
.modal-header-right button {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 1px solid transparent;
  color: white;
  font-size: 18px;
}

.modal-main {
  background-color: white;
  padding: 5px 15px 8px 15px;
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1;
}

.modal-main:deep(*) {
  font-size: 14px;
  white-space: pre-wrap;
  word-break: keep-all;
}
.modal-main:deep(h2) {
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
}
.modal-main:deep(h3) {
  line-height: 30px;
  font-size: 18px;
  font-weight: 600;
  padding-top: 5px;
  border-top: solid 3px #eeeeee;
}
.modal-main:deep(ul) {
  list-style: disc;
  margin-left: 25px;
  line-height: 0;
}
.modal-main:deep(ol) {
  list-style: decimal;
  margin-left: 25px;
  line-height: 0;
}
.modal-main:deep(li) {
  line-height: 30px;
}

.modal-main:deep(blockquote) {
  margin-top: 20px;
  padding-left: 10px;
  margin: 0;
  background-color: #eeeeee;
  border-left: solid 4px var(--color-primary);
}

.modal-main:deep(code) {
  color: var(--color-primary);
  opacity: 0.8;
}

.modal-main:deep(table) {
  border: solid 1px var(--color-primary);
  width: 100%;
  text-align: center;
}
.modal-main:deep(td) {
  height: 30px;
}

.modal-main:deep(a) {
  color: var(--color-primary);
}
</style>
