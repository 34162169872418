<template>
  <div class="card">
    <div class="header">
      <span class="title">{{ props.card.title }}</span>
      <div class="gap"></div>
      <span class="numcheck">{{ numCheck }}</span>
    </div>
    <hr />
    <div
      class="content"
      @touchstart="touchStart"
      @touchmove="touchMove"
      @touchend="touchMove"
    >
      <ul>
        <checklist-card-item
          v-for="item in props.card.content"
          :key="item.id"
          :item="item"
          @toggle-chk="toggleChk"
        />
      </ul>
    </div>
  </div>
</template>

<script setup>
import ChecklistCardItem from "@/components/ChecklistCardItem";
import { computed } from "vue";

const props = defineProps({
  card: Object,
});

const emit = defineEmits(["toggleChk"]);

const numCheck = computed(() => {
  const checked = props.card.content.filter((item) => {
    return item.checked === true;
  }).length;
  return "(" + checked + "/" + props.card.content.length + ")";
});

const toggleChk = (itemIdx) => {
  emit("toggleChk", props.card.idx, itemIdx);
};

/* Card Content Scroll */
let prevTouchY = 0;
const touchStart = (e) => {
  prevTouchY = e.changedTouches[0].clientY;
};
const touchMove = (e) => {
  const y = e.changedTouches[0].clientY;
  const deltaY = y - prevTouchY;
  document.querySelector(".content").scrollTop -= deltaY;

  prevTouchY = y;
};
</script>

<style scoped>
.card {
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  box-sizing: border-box;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.12);
}

.header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.header .title {
  font-size: 20px;
  font-weight: 600;
}
.header .numcheck {
  font-size: 15px;
  font-weight: 600;
  color: var(--color-primary);
}
.header .gap {
  padding: 0px 2px;
}

.content {
  margin-top: 0px;
  overflow: hidden;
}
.push_item {
  display: none;
}

hr {
  border: 0;
  border-top: 1px solid #e6e9ed;
  width: 100%;
}
</style>
