<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16.0207L4.53222 11.7084C3.55834 10.4736 2.97191 8.90159 2.97191 7.19214C2.97191 3.22562 6.11976 0 9.98596 0C13.8522 0 17 3.22777 17 7.19214C17 11.1565 13.8522 14.3843 9.98596 14.3843C8.25392 14.3843 6.66848 13.7357 5.44328 12.6662L0.888023 17L0 16.0164V16.0207ZM9.98596 13.0464C13.1338 13.0464 15.6931 10.4199 15.6931 7.19429C15.6931 3.96867 13.1317 1.34222 9.98596 1.34222C6.84022 1.34222 4.27881 3.96867 4.27881 7.19429C4.27881 10.4199 6.84022 13.0464 9.98596 13.0464Z"
      fill="#5F5E5C"
    />
  </svg>
</template>
