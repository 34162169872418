<script setup>
import TheHeader from "@/components/TheHeader";
import RegisterCheckIcon from "@/components/icon/RegisterCheckIcon";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import axios from "@/api";

const router = useRouter();
axios.defaults.withCredentials = true;

let username = ref("");
let password = ref("");
let passwordre = ref("");
const usernameFocused = ref(false);
const passwordFocused = ref(false);
const passwordreFocused = ref(false);
const isMinorChecked = ref(false);
const isTermsChecked = ref(false);
const showTooltip = ref(false);

const usernameStatus = computed(() => {
  if (username.value === "" && !usernameFocused.value) {
    return "none";
  } else if (!isEmail(username.value)) {
    return "wrong";
  } else {
    return "correct";
  }
});

const passwordStatus = computed(() => {
  if (password.value === "" && !passwordFocused.value) {
    return "none";
  } else if (!isPasswordValid(password.value)) {
    return "wrong";
  } else {
    return "correct";
  }
});

const passwordreStatus = computed(() => {
  if (passwordre.value === "" && !passwordreFocused.value) {
    return "none";
  } else if (passwordre.value !== password.value) {
    return "wrong";
  } else {
    return "correct";
  }
});

const submitStatus = computed(() => {
  if (usernameStatus.value !== "correct") return false;
  if (passwordStatus.value !== "correct") return false;
  if (passwordreStatus.value !== "correct") return false;
  if (!isMinorChecked.value) return false;
  if (!isTermsChecked.value) return false;
  return true;
});

const btnSubmitText = computed(() => {
  if (submitStatus.value) {
    return "가입하기";
  } else {
    return "가입을 위해 정보를 입력하세요";
  }
});

function handleFocus(field, value) {
  if (field === "username") {
    usernameFocused.value = value;
  } else if (field === "password") {
    passwordFocused.value = value;
  } else if (field === "passwordre") {
    passwordreFocused.value = value;
  }
}

function isEmail(value) {
  const emailRegex =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  return emailRegex.test(value);
}

function isPasswordValid(value) {
  const passwordRegex =
    /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{8,32}$/;
  return passwordRegex.test(value);
}

function toggleMinorCheck() {
  isMinorChecked.value = !isMinorChecked.value;
}

function toggleTermsCheck() {
  isTermsChecked.value = !isTermsChecked.value;
}
async function clickSubmit() {
  if (!submitStatus.value) return;
  await register();
}

async function register() {
  try {
    const response = await axios.post(`/api/auth/register`, {
      username: username.value,
      password: password.value,
    });
    console.log("Register Success", response.data);
    router.replace({ name: "Motion" });
  } catch (error) {
    console.error("Register Fail");
    console.warn(error.response.data.message);
  }
}
</script>

<style scoped></style>

<template>
  <the-header />
  <main>
    <div class="container">
      <div class="content">
        <div class="register_header">
          <div class="header_title">회원가입</div>
          <div class="header_title_sub">
            가입을 통해 더 다양한 서비스를 만나보세요!
          </div>
        </div>
        <div class="register_main">
          <div class="register_menu">
            <button
              class="btn_student"
              @mouseover="showTooltip = true"
              @mouseout="showTooltip = false"
            >
              학생
            </button>
            <button class="btn_admin">관리자</button>
            <div v-if="showTooltip" class="tooltip">Coming soon!</div>
          </div>
          <div class="register_username">
            <div class="username_header">
              <div class="username_title">이메일</div>
              <div class="username_desc" v-show="usernameStatus === 'wrong'">
                이메일 형식이 올바르지 않습니다
              </div>
            </div>

            <div class="username_input" :class="usernameStatus">
              <input
                type="text"
                class="ipt_username"
                v-model="username"
                @focus="handleFocus('username', true)"
                @blur="handleFocus('username', false)"
              />
            </div>
          </div>
          <div class="register_password">
            <div class="password_header">
              <div class="password_title">비밀번호</div>
              <div class="password_desc" v-show="passwordStatus === 'wrong'">
                영문/숫자/특수기호를 2가지 포함하여 8자 이상 32자 이하
              </div>
            </div>
            <div class="password_input" :class="passwordStatus">
              <input
                type="password"
                class="ipt_password"
                v-model="password"
                @focus="handleFocus('password', true)"
                @blur="handleFocus('password', false)"
              />
            </div>
          </div>
          <div class="register_passwordre">
            <div class="passwordre_header">
              <div class="passwordre_title">비밀번호 확인</div>
              <div
                class="passwordre_desc"
                v-show="passwordreStatus === 'wrong'"
              >
                비밀번호가 일치하지 않습니다
              </div>
            </div>
            <div class="passwordre_input" :class="passwordreStatus">
              <input
                type="password"
                class="ipt_passwordre"
                v-model="passwordre"
                @focus="handleFocus('passwordre', true)"
                @blur="handleFocus('passwordre', false)"
              />
            </div>
          </div>
          <div class="register_minor">
            <div class="minor_check" @click="toggleMinorCheck">
              <register-check-icon :checked="isMinorChecked" />
            </div>
            <div class="minor_desc">만 14세 이상입니다.</div>
          </div>
          <div class="register_terms">
            <div class="terms_check" @click="toggleTermsCheck">
              <register-check-icon :checked="isTermsChecked" />
            </div>
            <div class="terms_desc">
              <b>
                <router-link to="/policy/term" target="_blank"
                  >서비스 이용약관</router-link
                >&nbsp;/&nbsp;<router-link to="/policy/privacy" target="_blank"
                  >개인정보처리방침</router-link
                ></b
              >에 동의합니다.
            </div>
          </div>

          <div class="register_submit">
            <button
              class="btn_submit"
              :class="{ btn_disable: !submitStatus }"
              @click="clickSubmit"
            >
              {{ btnSubmitText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
b {
  font-weight: 600;
}
button:hover {
  cursor: pointer;
}
.container {
  width: 100%;
  max-width: var(--container-width);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.content {
  width: 350px;
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .content {
    padding-top: 30px;
  }
}

.register_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-bottom: 60px;
}

.header_title {
  font-size: 25px;
  font-weight: 600;
}
.header_title_sub {
  font-size: 15px;
}
.register_main {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.register_menu {
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
  gap: 10px;
  padding-bottom: 20px;
}
.btn_student {
  flex: 1;
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  background-color: white;
  color: #5f5e5c;
  font-size: 18px;
  font-weight: 700;
}
.btn_admin {
  flex: 1;
  border: 0;
  border-radius: 10px;
  background-color: #714bdd;
  color: white;
  font-size: 18px;
  font-weight: 700;
}

.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  top: -30px; /* 버튼 위에 위치하도록 top 값을 조절합니다. */
  left: 25%;
  transform: translateX(-50%);
}

.register_username {
  width: 100%;
}

.username_header,
.password_header,
.passwordre_header {
  display: flex;
  align-items: center;
}

.username_title,
.password_title,
.passwordre_title {
  padding-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
}
.username_desc,
.password_desc,
.passwordre_desc {
  font-size: 10px;
  flex: 1;
  color: #ef414f;
  display: flex;
  justify-content: flex-end;
}

.username_input,
.password_input,
.passwordre_input {
  width: 100%;
  height: 48px;
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.none {
  border: 2px solid #d9d9d9;
}
.wrong {
  border: 2px solid #ef414f;
}
.correct {
  border: 2px solid #714bdd;
}

input {
  width: 90%;
  height: 80%;
  border: 0;
}
input:focus {
  outline: none;
}

.register_minor {
  padding-top: 20px;
}

.register_minor,
.register_terms {
  display: flex;
  gap: 10px;
}

.minor_check:hover,
.terms_check:hover {
  cursor: pointer;
}

.minor_desc,
.terms_desc {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.register_submit {
  width: 100%;
  height: 50px;
  padding-top: 30px;
}
.btn_submit {
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 10px;
  background-color: #714bdd;
  color: white;
  font-size: 18px;
  font-weight: 700;
}

.btn_disable {
  background-color: #666;
}
.btn_disable:hover {
  cursor: default;
}
</style>
